import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import config from './config';
import { authCheck, setWatch } from "./redux/actions/auth";
import { toggleTariffsPlus } from "./redux/actions/tariffs";
import { openSignUpModal, openResetModal, openRestorePasswordModal } from "./redux/actions/ui";

import Preloader from "./components/ui/Preloader/Preloader";
import selectAuth from './redux/selectors/auth';
import useBindActionCreators from './hooks/utils/useBindActionCreators';
import routes from './routes';
import useQuery from './hooks/utils/useQuery';
import useEntrance from './hooks/useEntrance';
import Notification from "./components/template/Notification/Notification";
import { getRecaptcha3 } from "./utils/recaptcha";

const App = () => {
  const { ready: translationLoaded } = useTranslation('translation', { useSuspense: false });
  const { loading } = useSelector(selectAuth);

  const {
    authCheck: onAuthCheck,
    setWatch: setWatchFn,
  } = useBindActionCreators({
    authCheck,
    toggleTariffsPlus,
    openSignUpModal,
    openResetModal,
    openRestorePasswordModal,
    setWatch,
  });

  const query = useQuery() || {};
  const watch = query[config.adminWatchQuery];

  useMemo(
    () => {
      setWatchFn(watch);
    },
    [watch],
  );

  useEffect(
    () => {
      onAuthCheck();
      getRecaptcha3();
    },
    [],
  );

  useEntrance();

  return (
    loading || !translationLoaded
      ? (<Preloader />)
      : (
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Switch>
            {routes.map(([Component, path, options = {}], index) => {
              const { guards = null } = options;
              const componentNode = (
                <Component />
              );

              return (
                <Route
                  path={path}
                  exact={options && options.exact}
                  key={index}
                >
                  {!guards ? componentNode : guards.reduce((node, Guard) => (
                    <Guard key={index}>
                      {node}
                    </Guard>
                  ), componentNode)}
                </Route>
              );
            })}
          </Switch>
          <Notification />
        </BrowserRouter>
      )
  );
};

export default App;
