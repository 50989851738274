import cratePaths from './utils/createPaths';
import { getLanguage } from './utils/i18n';

type OptionsType = { query?: { [key: string]: string | number } };

const options: OptionsType = {};

export type PidPagesType = {
  partnerId: string,
  page: 'r' | 's' | 'es' | 'b' | 'm' | 'v' | 'a' | '' | undefined,
};

const routes = {
  redirect: 'api/redirect',
  main: 'main',
  surebets: 'arbs',
  middlebets: 'middlebets',
  valuebets: 'valuebets',
  questions: 'questions',
  contacts: 'contacts',
  contactsSent: 'contacts-sent',
  referal: 'referal',
  termsOfUse: 'terms-of-use',
  client: 'client',
  terms: 'terms',
  plugin: 'plugin',
  recovery: 'recovery',
  operator: 'operator',
  signUp: 'signUp',
  paymentSuccess: 'payment-success',
  paymentFail: 'payment-fail',
  payment: {
    plan: ':plan',
  },
  restore: 'restore/:resetCode?',
  tariffs: {
    tab: ':tab(subscription|subscription-plus)?',
  },
  partners: {
    api: 'api',
  },
  pid: {
    pages: ':partnerId/:page(r|s|es|b|m|v|a)?'
  },
  profile: {
    bookmakers: 'bookmakers',
    account: 'account',
    subscription: 'subscription',
    subscriptionPlus: 'subscription-plus',
    partners: 'partners',
    partnersApi: 'partners-api',
    currency: 'currency',
    filters: {
      tab: ':filterTab(surebet|middlebet|valuebet)?',
      edit: ':filterTab(surebet|middlebet|valuebet)/:id/edit',
      create: ':filterTab(surebet|middlebet|valuebet)/create',
    },
  },
};

export const basePaths = cratePaths(routes, {
  getDefaultParams: () => ({}),
});

export const config = (newOptions: OptionsType) => {
  Object.assign(options, newOptions);
};

export default cratePaths(routes, {
  prefix: '/:lang(ru|en|uk|kk)',
  getDefaultParams: () => {
    return ({ lang: getLanguage() });
  },
  getDefaultQuery: () => options.query,
});
