import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import map from "lodash/map";

const getDataSaveFilter = (data) => {
  if (isNaN(data.filterInfo.minProfit)) {
    return 'minProfit';
  }
  if (isNaN(data.filterInfo.maxProfit)) {
    return 'maxProfit';
  }
  if (isNaN(data.filterInfo.minCoefficient)) {
    return 'minCoefficient';
  }
  if (isNaN(data.filterInfo.maxCoefficient)) {
    return 'maxCoefficient';
  }
  if (isNaN(data.filterInfo.minAge)) {
    return 'minAge';
  }
  if (isNaN(data.filterInfo.maxAge)) {
    return 'maxAge';
  }
  if (isNaN(data.filterInfo.minBets)) {
    return 'minBets';
  }
  if (isNaN(data.filterInfo.maxBets)) {
    return 'maxBets';
  }
  const rules = data.state.editFilter.rules,
    rulesMas = data.state.editFilter.rulesMas,
    betsSettings = data.state.editFilter.betsSettings,
    sports = data.state.editFilter.filterInfo.sports,
    type = data.type;

  const sportMas = [];
  const betTypes = [];

  const filterBookmakersMas = data.filterInfo.bookers;

  let newMasBk = cloneDeep(filterBookmakersMas);
  const rulesMasFilter = [];

  //список спортивных событий в перерыве (события в перерыве)
  sports.map((sportItem) => sportMas.push({ sportId: sportItem.sportId, isBreakOnly: sportItem.isBreakOnly }));

  //список спортивных событий (типы исходов)
  betsSettings.map((sportGroup) => sportGroup.betTypeGroups.map((sport) => sport.check && betTypes.push(sport.id)));

  //если включена настройка "Учитывать правила в работе фильтра", формируем список правил
  rules === '1' && rulesMas.map(rule => {
    return rulesMasFilter.push({
      bookers: rule.bookers,
      minProfit: rule.minProfit,
      maxProfit: rule.maxProfit,
      betTypes: rule.betTypes
    });
  });

  //если включена настройка "Учитывать правила в работе фильтра", проставляем всем БК статус missing
  rules === '1' && filterBookmakersMas.forEach(bk => {
    bk.isActive = 'missing';
  });

  //формируем массив БК с учётом отмеченных типов исходов
  newMasBk.forEach((bk) => {
    const masSportsBet = [];

    bk.sports && bk.sports.map((sport) => sport.betTypeGroups.map((sportGroupBet) => {
      return sportGroupBet.check && masSportsBet.push(sportGroupBet.id);
    }));
    bk.betTypes = masSportsBet;
    bk.bookerId = bk.id;
  });
  newMasBk = map(newMasBk, object => omit(object, ['id', 'mirrors', 'sports', 'switcher', 'currentMirror', 'name']));


  //если включена настройка "Учитывать список БК в работе фильтра", удаляем правила из объекта. В противном случае добавляем правила
  rules === '0' || rulesMasFilter.length === 0 ? (data.filterInfo = omit(data.filterInfo, ['rules'])) : (data.filterInfo.rules = rulesMasFilter);
  type !== 'middlebet' && (data.filterInfo = omit(data.filterInfo, ['onlyOverestimated', 'minWidth']));

  //data.filterInfo.isActive = true;

  return {
    ...data.filterInfo,
    betTypes,
    bookers: newMasBk,
    sports: sportMas,
  };
};

export default getDataSaveFilter;
