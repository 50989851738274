//Модальное окно удаления задания
import cloneDeep from "lodash/cloneDeep";
import differenceBy from "lodash/differenceBy";
import get from "lodash/get";
import moment from "moment";
import { push } from "connected-react-router";
import { abuseModalApi } from "../../api/request";
import paths from '../../paths';
import { selectForksEventType } from "../selectors/forks";
import formatEventFullName from "../../utils/formatEventFullName";

export const HIDE_CHANGE_PASS_ALERT = "Hide change password alert";

//Скрыть сообщение об успешной регистрации
export const HIDE_REG_SUCCESS_ALERT = "Hide registration success alert";

//Модальное окно удаления фильтра
export const OPEN_DELETE_FILTER_MODAL = "Open delete filter modal";
export const CLOSE_DELETE_FILTER_MODAL = "Close delete filter modal";

//Модальное окно закрытия окна фильтра
export const OPEN_EXIT_FILTER_WINDOW = "Open exit filter window";
export const CLOSE_EXIT_FILTER_WINDOW = "Close exit filter window";


//Модальное окно закрытия окна фильтра
export const OPEN_SIGN_IN_MODAL = "Open sign in modal";
export const CLOSE_SIGN_IN_MODAL = "Close sign in modal";

//Модальное окно закрытия окна фильтра
export const OPEN_SIGN_UP_MODAL = "Open sign up modal";
export const CLOSE_SIGN_UP_MODAL = "Close sign up modal";

//Ошибка "Соединение с сервером"
export const SHOW_SERVER_ERROR = "Show server error";
export const CLOSE_SERVER_ERROR = "Close server error";

//Ошибка "Требуется подписка"
export const SUBSCRIPTION_REQUIRED = "Subscription required";
export const SUBSCRIPTION_REQUIRED_CLOSE = "Close subscription required";

//Вывод остальных ошибок
export const SHOW_ERROR = "Show other errors";
export const CLOSE_SHOW_ERROR = "Close other errors";
export const GET_LOGOUT = "LOGOUT";
export const SET_DROP_DOWN = "Set DropDown class";
export const SET_DROP_DOWN_ABUSE = "Set DropDown Abuse class";
// Ошибка Вилка просрочена
export const FORK_IS_GONE_ERROR = "Show fork is gone error";
export const CLOSE_FORK_IS_GONE_ERROR = "Close fork is gone error";

// Открытие модального окна смены пароля
export const OPEN_RESTORE_MODAL = "Open restore password modal";
// Открытие модального окна осстановления пароля
export const OPEN_RESET_MODAL = "Open reset password modal";

//Отправить форму пажаловаться на событие
export const SET_ABUSE_START = "Set abuse modal start";
export const SET_ABUSE_SUCCESS = "Set abuse modal success";
export const SET_ABUSE_FAILURE = "Set abuse modal failed";

//Скрыть блок регистрации при успешной регистрации
export const CLOSE_SIGN_UP_BLOCK = "Close sign up block";


export const ENTER_MODAL = "Enter button to modal";
export const CLOSE_MODAL = "Close button to modal";
export const CLEAN_MODAL = "Clean modal info";


export const OPEN_SHOW_WARN = "Open warn modal";
export const CLOSE_SHOW_WARN = "Close warn modal";

export const OPEN_SUBSCRIBE_NOTIFICATION = "Open subscribe notification";
export const HIDE_SUBSCRIBE_NOTIFICATION = "Hide subscribe notification";

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';


export const showNotification = ({ type, message }) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      type,
      message,
    },
  };
};

export const closeNotification = () => {
  return {
    type: CLOSE_NOTIFICATION,
  };
};

//Скрыть сообщение об успешной смене пароля
export const hideChangePassAlert = () => ({
  type: HIDE_CHANGE_PASS_ALERT,
});

//Скрыть сообщение об успешной регистрации
export const hideRegSuccessAlert = () => ({
  type: HIDE_REG_SUCCESS_ALERT,
});

//Модальное окно удаления фильтра
export const openDeleteFilterModal = (id, type) => ({
  type: OPEN_DELETE_FILTER_MODAL,
  payload: {
    id,
    type
  }
});

export const closeDeleteFilterModal = () => ({
  type: CLOSE_DELETE_FILTER_MODAL,
});

//Модальное окно закрытия фильтра
export const openExitFilterModal = ({ location, action } = {}) => ({
  type: OPEN_EXIT_FILTER_WINDOW,
  payload: {
    location,
    action,
  },
});

export const closeExitFilterModal = (payload) => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_EXIT_FILTER_WINDOW, payload });
  };
};

export const exitFilterModalSuccess = (goBack) => {
  return async (dispatch) => {
    dispatch(closeExitFilterModal({ success: true }))
      .then(() => {
        if (goBack) goBack();
      });
  };
};

export const getEventsList = (list, prevList, isSound, sortBy = false) => {
  const listDef = cloneDeep(list);
  if (prevList.length > 0 && isSound) {
    differenceBy(listDef, prevList, 'id')
      .map((el) => { el.isNewEvent = true; return el; });
  }
  //сортировка вилок по убывающему проценту (или по параметру из Личного Кабинета - букмекеры (для коридоров)
  //return sortBy ? sortBy(listDef, 'profit', 'desc') : listDef;
  // сортировку попросили убрать
  return listDef;
};

export const openSignInModal = () => {
  return async (dispatch) => {
    dispatch({ type: OPEN_SIGN_IN_MODAL });
  };
};

export const closeSignInModal = () => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_SIGN_IN_MODAL });
  };
};

export const openSignUpModal = () => {
  return async (dispatch) => {
    dispatch({ type: OPEN_SIGN_UP_MODAL });
    //Если мы были на странице восстановления пароля, переходим обратно на главную
    if (window.location.pathname === paths.recovery()) dispatch(push(paths.index()));
  };
};

export const closeSignUpModal = () => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_SIGN_UP_MODAL });
  };
};


export const closeSignUpBlock = () => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_SIGN_UP_BLOCK });
  };
};

//Показать сообщение с оформлением/возобновлением подписки
export const openSubscribeNotification = () => ({
  type: OPEN_SUBSCRIBE_NOTIFICATION,
});

//Скрыть сообщение с оформлением/возобновлением подписки
export const hideSubscribeNotification = () => ({
  type: HIDE_SUBSCRIBE_NOTIFICATION,
});

export const showResponseMessage = (error) => dispatch => {
  const message = get(error, 'response.data.message');
  if (message) {
    dispatch({
      type: SHOW_ERROR,
      payload: {
        message
      }
    });
  }
};

export const showError = (message) => ({
  type: SHOW_ERROR,
  payload: {
    message
  }
});

const getLogout = () => {
  if (window.location.pathname === '/profile') {
    window.location.href = '/';
  } return {
    type: GET_LOGOUT,
  };
};

export const showServerErrors = (error, message, params) => {
  return async (dispatch) => {
    if (error.response && error.response.status === 401) return dispatch(getLogout());
    if ((error.response && error.response.status === 500) || error === 500) return dispatch({ type: SHOW_SERVER_ERROR });
    if (error.response && error.response.status === 403) return dispatch({ type: SUBSCRIPTION_REQUIRED });
    if (error.response && error.response.status === 410) return dispatch({ type: FORK_IS_GONE_ERROR });
    return dispatch(
      {
        type: SHOW_ERROR,
        payload: {
          message,
          params,
        }
      }
    );
  };
};


export const closeServerErrors = () => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_SERVER_ERROR });
    dispatch({ type: SUBSCRIPTION_REQUIRED_CLOSE });
    dispatch({ type: CLOSE_SHOW_ERROR });
    dispatch({ type: CLOSE_FORK_IS_GONE_ERROR });
  };
};

export const setDropDown = id => {
  return {
    type: SET_DROP_DOWN,
    payload: {
      id
    }
  };
};

export const setDropDownAbuse = id => {
  return {
    type: SET_DROP_DOWN_ABUSE,
    payload: {
      id
    }
  };
};

//Второй этап восстановления пароля
export const toggleRestorePasswordModal = (resetCode) => {
  return {
    type: OPEN_RESTORE_MODAL,
    payload: {
      resetCode
    }
  };
};

export const openRestorePasswordModal = (resetCode) => {
  return async function (dispatch, getState) {
    dispatch(toggleRestorePasswordModal(resetCode));
    //Если модальное онко восстановления пароля закрыли,
    //То редирект на главную
    if (window.location.pathname !== paths.index()) dispatch(push(paths.index()));
  };
};

//Первый этап восстановления пароля
export const toggleResetModal = (onlyClose) => {
  return {
    type: OPEN_RESET_MODAL,
    payload: {
      onlyClose
    }
  };
};

export const openResetModal = (onlyClose) => {
  return async function (dispatch) {
    dispatch(toggleResetModal(onlyClose));
    //Если модальное онко восстановления пароля закрыли,
    //То редирект на главную
    if (onlyClose) dispatch(push(paths.index()));
    else if ((window.location.pathname === paths.index()) && !onlyClose) dispatch(push(paths.recovery()));
  };
};

const setAbuseStart = () => ({
  type: SET_ABUSE_START,
});

const setAbuseSuccess = (successMessage) => dispatch => {
  dispatch({
    type: SET_ABUSE_SUCCESS,
    payload: {
    }
  });
  dispatch(showNotification({
    type: 'success',
    message: successMessage,
  }));
  dispatch(setDropDownAbuse('-1'));
};

//Отправить модалку пожаловаться на событие
export const setAbuseModal = ({
  reasons,
  description,
  fork,
  successMessage,
}) => async (dispatch, getState) => {
  const eventType = selectForksEventType(getState());
  const leftShoulder = 'leftShoulder' in fork ? fork.leftShoulder : fork.bet;
  const rightShoulder = 'rightShoulder' in fork ? fork.rightShoulder : null;

  const getShoulderData = (sholder) => ({
    betId: sholder.betId,
    eventId: sholder.eventId,
    coefficient: sholder.coefficient,
    contraryCount: sholder.contraryCount, 
    fullName: formatEventFullName(sholder),
    betName: sholder.betName,
  });

  const formatDate = (t) => {
    return moment(typeof t === 'string' ? t : Number(t) * 1000).unix();
  };

  const data = {
    reasons,
    eventType,
    description: description || null,
    fork: {
      eventsLinkId: fork.eventsLinkId,
      profit: fork.profit,
      updateTime: formatDate(fork.updateTime),
      updateTime2: fork.updateTime2 ? formatDate(fork.updateTime2) : null,
      leftShoulder: getShoulderData(leftShoulder),
      rightShoulder: rightShoulder ? getShoulderData(rightShoulder) : null,
    }
  };
  dispatch(setAbuseStart());
  abuseModalApi.setModal({ ...data, eventType })
    .then((res) => {
      dispatch(setAbuseSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(setAbuseFailure(error, dispatch));
    });
};

const setAbuseFailure = (error, dispatch) => {
  dispatch(showServerErrors(error, "fail_send_abuse_modal"));
  return {
    type: SET_ABUSE_FAILURE,
    payload: {
      error: "fail_send_abuse_modal"
    }
  };
};

export const enterModal = (modal) => {
  return {
    type: ENTER_MODAL,
    payload: {
      modal
    }
  };
};

export const closeModal = (modal) => {
  return {
    type: CLOSE_MODAL,
    payload: {
      modal
    }
  };
};

export const cleanModal = () => {
  return {
    type: CLEAN_MODAL,
  };
};


export const keyUpModal = (button, closeFunc, enterFunc, isValid = true) => {
  return async function (dispatch) {
    if (button === 'close') {
      closeFunc();
      dispatch(cleanModal());
    }
    if (button === 'enter' && isValid) {
      enterFunc();
      dispatch(cleanModal());
    }
  };
};
export const chooseModal = (key) => {
  return async function (dispatch, getState) {
    if (key === 27) {
      if (getState().ui.signInModal.isOpen) {
        dispatch(closeModal('signInModal'));
      } else if (getState().ui.signUpModal.isOpen) {
        dispatch(closeModal('signUpModal'));
      } else if (getState().ui.openResetModal) {
        dispatch(closeModal('openResetModalParam'));
      } else if (getState().ui.openRestorePasswordModal) {
        dispatch(closeModal('openRestorePasswordPerem'));
      } else {
        dispatch(closeModal(''));
      }
    }
    if (key === 13) {
      if (getState().ui.signInModal.isOpen) {
        dispatch(enterModal('signInModal'));
      } if (getState().ui.signUpModal.isOpen) {
        dispatch(enterModal('signUpModal'));
      } if (getState().ui.openResetModal) {
        dispatch(enterModal('openResetModalParam'));
      } if (getState().ui.openRestorePasswordModal) {
        dispatch(enterModal('openRestorePasswordPerem'));
      } else {
        dispatch(enterModal(''));
      }
    }
  };
};

export const openShowWarningAsync = (payload) => {
  return {
    type: OPEN_SHOW_WARN,
    payload,
  };
};

export const closeShowWarningAsync = () => {
  return {
    type: CLOSE_SHOW_WARN,
  };
};
