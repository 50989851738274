import { createSelector } from 'reselect';

export const selectCalc = (state: any) => state.calc;

export const selectCalcIsAutoUpdateOn = (calc: any): boolean => calc.isAutoUpdateOn;

export const selectFork = (state: any) => selectCalc(state).fork;

export const selectBkMasValues = (state: any) => selectCalc(state).bkMasValues;

export const selectLeftShoulderBets = createSelector(
  selectFork,
  (fork) => fork.leftShoulderBets
);

export const selectRightShoulderBets = createSelector(
  selectFork,
  (fork) => fork.rightShoulderBets
);
