import pick from "lodash/pick";
import { currencyRatesApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";

//Загрузка курсов валют (в профиле пользователя)
export const CURRENCY_RATES_LOADING_START = "Currency rates loading start";
export const CURRENCY_RATES_LOADED_SUCCESSFULLY = "Currency rates loaded successfully";
export const CURRENCY_RATES_LOADING_FAILED = "Currency rates loading failed";

//Сохранение нового курса валют
export const CURRENCY_RATES_EDITING_START = "Currency rates editing start";
export const CURRENCY_RATES_EDITED_SUCCESSFULLY = "Currency rates edited successfully";
export const CURRENCY_RATES_EDITING_FAILED = "Currency rates loading failed";

//Редактирование курсов валют
export const SET_USER_RATE = "Set user rate";
export const USE_CB_RATE = "Use CB rate";
export const USE_USER_RATE = "Use user rate";
export const SET_DEFAULT_CURRENCY = "Set default currency";

//ЗАГРУЗКА КУРСОВ ВАЛЮТ
const startLoading = () => ({
  type: CURRENCY_RATES_LOADING_START
});

const loadSuccess = data => ({
  type: CURRENCY_RATES_LOADED_SUCCESSFULLY,
  payload: {
    data: data || {}
  }
});


const loadFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_currency"));
  return {
    type: CURRENCY_RATES_LOADING_FAILED,
    payload: {
      error: "fail_load_currency"
    }
  };
};


export const loadCurrencyRatesAsync = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let rates;
    try {
      rates = await currencyRatesApi.getCurrencyRates();
    } catch (err) {
      return dispatch(loadFailure(err, dispatch));
    }
    return dispatch(loadSuccess(rates.data));
  };
};

//УСТАНОВКА НОВЫХ КУРСОВ ВАЛЮТ
const startEditing = () => ({
  type: CURRENCY_RATES_EDITING_START
});

//Она не изменяет state, так как потом сразу идет загрузка новых данных
const editingSuccess = () => ({
  type: CURRENCY_RATES_EDITED_SUCCESSFULLY,
});


const editingFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_edit_currency"));
  return {
    type: CURRENCY_RATES_EDITING_FAILED,
    payload: {
      error: "fail_edit_currency"
    }
  };
};


export const saveCurrencyRatesAsync = () => {
  return async (dispatch, getState) => {
    dispatch(startEditing());
    const currentState = getState();
    let data = {};
    const currentRates = { ...currentState.currencyRates };
    const newRates = currentRates.rates.map((rate) => {
      const newRate = pick(rate, [
        'id',
        'userRate',
      ]);
      return newRate;
    });
    currentRates.rates = newRates;
    const newUseDefaultRates = currentState.currencyRates.useDefaultRates !== -1;
    data = pick(currentRates, [
      'rates',
      'defaultCurrencyId',
    ]);
    try {
      await currencyRatesApi.saveCurrencyRates({ ...data, ...{ useDefaultRates: newUseDefaultRates }, });
    } catch (err) {
      return dispatch(editingFailure(err, dispatch));
    }
    dispatch(loadCurrencyRatesAsync());
    return dispatch(editingSuccess());
  };
};

//РЕДАКТИРОВАНИЕ НАСТРОЕК КУРСОВ ВАЛЮТ
export const setUserRate = (value, index) => ({
  type: SET_USER_RATE,
  payload: {
    value,
    index
  }
});

//Установить валюту по умолчанию
export const setDefaultCurrency = (id, rate) => ({
  type: SET_DEFAULT_CURRENCY,
  payload: {
    id,
    rate,
  }
});

export const useCBRate = (index) => {
  return async (dispatch, getState) => {
    const cbRates = getState().currencyRates.rates;
    const rate = cbRates[index].calculatedDefaultRate;
    dispatch({ type: USE_CB_RATE, payload: { rate, index } });
  };
};

export const useUserRate = (index) => {
  return async (dispatch) => {
    dispatch({ type: USE_USER_RATE, payload: { index } });
  };
};