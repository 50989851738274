export default {
  //apiDomain: process.env.REACT_APP_API_DOMAIN || `${window.location.protocol}//${window.location.host}`,
  apiDomain: process.env.REACT_APP_API_DOMAIN || `/`,
  apiPrefix: process.env.REACT_APP_API_PREFIX || 'api',
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '6LfX9SYnAAAAACXqMkJj7B8DV5MGVtls3GHTgORc',
  authHeader: process.env.REACT_APP_AUTH_HEADER || 'Authorization',
  referralDomain: 'https://positivebet.com', // window.location.origin
  adminWatchHeader: 'x-admin-watch',
  adminWatchQuery: 'w',
  apiAuthUser: process.env.REACT_APP_API_AUTH_USER,
  apiAuthPassword: process.env.REACT_APP_API_AUTH_PASSWORD,
  maxCalcRequestErrors: 10,
  pbSupportTelegramURL: 'https://t.me/positivebet_support',
  pbSupportTelegramName: '@positivebet_support',
  forksTelegramURL: "https://t.me/TheForks",
  forksSupportEmail: "support@theforks.ru",
  forksVKURL: 'https://vk.com/im?media=&sel=-144939528',
  autoBotVKURL: 'https://vk.com/theforks_autobot',
};
