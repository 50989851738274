import cloneDeep from "lodash/cloneDeep";
import without from 'lodash/without';
import { filtersApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";
import getDataSaveFilter from '../../utils/getDataSaveFilter';
import { colorsList } from "../../utils/colors";
import { soundsList } from "../../utils/sounds";
import EditFilterErrors from '../../enym/editFilter/EditFilterErrors';
import { loadBookmakersAsync } from "./bookmakers";
import { loadBookersLeaguesAsync } from "./bookersLeaguesActions";
import { selectEditFilterInfo, selectIsEditFilter } from "../selectors/filter";

//Загрузка фильтров (в профиле пользователя)
export const SAVE_FILTER_SETTINGS_START = "Filter save start";
export const SAVE_FILTER_SETTINGS_SUCCESS = "Filter save successfully";
export const SAVE_FILTER_SETTINGS_FAIL = "Filter save failed";


export const FILTER_BOOKMAKERS_LOADED_SUCCESSFULLY = "Filter bookmakers load successfully";
export const FILTER_BOOKMAKERS_LOADING_FAILED = "Filter bookmakers load failed";

export const CHANGE_FILTER_SETTINGS = "Change filter settings";
export const CHANGE_BREAK_EVENTS_SETTINGS = "Change break events settings";
export const CHANGE_BETS_SETTINGS = "Change bets settings";
export const CHANGE_BETS_SETTINGS_RULES = "Change bets settings for rules";
export const CHANGE_BETS_SETTINGS_BOOKERS = "Change bets settings for bookers";

export const LOAD_SPORTS_EVENTS_START = "Sports loading start";
export const LOAD_SPORTS_BETS_SETTINGS = "Sports loading main bets settings";
export const LOAD_SPORTS_BETS_FOR_BK_SETTINGS = "Sports loading bk settings";
export const LOAD_SPORTS_RULES_BETS_SETTINGS = "Sports loading rules settings";
export const LOAD_SPORTS_EVENTS_SUCCESS = "Sports loading success";
export const LOAD_SPORTS_EVENTS_FAILED = "Sports loading failed";

export const EDIT_FILTER_START = "Edit filter start";
export const EDIT_FILTER_SUCCESS = "Edit filter success";
export const EDIT_FILTER_FAILED = "Edit filter failed";


export const LOAD_FILTER_START = "Load filter start";
export const LOAD_FILTER_SUCCESS = "Load filter success";
export const LOAD_FILTER_FAILED = "Load filter failed";

export const CHANGE_RULES_CHECK = "Change rules check";

export const EDIT_FILTER_CHANGED = 'Edit filter changed';

export const SAVE_NEW_RULES = "Save new rules";
export const CREATE_NEW_RULE = "Create new rule";
export const EDIT_RULE = "Edit rule";
export const DELETE_RULE = "Delete rule";
export const CHANGE_ACTIVE_TYPE = "Change active type";
export const SELECT_RULE_BET_TYPES = "Select rule bet types";


export const CHANGE_BETS_SETTINGS_TYPE = "Change settings bets for settings";
export const START_LOAD_INITIAL_FILTER = "Start load initial filter";


export const LOAD_DEFAULT_VALUES = "Load default filter values";
export const CHANGE_DISABLED_BUTTON = "Change Disabled button";

export const CLEAR_EDIT_FILTER = "Clear edit filter";


export const clearEditFilter = () => ({
  type: CLEAR_EDIT_FILTER
});

//СОЗДАНИЕ НОВОГО ФИЛЬТРА
const saveFilterSettingsStart = () => ({
  type: SAVE_FILTER_SETTINGS_START
});

const saveFilterSettingsSuccess = (params) => ({
  type: SAVE_FILTER_SETTINGS_SUCCESS,
  payload: {
    params
  }
});

const saveFilterSettingsFailure = (message, field = false) => ({
  type: SAVE_FILTER_SETTINGS_FAIL,
  payload: {
    error: message,
    field
  }
});

export const editFilterChanged = (value) => ({
  type: EDIT_FILTER_CHANGED,
  payload: value,
});

export const saveFiltersSettings = (params, type, history, isGoBackFlag) => {
  return async (dispatch, getState) => {
    const newParams = { filterInfo: params, state: getState(), type };
    dispatch(saveFilterSettingsStart());

    const resParam = getDataSaveFilter(newParams);
    try {
      typeof resParam === 'object'
        ? await filtersApi.createNewFilter(getDataSaveFilter(newParams), type)
        : dispatch(saveFilterSettingsFailure('wrong_fields', resParam));
    } catch (err) {
      return dispatch(saveFilterSettingsFailure(err.response.data.error));
    }
    if (typeof resParam === 'object') {
      setTimeout(() => { isGoBackFlag ? history.push("/profile-filters") : history.goBack(); }, 1000);
      return dispatch(saveFilterSettingsSuccess(params));
    }
  };
};
//ИЗМЕНЕНИЕ ПАРАМЕТРОВ НАСТРОЕК ФИЛЬТРОВ
//settingNum - индекс элемента
//settingName - что мы меняем
//settingVal - значение, на которое меняем
export const changeFilterSettings = (settingNum = false, settingVal, settingName) => ({
  type: CHANGE_FILTER_SETTINGS,
  payload: {
    settingNum,
    settingVal,
    settingName
  }
});

//  ИЗМЕНИТЬ СОБЫТИЯ В ПЕРЕРЫВЕ
export const changeBreakEventsSettings = (value, sportId) => ({
  type: CHANGE_BREAK_EVENTS_SETTINGS,
  payload: {
    value,
    sportId
  }
});
//  ИЗМЕНЕНИЕ ЧЕКБОКСА В ТИПАХ ИСХОДОВ
export const changeBetsSettings = (value, type, bk = false, indexMain = false, indexSport = false, idBetType = false, betTypes) => {
  let resType;
  let betMainMas = cloneDeep(betTypes);

  if (value) {
    betMainMas.push(...(typeof idBetType === 'object' ? betTypes : [idBetType]));
  } else {
    betMainMas = without(
      betMainMas,
      ...(typeof idBetType === 'object' ? idBetType : [idBetType]),
    );
  }

  if (type === 'settings') {
    resType = CHANGE_BETS_SETTINGS;
  } else if (type === 'bookers') {
    resType = CHANGE_BETS_SETTINGS_BOOKERS;
  } else {
    resType = CHANGE_BETS_SETTINGS_RULES;
  }
  const flagAllCheck = idBetType.length > 1 && type === 'rules';
  return {
    type: resType,
    payload: {
      value,
      bk,
      indexMain,
      indexSport,
      idBetType,
      betMainMas,
      flagAllCheck,
    }
  };
};

export const mergeFilterBookmakers = (bookmakers, sports) => (dispatch, getState) => {
  const state = getState();
  const { bookers } = selectEditFilterInfo(state);

  const bkInitialParams = {
    isActive: 'optional',
    isInitiator: 'optional',
    useBetType: false,
    minCoefficient: 1,
    maxCoefficient: 1000,
    switcher: false,
  };

  const data = bookmakers.map(item => {
    const filterBookmaker = bookers.find(({ bookerId }) => bookerId === item.id)
      || { ...bkInitialParams, activeMirrorId: item.id, betTypes: [] };
    const mergedItem = { ...item, ...filterBookmaker };
    const { mas } = formatSports(sports, mergedItem.betTypes);
    mergedItem.sports = mas;

    return mergedItem;
  });

  dispatch({ type: FILTER_BOOKMAKERS_LOADED_SUCCESSFULLY,
    payload: { data }
  });
};


//ЗАГРУЗКА СПИСКА СПОРТИВНЫХ СОБЫТИ
const loadSportsEventsFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_sports"));
  return {
    type: LOAD_SPORTS_EVENTS_FAILED,
    payload: {
      error: "fail_load_sports"
    }
  };
};

const loadSportsEventsStart = () => ({
  type: LOAD_SPORTS_EVENTS_START
});

const loadSportsEventsSuccessFunc = (sports) => {
  return {
    type: LOAD_SPORTS_EVENTS_SUCCESS,
    payload: {
      sports,
    }
  };
};

export const loadSportsEvents = () => async (dispatch) => {
  dispatch(loadSportsEventsStart());
  let sports;
  try {
    sports = await filtersApi.getSports();
  } catch (err) {
    return dispatch(loadSportsEventsFailure(err, dispatch));
  }

  dispatch(loadSportsEventsSuccessFunc(sports.data.sports));
};


const formatSports = (sports, betTypes) => {
  let allFlag = false;

  const newMas = sports.map((sport) => {
    const betTypeGroups = sport.betTypeGroups.map((betType) => ({
      ...betType,
      check: (betTypes || [betType.id]).includes(betType.id),
    }));

    const maincheck = betTypeGroups.some(({ check }) => check);

    allFlag = maincheck || allFlag;

    return {
      ...sport,
      maincheck,
      betTypeGroups,
    };
  });

  return {
    mas: newMas,
    allFlag
  };
};

//ОБНОВЛЕНИЕ СПИСКА ТИПОВ СОБЫТИЙ ДЛЯ ОСНОВНЫХ НАСТРОЕК
export const loadSportsMainBetsEventsSuccess = (sports, betTypes) => (dispatch, getState) => {
  const state = getState();
  const isEditFilter = selectIsEditFilter(state);
  const { sports: filterSports } = selectEditFilterInfo(state);

  dispatch({
    type: LOAD_SPORTS_BETS_SETTINGS,
    payload: {
      ...formatSports(sports, betTypes, isEditFilter),
      sports: sports.map((item) => {
        const filterSport = filterSports.find(({ sportId }) => sportId === item.id);

        return {
          isBreakOnly: 'optional',
          ...item,
          ...(filterSport || {}),
        };
      }),
    },
  });
};

//ОБНОВЛЕНИЕ СПИСКА ТИПОВ СОБЫТИЙ ДЛЯ БУКМЕКЕРОВ
export const loadSportsBKEventsSuccess = (bookersMas) => {
  bookersMas.map(booker => {
    return booker.sports && booker.sports.forEach(sport => {
      (sport.maincheck = !sport.betTypeGroups.find(bet => !bet.check));
    });
  });
  return async (dispatch) => {
    return dispatch(() => {
      return (
        {
          type: LOAD_SPORTS_BETS_FOR_BK_SETTINGS,
          payload: {
            bookersMas
          }
        });
    });
  };
};

//ОБНОВЛЕНИЕ СПИСКА ТИПОВ СОБЫТИЙ ДЛЯ ПРАВИЛ
export const loadSportsRulesBetsEventsSuccess = (rulesSet, rulesBets = false) => {
  const newRulesSrt = cloneDeep(rulesSet);
  let allFlag = false;
  newRulesSrt.map(sport => {
    return sport.betTypeGroups.forEach((elem) => {
      if (rulesBets && rulesBets.betTypes.find(bet => bet === elem.id)) {
        elem.check = true;
        allFlag = true;
      } else {
        elem.check = false;
      }
    });
  });
  newRulesSrt.forEach(sport => { sport.maincheck = !sport.betTypeGroups.find(bet => !bet.check); });
  return (
    {
      type: LOAD_SPORTS_RULES_BETS_SETTINGS,
      payload: {
        newRulesSrt
      }
    });
};

//РЕДАКТИРОВАНИЕ ФИЛЬТРА
export const editFiltersSettings = (filterId, params, type) => {
  return async (dispatch, getState) => {
    dispatch(editFilterSettingsStart());
    const newParams = { filterInfo: params, state: getState(), type };
    const resParam = getDataSaveFilter(newParams);
    try {
      typeof resParam === 'object'
        ? await filtersApi.editFilter(filterId, resParam, type)
        : dispatch(saveFilterSettingsFailure('wrong_fields', resParam));
    } catch (err) {
      return dispatch(editFilterSettingsFailure(err, dispatch));
    }
    if (typeof resParam === 'object') {
      return dispatch(editFilterSettingsSuccess(params));
    }
  };
};

const editFilterSettingsStart = () => ({
  type: EDIT_FILTER_START
});

const editFilterSettingsSuccess = (sports) => ({
  type: EDIT_FILTER_SUCCESS,
  payload: {
    sports
  }
});


const editFilterSettingsFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_edit_filter"));
  return {
    type: EDIT_FILTER_FAILED,
    payload: {
      error: "fail_edit_filter"
    }
  };
};

//СОЗДАТЬ ПРАВИЛО
export const createNewRule = (mas = []) => ({
  type: CREATE_NEW_RULE,
  payload: {
    rulesMas: mas,
  }
});

//УДАЛИТЬ ПРАВИЛО
export const deleteNewRule = (mas = [], key = false) => (
  {
    type: DELETE_RULE,
    payload: {
      rulesMas: mas,
      key
    }
  });

//СОХРАНИТЬ НОВОЕ ПРАИВЛО
export const saveNewRules = (mas, params) => {
  return (
    {
      type: SAVE_NEW_RULES,
      payload: {
        params,
      }
    });
};

//ИЗМЕНИТЬ ПРАИВЛО
export const editRule = (params, key) => {
  return (
    {
      type: EDIT_RULE,
      payload: {
        params,
        key
      }
    });
};


//ВЫБРАТЬ ПРАВИЛО ИЗ СПИСКА
export const selectRuleBetTypes = (select) => {
  return (
    {
      type: SELECT_RULE_BET_TYPES,
      payload: {
        select
      }
    });
};

//ИЗМЕНИТЬ ТИП СОБЫТИЙ (основные настройки, букмекеры, правила)
export const changeActiveType = type => {
  return (
    {
      type: CHANGE_ACTIVE_TYPE,
      payload: {
        type,
      }
    });
};

//НАЧАЛО СОЗДАНИЯ НОВОГО ФИЛЬТРА
export const loadInitialFilter = (isEditFilter) => (dispatch) => {
  dispatch({
    type: START_LOAD_INITIAL_FILTER,
    payload: {
      isEditFilter,
    },
  });
};

//ЗАГРУЗКА ФИЛЬТРА
export const loadFilterInfo = (filterId, type) => async (dispatch) => {
  dispatch(loadFilterStart());
  let filter;
  try {
    filter = await filtersApi.getFilterInfo(filterId, type);
  } catch (err) {
    return dispatch(loadFilterFailure(err, dispatch));
  }
  dispatch(loadFilterSuccess(filter.data));
};

const loadFilterStart = () => ({
  type: LOAD_FILTER_START
});

const loadFilterSuccess = filter => ({
  type: LOAD_FILTER_SUCCESS,
  payload: {
    filterInfo: filter || {}
  }
});


const loadFilterFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  let errorType = EditFilterErrors.FAIL_LOAD;
  if (error.response && error.response.status === 404) {
    errorType = EditFilterErrors.NOT_FOUND;
  }
  dispatch(showServerErrors(error, errorType));
  return {
    type: LOAD_FILTER_FAILED,
    payload: {
      error: errorType
    }
  };
};

export const loadDefaultValuesAsync = (type, text) => {
  return async (dispatch, getState) => {
    let name = '',
      color = colorsList()[0],
      sound = '',
      count = 1;
    const filters = getState().filters.filters;
    filters.map((filter) => {
      if (filter.name.indexOf(text) !== -1) count++;
      return count;
    });
    name = text + " " + count;
    colorsList().find(defColor => {
      if (!filters.find(filter => filter.color === defColor)) { color = defColor; return color; }
      return null;
    });
    if (type === 'surebet') {
      sound = soundsList()[0].value;
    }
    if (type === 'middlebet') {
      sound = soundsList()[1].value;
    }
    if (type === 'valuebet') {
      sound = soundsList()[2].value;
    }
    return dispatch({
      type: LOAD_DEFAULT_VALUES,
      payload: {
        name,
        color,
        sound
      }
    });
  };
};

export const isDisabledButton = val => ({
  type: CHANGE_DISABLED_BUTTON,
  payload: {
    val
  }
});

export const loadEditFilterData = (filterType, filterId) => (dispatch) => {
  const isEditFilter = !!filterId;
  dispatch(loadInitialFilter(isEditFilter));
  if (isEditFilter) {
    dispatch(loadFilterInfo(filterId, filterType));
  }
  dispatch(loadBookmakersAsync());
  dispatch(loadBookersLeaguesAsync());
  dispatch(loadSportsEvents());
};