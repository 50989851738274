import { AuthAPI, userProfileApi } from '../../api/request';
import { addToken, removeToken, toggleWatchEmail } from '../../api/xhr';
import { closeSignUpModal, openSubscribeNotification, showServerErrors, closeSignUpBlock } from './ui';
import { config as configPath } from '../../paths';
import config from '../../config';
import dispatchGlobalEvent from '../../utils/dispatchGlobalEvent';
import { getRecaptcha3Token } from '../../utils/recaptcha';

export const SIGN_IN_START = "Sign-in begins";
export const SIGN_IN_SUCCESS = "Sign-in successfull";
export const SIGN_IN_FAILURE = "Sign-in failed";
export const SIGN_UP_START = "Sign-up begins";
export const SIGN_UP_SUCCESS = "Sign-up successfull";
export const SIGN_UP_FAILURE = "Sign-up failed";
export const SIGN_OUT = "Sign-out called";
export const SET_SUBSCRIPTION = "Set subscription";
export const SET_SUBSCRIPTION_TYPE = "Set subscription type";
export const SET_IS_ADMIN = 'set is admin';
export const SET_WATCH_USER = 'set watch user';
export const SET_USER_GEO = 'set user geo';
export const RESET_PASSWORD_START = "Start reset password";
export const RESET_PASSWORD_SUCCESS = "Success reset password";
export const RESET_PASSWORD_FAILED = "Fail reset password";

export const RESTORE_PASSWORD_START = "Start restore password";
export const RESTORE_PASSWORD_SUCCESS = "Success restore password";
export const RESTORE_PASSWORD_FAILED = "Fail restore password";

export const REFRESH_TOKEN_START = "Start refresh token";
export const REFRESH_TOKEN_SUCCESS = "Success refresh token";
export const REFRESH_TOKEN_FAILED = "Fail refresh token";

export const GET_LOGOUT = "Logout";
export const CLEAR_ERROR_REG = "Clear reg error";
export const CLEAR_ERROR_SIGNIN = "Clear signIn error";

export const CLOSE_RESET_SUCCESS = "Close reset success";

export const SET_USER_DATA = "Set user data";
export const ADD_ADMIN_PREVIEW = "Add admin preview";

const sigInStart = () => ({
  type: SIGN_IN_START,
});

export const setWatch = (watch) => {
  configPath({
    query: {
      [config.adminWatchQuery]: watch
    },
  });
  toggleWatchEmail(watch || null);
  return {
    type: SET_WATCH_USER,
    payload: {
      watch,
    },
  };
};

const setSubscriptions = subscriptions => {
  return {
    type: SET_SUBSCRIPTION,
    payload: {
      subscriptions
    }
  };
};

const setIsAdmin = data => {
  return {
    type: SET_IS_ADMIN,
    payload: data,
  };
};


const setSubscriptionType = subscriptions => {
  const type = subscriptions.reduce(
    (mem, s) => {
      if (s.type === 'subscription_ext') return mem;
      return s.type;
    },
    null
  );

  if (type) {
    return {
      type: SET_SUBSCRIPTION_TYPE,
      payload: {
        type
      }
    };
  }

  return () => {};
};

const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

const sigInSuccess = (payload) => {
  return {
    type: SIGN_IN_SUCCESS,
    payload,
  };
};

const sigInFailure = message => ({
  type: SIGN_IN_FAILURE,
  payload: {
    error: message
  }
});

const sigUpStart = () => ({
  type: SIGN_UP_START,
});


const sigUpSuccess = () => {
  return {
    type: SIGN_UP_SUCCESS,
  };
};

const sigUpFailure = message => ({
  type: SIGN_UP_FAILURE,
  payload: {
    error: message
  }
});

function setToken({ accessToken, refreshToken }) {
  localStorage.setItem('token', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  addToken(accessToken);
  return authCheck();
}

/**
 * Dispatch window event for plugin
 */
function dispatchLogin() {
  dispatchGlobalEvent('pb:login');
}

export const auth = (data) => {
  return async (dispatch) => {
    dispatch(sigInStart());
    const captcha = await getRecaptcha3Token();
    AuthAPI.login(data, captcha)
      .then((res) => {
        dispatch(setToken(res.data));
        dispatchLogin();
      })
      .catch(() => {
        dispatch(sigInFailure("auth_error"));
      });
  };
};

export const signUp = (data) => {
  return async (dispatch) => {
    const captcha = await getRecaptcha3Token();
    dispatch(sigUpStart());
    AuthAPI.registration(data, captcha)
      .then(async (res) => {
        if (res.status === 200) {
          dispatch(sigUpSuccess());
          dispatch(setToken(res.data));

          dispatch(closeSignUpBlock());
          dispatch(closeSignUpModal());
        } else {
          dispatch(sigUpFailure('reg_not_success'));
        }
      })
      .catch((error) => {
        if (error && !error.response) {
          console.log(error);
        } else if (error.response.status === 409) {
          dispatch(sigUpFailure("user_already_exists"));
        } else if (error.response.status === 400) {
          dispatch(sigUpFailure("incorrect_data"));
        } else {
          dispatch(sigUpFailure("reg_not_success"));
        }
      });
  };
};

export const getToken = () => {
  return localStorage.getItem('token');
};


export const signOut = () => {
  localStorage.removeItem('token');
  removeToken();
  return {
    type: SIGN_OUT,
  };
};

export const refreshTokenAsync = () => {
  return async (dispatch) => {
    dispatch(refreshTokenStart());
    const refreshToken = localStorage.getItem('token');
    AuthAPI.refresh({ refreshToken })
      .then((res) => {
        const token = res.data.accessToken;
        const refreshTokenNew = res.data.refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshTokenNew);
        dispatch(refreshTokenSuccess());
      })
      .catch(() => {
        dispatch(refreshTokenFailure("refresh_error", dispatch));
        dispatch(signOut());
      });
  };
};

const refreshTokenStart = () => ({
  type: REFRESH_TOKEN_START,
});

const refreshTokenSuccess = () => {
  return {
    type: REFRESH_TOKEN_SUCCESS
  };
};

const refreshTokenFailure = (error, dispatch) => {
  dispatch(showServerErrors(error, "refresh_error"));
  return {
    type: REFRESH_TOKEN_FAILED,
    payload: {
      error: "refresh_error"
    }
  };
};

export const getLogout = () => ({
  type: GET_LOGOUT,
});

export const setGeo = (payload) => ({
  type: SET_USER_GEO,
  payload,
});

export const tokenError = (error, dispatch) => {
  if (error.response && error.response.status === 401) {
    error.response.data.error === 'expired_token'
      ? dispatch(refreshTokenAsync())
      : localStorage.getItem('token') && dispatch(signOut());
  }
};

export const setAccessToken = (accessToken) => dispatch => {
  if (typeof accessToken === "string") {
    localStorage.setItem('token', accessToken);
    addToken(accessToken);
  }
};

export const authCheck = () => {
  return async (dispatch) => {
    let user;
    const token = localStorage.getItem('token');
    if (token) {
      try {
        user = await userProfileApi.loadUserInfoRequest();
        dispatch(sigInSuccess({
          token,
          hideUserAgreement: user.data && user.data.hideUserAgreement
        }));
      } catch (err) {
        tokenError(err, dispatch);
      }

      if (user && user.data) {
        dispatch(setSubscriptions(user.data.subscriptions));

        if (sessionStorage.getItem('subscribeNotification') === null) {
          dispatch(openSubscribeNotification());
        }
      }

      if (user && user.data && user.data.subscriptions) {
        dispatch(setSubscriptionType(user.data.subscriptions));
      }

      if (user && user.data && user.data) {
        dispatch(setIsAdmin(user.data));
      }

      if (user && user.data && user.data.email) {
        dispatch(setUserData(user.data));
        localStorage.setItem('email', user.data.email);
        dispatch(setGeo(user.data));
      }
    } else {
      const res = await userProfileApi.loadUserGeo();
      dispatch(sigInFailure(''));
      dispatch(setGeo(res.data || {}));
    }
  };
};


export const resetPassword = login => {
  return async (dispatch) => {
    dispatch(resetPasswordStart());
    AuthAPI.resetPassword({ login })
      .then((res) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((error) => {
        dispatch(resetPasswordFailure("fail_reset_password", dispatch));
      });
  };
};

const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});

const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS
  };
};

const resetPasswordFailure = (error, dispatch) => {
  dispatch(showServerErrors(error, "fail_reset_password"));
  return {
    type: RESET_PASSWORD_FAILED,
    payload: {
      error: "fail_reset_password"
    }
  };
};

export const restorePassword = password => {
  return async (dispatch) => {
    dispatch(restorePasswordStart());
    AuthAPI.restorePassword(password)
      .then((res) => {
        dispatch(restorePasswordSuccess());
      })
      .catch((error) => {
        return dispatch(restorePasswordFailure(error, dispatch));
      });
  };
};

const restorePasswordStart = () => ({
  type: RESTORE_PASSWORD_START,
});

const restorePasswordSuccess = () => {
  return {
    type: RESTORE_PASSWORD_SUCCESS
  };
};

const restorePasswordFailure = (error, dispatch) => {
  if (error.response && error.response.data.error === "wrong_reset_code") dispatch(showServerErrors(error, "wrong_reset_code"));
  dispatch(showServerErrors(error, "fail_restore_password"));
  return {
    type: RESTORE_PASSWORD_FAILED,
    payload: {
      error: "fail_restore_password"
    }
  };
};

export const clearRegError = () => {
  return {
    type: CLEAR_ERROR_REG
  };
};

export const clearSignInError = () => {
  return {
    type: CLEAR_ERROR_SIGNIN
  };
};

export const closeResetSuccess = () => {
  return {
    type: CLOSE_RESET_SUCCESS
  };
};

export const addAdminPreview = (data) => ({
  type: ADD_ADMIN_PREVIEW,
  payload: data,
});
