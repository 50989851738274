import axios from 'axios';
import config from "../config";

const apiBaseUrl = config.apiDomain + config.apiPrefix;

const headers = axios.defaults.headers;
headers[config.authHeader] = (localStorage.getItem('token') || '');
headers.common = {};
headers.common['Accept-Language'] = localStorage.getItem('i18nextLng');

if (config.apiAuthUser && config.apiAuthPassword) {
  const token = btoa(`${config.apiAuthUser}:${config.apiAuthPassword}`);
  headers.Authorization = `Basic ${token}`;
}

export const get = (url, data = false, requestConfig = {}) => {
  const baseUrl = apiBaseUrl;
  const params = new URLSearchParams();

  if (typeof data === 'object' && Object.keys(data).length > 0) {
    Object.keys(data).map((key) => {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        return;
      }
      const value = data[key];
      return params.append(key, value);
    });
  }
  return axios.get(baseUrl + url, { params, ...requestConfig });
};

export const post = (url, data = null, baseUrl = apiBaseUrl, requestConfig = {}) => {
  return axios.post(baseUrl + url, data, requestConfig);
};

export const put = (url, data = null, baseUrl = apiBaseUrl, requestConfig = {}) => {
  return axios.put(baseUrl + url, data, requestConfig);
};

export const patch = (url, data = null, baseUrl = apiBaseUrl, requestConfig = {}) => {
  return axios.patch(baseUrl + url, data, requestConfig);
};

export const del = (url, data = null, baseUrl = apiBaseUrl, requestConfig = {}) => {
  return axios.delete(baseUrl + url, data, requestConfig);
};

export function addToken(token) {
  headers[config.authHeader] = `${token || localStorage.getItem('token')}`;
}
export function removeToken() {
  delete headers[config.authHeader];
}

export function toggleWatchEmail(email) {
  if (email) {
    headers[config.adminWatchHeader] = email;
  } else {
    delete headers[config.adminWatchHeader];
  }
}
