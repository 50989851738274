import update from "immutability-helper";
import {
  //Загрузка букмекеров
  BOOKMAKERS_LOADING_START,
  BOOKMAKERS_LOADED_SUCCESSFULLY,
  BOOKMAKERS_LOADING_FAILED,
  //Редактирование букмекера
  SET_BK_LINK,
  SET_BK_CURRENCY,
  SET_BK_ROUNDING,
  SET_BK_COMMISSION,
  SET_BK_MAX_BET,
  SET_CURRENT_MIRROR,
  //Редактирование общих настроек букмекера
  SET_BK_SETTINGS_ROUNDING,
  SET_BK_SETTINGS_SORTING,
  TOGGLE_LINKS_DISPLAY,
  TOGGLE_SORT_SHOULDERS,
  //Сохранение новых настроек
  //BOOKMAKERS_EDITING_START,
  //BOOKMAKERS_EDITED_SUCCESSFULLY,
  //BOOKMAKERS_EDITING_FAILED,
} from '../actions/bookmakers';

const INITIAL_STATE = {
  ready: false,
  loading: false,
  error: {
    text: '',
    show: false,
  },
  bookmakers: [],
  settings: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Сохранение новых настроек
    //case BOOKMAKERS_EDITING_START:
    //case BOOKMAKERS_EDITED_SUCCESSFULLY:
    //case BOOKMAKERS_EDITING_FAILED:
    //Редактирование общих настроек букмекера
    case SET_BK_SETTINGS_ROUNDING:
      return update(state, {
        settings: {
          roundingPrecision: { $set: action.payload.value }
        }
      });
    case SET_BK_SETTINGS_SORTING:
      return update(state, {
        settings: {
          middlebetsSortField: { $set: action.payload.value }
        }
      });
    case TOGGLE_LINKS_DISPLAY:
      return update(state, {
        settings: {
          hideLinks: { $set: !state.settings.hideLinks }
        }
      });

    case TOGGLE_SORT_SHOULDERS:
      return update(state, {
        settings: {
          sortShoulders: { $set: !state.settings.sortShoulders }
        }
      });

    //Редактирование букмекера
    case SET_BK_LINK:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: {
              url: { $set: action.payload.value }
            }
          }
        }
      });
    case SET_BK_CURRENCY:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: {
              currencyId: { $set: action.payload.value }
            }
          }
        }
      });
    case SET_BK_ROUNDING:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: {
              roundingPrecision: { $set: action.payload.value }
            }
          }
        }
      });
    case SET_BK_COMMISSION:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: {
              commission: { $set: parseFloat(action.payload.value) }
            }
          }
        }
      });
    case SET_BK_MAX_BET:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: {
              maxBet: { $set: parseFloat(action.payload.value) }
            }
          }
        }
      });
    case SET_CURRENT_MIRROR:
      return update(state, {
        bookmakers: {
          [action.payload.index]: {
            currentMirror: { $set: action.payload.mirror }
          }
        }
      });
    //Загрузка букмекеров
    case BOOKMAKERS_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        bookmakers: [],
        settings: {},
      };
    case BOOKMAKERS_LOADED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        bookmakers: action.payload.data.bookers,
        settings: action.payload.data.settings || {},
      };
    case BOOKMAKERS_LOADING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}
