import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';

import { routerMiddleware } from "connected-react-router";
import listenerMiddleware from './redux/listeners';
import './utils/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "semantic-ui-css/semantic.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./assets/scss/main.scss";
import middlewares from './redux/middlewares';
import reducers from './redux/reducers';
import QueryChecker from './core/QueryChecker';
import useAccessToken from './hooks/checker/useAccessToken';
import history from './history';


const store = configureStore({
  reducer: reducers(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  })
    .concat(...[routerMiddleware(history), ...middlewares])
    .prepend(listenerMiddleware.middleware),
});

ReactDOM.render(
  (
    <Provider store={store}>
      <Router history={history}>
        <QueryChecker
          checkers={[
            useAccessToken,
          ]}
        >
          <App />
        </QueryChecker>
      </Router>
    </Provider>
  ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
