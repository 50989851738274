import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useQuery = <P = any>(): P | null => {
  const { search } = useLocation();

  const query = useMemo(
    () => (search ? qs.parse(search.replace(/^\?/, '')) : null),
    [search],
  );

  return query as unknown as P;
};

export default useQuery;
