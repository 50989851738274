import update from "immutability-helper";
import { BookerLeagueType } from '../types/bookersLeaguesTypes';

import {
  BOOKERS_LEAGUES_FAILED,
  BOOKERS_LEAGUES_LOADING_START,
  BOOKERS_LEAGUES_SUCCESSFULLY,
  StartBookersLeaguesActionType,
  FailedBookersLeaguesActionType,
  SuccessBookersLeaguesActionType,
  ClearBookersLeaguesActionType,
  BOOKERS_LEAGUES_CLEAR,
} from "../actions/bookersLeaguesActions";


type ActionsType = StartBookersLeaguesActionType
  | FailedBookersLeaguesActionType
  | SuccessBookersLeaguesActionType
  | ClearBookersLeaguesActionType;

const INITIAL_STATE: {
  isLoaded: boolean,
  isLoading: boolean,
  isError: boolean,
  data: BookerLeagueType[],
} = {
  isLoaded: false,
  isLoading: false,
  isError: false,
  data: [],
};

export default (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case BOOKERS_LEAGUES_LOADING_START:
      return update(state, {
        isLoading: { $set: true },
        data: { $set: [] },
      });

    case BOOKERS_LEAGUES_SUCCESSFULLY:
      return update(state, {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        data: { $set: action.payload },
      });

    case BOOKERS_LEAGUES_FAILED:
      return update(state, {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        isError: { $set: true },
      });

    case BOOKERS_LEAGUES_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};
