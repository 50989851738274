import {
  TARIFFS_LIST_LOADING_START,
  TARIFFS_LIST_LOADED_SUCCESSFULLY,
  TARIFFS_LIST_LOADING_FAILED,
  TOGGLE_TARIFFS_PLUS,
  SELECT_TARIFFS_PLAN,
  CHANGE_PAYMENT_OPERATOR
} from "../actions/tariffs";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  error: {
    text: '',
    show: false,
  },
  tariffs: [],
  selectTariff: {},
  paymentType: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_TARIFFS_PLUS:
      return {
        ...state,
        plus: action.payload.isTransition ? action.payload.isTransition : action.payload.plus,
      };
    //Загрузка списка тарифов
    case TARIFFS_LIST_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        tariffs: [],
      };
    case TARIFFS_LIST_LOADED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        tariffs: action.payload.tariffs,
      };
    case TARIFFS_LIST_LOADING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    case SELECT_TARIFFS_PLAN:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        selectTariff: action.payload.tariff
      };
    case CHANGE_PAYMENT_OPERATOR:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        paymentType: action.payload.paymentType
      };
    default:
      return state;
  }
}
