import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';
import qs from 'qs';
import useQuery from '../utils/useQuery';
import { setAccessToken } from '../../redux/actions/auth';

const useAccessToken = () => {
  const query = useQuery<{ accessToken?: string }>();
  const { accessToken } = query || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(
    () => {
      if (accessToken) {
        dispatch(setAccessToken(accessToken));
        const omitQuery = omit(query, 'accessToken');
        const queryString = qs.stringify(omitQuery);
        history.replace(
          `${pathname}${query ? `?${queryString}` : ''}`,
        );
      }
    },
    [accessToken],
  );

  return useMemo(() => !accessToken, [accessToken]);
};

export default useAccessToken;
