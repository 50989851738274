import React, { memo } from "react";
import "./preloader.scss";

type PreloaderPropsType = {
  className?: string | undefined,
};


const Preloader = ({ className }: PreloaderPropsType) => (
  <section className={`preloader ${className || ''}`}>
    <div className="sk-folding-cube">
      <div className="sk-cube sk-cube-1" />
      <div className="sk-cube sk-cube-2" />
      <div className="sk-cube sk-cube-4" />
      <div className="sk-cube sk-cube-3" />
    </div>
  </section>
);


export default memo(Preloader);
