import { filtersApi } from '../../api/request';
import { CLOSE_DELETE_FILTER_MODAL, showServerErrors } from "./ui";
import { tokenError } from "./auth";
import { setBookersLoaded } from './forksUpdating';

//Загрузка фильтров (в профиле пользователя)
export const FILTERS_LOADING_START = "Filters loading start";
export const FILTERS_LOADED_SUCCESSFULLY = "Filters loaded successfully";
export const FILTERS_LOADING_FAILED = "Filters loading failed";

export const FILTER_DELETION_START = "Filter deletion start";
export const FILTER_DELETED_SUCCESSFULLY = "Filter deleted successfully";
export const FILTER_DELETION_FAILED = "Filter deletion failed";

export const SAVE_FILTER_SETTINGS_START = "Filter save start";
export const SAVE_FILTER_SETTINGS_SUCCESS = "Filter save successfully";
export const SAVE_FILTER_SETTINGS_FAIL = "Filter save failed";

export const GET_FILTERS_START = "Get filter start";
export const GET_FILTERS_SUCCESS = "Get filter success";
export const GET_FILTERS_FAILED = "Get filter failed";

export const GET_ALL_FILTERS_SUCCESS = "Get all filter success";

export const SET_FILTER_ACTIVE_START = "Set filter active start";
export const SET_FILTER_ACTIVE_SUCCESS = "Set filter active success";
export const SET_FILTER_ACTIVE_FAILED = "Set filter active failed";

export const CHANGE_RULES_CHECK = "Change rules check";

//ЗАГРУЗКА ФИЛЬТРОВ ПОЛЬЗОВАТЕЛЯ
const startLoading = () => ({
  type: FILTERS_LOADING_START
});

const loadSuccess = filters => ({
  type: FILTERS_LOADED_SUCCESSFULLY,
  payload: {
    filters: filters || []
  }
});

const loadFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_filters"));
  return {
    type: FILTERS_LOADING_FAILED,
    payload: {
      error: "fail_load_filters"
    }
  };
};

// TODO: не используется. Дублирует getFilterSettings, который используется в компонентах
export const loadFiltersAsync = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let filters;
    try {
      filters = await filtersApi.getFilters();
    } catch (err) {
      return dispatch(loadFailure(err, dispatch));
    }
    return dispatch(loadSuccess(filters));
  };
};

//УДАЛЕНИЕ ФИЛЬТРА
const startDeletion = () => ({
  type: FILTER_DELETION_START
});

const deletionSuccess = (id, type) => ({
  type: FILTER_DELETED_SUCCESSFULLY,
  payload: {
    id,
    type
  }
});


const deletionFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  let message = "fail_delete_filter";
  if (error.response && error.response.status === 409) {
    message = "fail_delete_last_filter";
  }
  dispatch(showServerErrors(error, message));
  return {
    type: FILTER_DELETION_FAILED,
    payload: {
      error: "fail_delete_filter"
    }
  };
};
//АКТИВАЦИЯ-ДЕЗАКТИВАЦИЯ ПРАВИЛ

export const changeRulesCheck = (rules) => ({
  type: CHANGE_RULES_CHECK,
  payload: {
    rules
  }
});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_FILTER_MODAL
});


const getFilterSettingsStart = () => ({
  type: GET_FILTERS_START
});

const getFilterSettingsSuccess = (filters, type) => ({
  type: GET_FILTERS_SUCCESS,
  payload: {
    filters,
    type
  }
});

const getAllFilterSettingsSuccess = (filters) => ({
  type: GET_ALL_FILTERS_SUCCESS,
  payload: filters,
});

export const deleteFilterAsync = (id, type) => {
  return async (dispatch) => {
    dispatch(startDeletion());
    try {
      await filtersApi.deleteFilter(id, type);
    } catch (err) {
      dispatch(closeDeleteModal());
      return dispatch(deletionFailure(err, dispatch));
    }
    //Закрыть модалку, сообщить об успехе
    //dispatch(closeDeleteModal());
    return dispatch(deletionSuccess(id, type));
  };
};

const getFilterSettingsFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_get_filter"));
  return {
    type: GET_FILTERS_FAILED,
    payload: {
      error: "fail_get_filter"
    }
  };
};

export const fetchAllFilters = (filterTab) => async (dispatch) => {
  dispatch(getFilterSettingsStart());
  let response;
  try {
    response = await filtersApi.getAllFilters();
  } catch (err) {
    return dispatch(getFilterSettingsFailure(err, dispatch));
  }
  return dispatch(getAllFilterSettingsSuccess(response.data));
};

//ПОЛУЧЕНИЕ СПИСКА ФИЛЬТРОВ
export const getFiltersSettings = (type) => {
  return async (dispatch) => {
    dispatch(getFilterSettingsStart());
    let response;
    try {
      response = await filtersApi.getFiltersList(type);
      if (response && response.data && response.data.filters) {
        // let filters = response.data.filters;
        /* eslint-disable no-await-in-loop */
        // for (let i = 0; i < filters.length; i++) {
        //   let filter = filters[i];
        //
        //   if (filter.isActive) {
        //     const filterDetailsResponse = await filtersApi.getFilterInfo(filter.id, `/${type}`);
        //     if (filterDetailsResponse && filterDetailsResponse.data) {
        //       filter.bookers = filterDetailsResponse.data.bookers;
        //     }
        //   }
        // }
      }
    } catch (err) {
      // err.response.status === 401 && dispatch(getLogout());
      return dispatch(getFilterSettingsFailure(err, dispatch));
    }
    return dispatch(getFilterSettingsSuccess(response.data.filters, type));
  };
};

//АКТИВАЦИЯ ФИЛЬТРА

const setFilterActiveStart = () => ({
  type: SET_FILTER_ACTIVE_START
});

const setFilterActiveSuccess = (activeElems, id, active, bookers) => ({
  type: SET_FILTER_ACTIVE_SUCCESS,
  payload: {
    activeElems,
    id,
    active,
    bookers
  }
});
const setFilterActiveFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_active_filter"));
  return {
    type: SET_FILTER_ACTIVE_FAILED,
    payload: {
      error: "fail_active_filter"
    }
  };
};

export const setFilterActive = (activeElems, id, active = false, type) => {
  return async (dispatch) => {
    dispatch(setFilterActiveStart());
    let bookers;
    try {
      await filtersApi.setFilterActiveRequest(activeElems, type);

      if (active) {
        const filterDetailsResponse = await filtersApi.getFilterInfo(id, `/${type}`);
        if (filterDetailsResponse && filterDetailsResponse.data) {
          bookers = filterDetailsResponse.data.bookers;
        }
      }
    } catch (err) {
      return dispatch(setFilterActiveFailure(err, dispatch));
    }
    dispatch(setBookersLoaded(false));
    return dispatch(setFilterActiveSuccess(activeElems, id, active, bookers));
  };
};
