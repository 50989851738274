import cloneDeep from "lodash/cloneDeep";
import filterTypeMap from '../../utils/filterTypeMap';

import {
  FILTERS_LOADING_START,
  FILTERS_LOADED_SUCCESSFULLY,
  FILTERS_LOADING_FAILED,

  FILTER_DELETION_START,
  FILTER_DELETED_SUCCESSFULLY,
  FILTER_DELETION_FAILED,

  SAVE_FILTER_SETTINGS_SUCCESS,
  SAVE_FILTER_SETTINGS_START,
  SAVE_FILTER_SETTINGS_FAIL,

  // ПОЛУЧЕНИЕ ФИЛЬТРОВ
  GET_FILTERS_START,
  GET_FILTERS_SUCCESS,
  GET_FILTERS_FAILED,

  SET_FILTER_ACTIVE_START,
  SET_FILTER_ACTIVE_SUCCESS,
  SET_FILTER_ACTIVE_FAILED,
  GET_ALL_FILTERS_SUCCESS,
} from '../actions/filters';
import { SIGN_OUT } from '../actions/auth';

const INITIAL_STATE = {
  ready: false,
  loading: false,
  saveFilter: false,
  error: {
    text: '',
    show: false,
  },
  filters: [],
  sports: [],

  saveRule: {},
  all: {
    surebets: [],
    middlebets: [],
    valuebets: [],
  },
  filtersLength: {
    surebet: 0,
    middlebet: 0,
    valuebet: 0
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Загрузка фильтров (в профиле пользователя)
    case FILTERS_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        filters: [],
      };
    case FILTERS_LOADED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        filters: action.payload.filters,
      };
    case FILTERS_LOADING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };


    case SAVE_FILTER_SETTINGS_START:
      return {
        ...state,
        ready: false,
        loading: true,
        saveFilter: false,
        error: {
          text: '',
          show: false,
        },
      };
    case SAVE_FILTER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: true,
        saveFilter: true,
      };
    case SAVE_FILTER_SETTINGS_FAIL:
      return {
        ...state,
        ready: false,
        loading: false,
        saveFilter: false,
        error: {
          text: action.payload.error,
          show: true
        },
      };


    //  ПОЛУЧЕНИЕ СПИСКА ФИЛЬТРОВ
    case GET_FILTERS_START:
      return {
        ...state,
        ready: false,
        loading: true,
        saveFilter: false,
        error: {
          text: '',
          show: false,
        },
      };

    case GET_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        all: action.payload,
        filtersLength: {
          surebet: action.payload.surebets.length,
          middlebet: action.payload.middlebets.length,
          valuebet: action.payload.valuebets.length,
        }
      };

    case GET_FILTERS_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },

        filters: action.payload.filters,
        filtersLength: {
          ...state.filtersLength,
          [action.payload.type]: action.payload.filters.length
        }
      };
    case GET_FILTERS_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

    //  УДАЛЕНИЕ ФИЛЬТРА
    case FILTER_DELETION_START:
      return {
        ...state,
        ready: true,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case FILTER_DELETED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        all: {
          ...state.all,
          [filterTypeMap[action.payload.type]]: state.all[filterTypeMap[action.payload.type]]
            .filter((item) => item.id !== action.payload.id),
        },
        filtersLength: {
          ...state.filtersLength,
          [action.payload.type]: state.filtersLength[action.payload.type] - 1
        }
      };
    case FILTER_DELETION_FAILED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //  АКТЕВАЦИЯ - ДЕАКТИВАЦИЯ ФИЛЬТРА
    case SET_FILTER_ACTIVE_START:
      return {
        ...state,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case SET_FILTER_ACTIVE_SUCCESS:
      const newFilters = cloneDeep(state.filters);
      const filter = newFilters.find(f => f.id === action.payload.id);
      filter.isActive = action.payload.active;
      filter.bookers = action.payload.bookers;
      return {
        ...state,
        loading: false,
        filters: newFilters
      };
    case SET_FILTER_ACTIVE_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

    case SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}
