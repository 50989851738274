import { post, get, patch, del } from './xhr';
import { interruptRequest, addInterruptToken } from './interrupt';

// Авторизация
export const AuthAPI = {
  login(params, captcha) {
    return post('/auth/login', params, undefined, { headers: { recaptcha: captcha } });
  },
  refresh(token) {
    return post('/auth/refresh-token', token);
  },
  registration(params, captcha) {
    return post('/auth/register', params, undefined, { headers: { recaptcha: captcha } });
  },
  resetPassword(login) {
    return post('/auth/reset', login);
  },
  restorePassword(password) {
    return post('/auth/restore', password);
  },
};

//Профиль пользователя (вкладка Профиль)
export const userProfileApi = {
  //Загрузка информации о пользователе
  loadUserInfoRequest() {
    return get('/profile');
  },

  loadUserGeo() {
    return get('/profile/geo');
  },

  //Смена пароля
  changePasswordRequest(params) {
    return patch('/profile/password', params);
  },
};

export const statisticProfileApi = {
  //Загрузка статистики в профиле
  loadStatistic(type = 'basic') {
    return get(`/profile/partnership?type=${type}`);
  },
  loadDetails(period, type = 'basic') {
    return get(`/profile/partnership/period/${period}?type=${type}`);
  },
  loadUsersTable(type = 'basic') {
    return get(`/profile/partnership/users?type=${type}`);
  },
};

//Курсы валют в профиле пользователя
export const currencyRatesApi = {
  //Загрузка списка курсов валют
  getCurrencyRates() {
    return get('/currencies');
  },

  //Сохранение нового курса валют
  saveCurrencyRates(params) {
    return post('/currencies', params);
  },
};

//Список букмекеров
export const bookmakersApi = {
  //Загрузка списка букмекеров
  getBookmakers() {
    return get('/profile/bookers');
  },
  saveBookmakers(params) {
    return post('/profile/bookers', params);
  },
};

//Фильтры пользователя
export const filtersApi = {
  //Загрузка списка фильтров
  // getFilters() {
  //   return [
  //     { id: 0, title: 'Только футбол на победы до 5 с', color: 'yellow', sound: 'loud_bass', settings: ['Футбол победы', 'Возраст от 0 до 5 с', 'Исключая 1xbet', '+10'], },
  //     { id: 1, title: 'Теннис', color: 'green', sound: 'ambient_2', settings: ['Теннис: все ставки'] },
  //     { id: 2, title: 'Williams Hill', color: 'red', sound: 'loud_bass', settings: ['Только БК Williams Hill'] },
  //     { id: 3, title: 'Только Vbet', color: 'no_color', sound: 'ambient_1', settings: ['Только БК Vbet'] },
  //   ];
  // },

  //Удалить 1 фильтр
  deleteFilter(id, type) {
    return del(`/filters/${type}/${id}`);
  },


  //Список спортивных состязаний

  getSports() {
    return get('/sports');
  },

  createNewFilter(params, type) {
    return post(`/filters/${type}s`, params);
  },


  editFilter(filterId, params, type) {
    return patch(`/filters/${type}s/${filterId}`, params);
  },

  getFiltersList(type, params) {
    return get(`/filters/${type}s/`, params);
  },

  getFilterInfo(filterId, type) {
    return get(`/filters/${type}s/${filterId}`);
  },

  setFilterActiveRequest(params, type) {
    return post(`/filters/${type}s/setActive`, params);
  },

  getAllFilters() {
    return get('/filters');
  },
};

//Подписки пользователя
export const subscriptionsApi = {
  //Загрузка списка подписок
  // getSubscriptions() {
  //   return [
  //     { id: 0, plan: 'one_month', startDate: new Date(), endDate: new Date(), paymentDate: new Date(), stops: 1 },
  //     { id: 1, plan: 'two_months', startDate: new Date(), endDate: new Date(), paymentDate: new Date(), stops: 2 },
  //     { id: 2, plan: 'one_month', startDate: new Date(), endDate: new Date(), paymentDate: new Date(), stops: 1 },
  //     { id: 3, plan: 'week', startDate: new Date(), endDate: new Date(), paymentDate: new Date(), stops: 0 },
  //     { id: 4, plan: 'day', startDate: new Date(), endDate: new Date(), paymentDate: new Date(), stops: 0 },
  //   ];
  // },
  getProfileSubscriptions(type) {
    return get(`/profile/subscriptions?type=${type}`);
  },
  setSubscriptionFreeze(type) {
    return post(`/subscriptions/freeze?type=${type}`);
  },
  setSubscriptionUnFreeze(type) {
    return post(`/subscriptions/unfreeze?type=${type}`);
  },
  getSubscriptionTransform() {
    return get('/subscriptions/transform');
  },
  setSubscriptionTransform() {
    return post('/subscriptions/transform');
  },
};

const getListFn = (path) => (data, options = {}) => {
  const { recaptchaToken } = options;
  return get(path, data, { headers: { recaptcha: recaptchaToken } });
};


export const liveForksApi = {
  getlist: getListFn('/bets/surebets'),
  updeteFork(params, options = {}) {
    const { recaptchaToken } = options;
    return get('/bets', params, {
      cancelToken: addInterruptToken('fork').token,
      headers: { recaptcha: recaptchaToken }
    });
  },
  interruptUpdeteFork() {
    interruptRequest('fork');
  },
  getUrl(params) {
    return get('/bets/url', params);
  }
};

export const middlebetsApi = {
  getlist: getListFn('/bets/middlebets'),
};

export const valuebetsApi = {
  getlist: getListFn('/bets/valuebets'),
};

export const getForksApi = {
  surebet: liveForksApi.getlist,
  middlebet: middlebetsApi.getlist,
  valuebet: valuebetsApi.getlist,
};

export const hideEventsApi = {
  hide(params) {
    return post('/bets/hide', params);
  },
  show(params) {
    return post('/bets/show', params);
  },
  getHidden() {
    return get('/bets/hidden');
  }
};

export const abuseModalApi = {
  setModal(data) {
    return post('/bets/abuse', data);
  },
};

//Cписок всех тарифов
export const tariffsApi = {
  // getTariffs() {
  //   //return [];
  //   return [
  //     { id: 0, plan: 'day', suspenses: 0, price1: 8.9, price2: 560, sale1: 9.9, sale2: 620 },
  //     { id: 1, plan: 'week', suspenses: 1, price1: 27, price2: 1699, sale1: 29.9, sale2: 1875 },
  //     { id: 2, plan: 'one_month', suspenses: 2, price1: 89, price2: 5599, sale1: 99, sale2: 6211 },
  //     { id: 3, plan: 'two_months', suspenses: 4, price1: 224, price2: 14092, sale1: 249, sale2: 15622 },
  //   ];
  // },
  getTariffs() {
    //return [];
    return get('/subscriptions');
  },
};

export const paymentApi = {
  getPaymentLink(params) {
    return get('/payments/link', params);
  },
  getPaymentInvoice(params) {
    return get('/payments/invoice', params);
  },
};

export const contactsApi = {
  loadFile(file) {
    return post('/files/upload', file);
  },
  postFeedback(params) {
    return post('/feedback', params);
  },
};

export const referalApi = {
  becomePartner() {
    return post('/profile/partnership/enter');
  },
};

export const bookersApi = {
  getLeagues() {
    return get('/bookers/leagues');
  },
};
