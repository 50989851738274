import {
  LOAD_STATISTIC_START,
  LOAD_STATISTIC_SUCCESS,
  LOAD_STATISTIC_FAILED,
  LOAD_DETAILS_START,
  LOAD_DETAILS_SUCCESS,
  LOAD_DETAILS_FAILED,
  LOAD_USERS_TABLE_START,
  LOAD_USERS_TABLE_SUCCESS,
  LOAD_USERS_TABLE_FAIL
} from "../actions/statistic";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  error: {
    text: '',
    show: false,
  },
  stat: {},
  openDetails: '-1',
  details: [],
  users: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Загрузка статистики (в профиле пользователя)
    case LOAD_STATISTIC_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        stat: {},
      };
    case LOAD_STATISTIC_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        stat: action.payload.statistic,
      };
    case LOAD_STATISTIC_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //Получение детальной информации за отдельный период (в Личном кабинете)
    case LOAD_DETAILS_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        openDetails: action.payload.period,
        details: action.payload.details,
      };
    case LOAD_DETAILS_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //Получение детальной информации за отдельный период (в Личном кабинете)
    case LOAD_USERS_TABLE_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        //users: [],
      };
    case LOAD_USERS_TABLE_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        users: action.payload.users,
      };
    case LOAD_USERS_TABLE_FAIL:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}
