import memoize from "lodash/memoize";
import config from "../config";

const API_KEY = config.googleApiKey;
const CAPTCHA_API = 'https://www.google.com/recaptcha/api.js';

/**
 * Добавляем скрипт
 */
const createScript = new Promise((r) => {
  const script = document.createElement('script');

  script.src = `${CAPTCHA_API}?render=${API_KEY}`;
  document.body.appendChild(script);

  script.onload = () => {
    r(true);
  };
});


export const getRecaptcha3 = () => memoize(async () => {
  await createScript;
});


const getToken = (action: string) => new Promise((r) => {
  grecaptcha.ready(() => {
    grecaptcha.execute(API_KEY, { action }).then((token) => {
      r(token);
    });
  });
});

export const getRecaptcha3Token = async (action: string) => {
  await getRecaptcha3();

  const token = await getToken(action);

  return token;
};