import update from "immutability-helper";
import {
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_OUT,
  GET_LOGOUT,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_TYPE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESTORE_PASSWORD_START,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAILED,
  REFRESH_TOKEN_START,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
  CLEAR_ERROR_REG,
  CLEAR_ERROR_SIGNIN,
  SET_USER_DATA,
  CLOSE_RESET_SUCCESS,
  SET_IS_ADMIN,
  SET_WATCH_USER,
  ADD_ADMIN_PREVIEW,
  SET_USER_GEO,
} from '../actions/auth';

const INITIAL_STATE = {
  loading: true,
  token: '',
  isAuth: false,
  email: '',
  userId: '',
  adminData: [],
  isReg: false,
  error: '',
  unAuthorised: false,
  errorReg: '',
  isPlus: false,
  subscriptions: null,
  errorRestore: '',
  loadingReg: false,
  showRecoverySuccess: false,
  signInLoading: false,
  isAdmin: false,
  hideUserAgreement: true,
  featuresAccess: {
    bkLink: false,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN_START:
      return {
        ...state,
        signInLoading: true,
        error: null,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signInLoading: false,
        loading: false,
        token: action.payload.token,
        hideUserAgreement: action.payload.hideUserAgreement,
        isAuth: true,

      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: !!action.payload.isAdmin,
        isFeatureTester: !!action.payload.isFeatureTester,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        signInLoading: false,
        loading: false,
        error: action.payload.error
      };
    case SIGN_UP_START:
      return {
        ...state,
        loadingReg: true,
        isReg: false,
        error: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loadingReg: false,
        isReg: true,
        loading: false,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        loadingReg: false,
        loading: false,
        errorReg: action.payload.error
      };
    case CLEAR_ERROR_REG:
      return {
        ...state,
        loading: false,
        errorReg: ""
      };
    case CLEAR_ERROR_SIGNIN:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case SIGN_OUT:
      return {
        ...INITIAL_STATE,
        loading: false,
        isAuth: false,
        token: ''
      };
    case GET_LOGOUT:
      return update(state, {
        unAuthorised: { $set: true },
      });
    case SET_SUBSCRIPTION:
      return update(state, {
        subscriptions: { $set: action.payload.subscriptions }
      });
    case SET_SUBSCRIPTION_TYPE:
      return update(state, {
        isPlus: { $set: action.payload.type !== 'subscription' }
      });
    case SET_USER_GEO:
      return {
        ...state,
        ip: action.payload.ip,
        ipBasedCountryCode: action.payload.ipBasedCountryCode,
      };

    case RESET_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        showRecoverySuccess: true
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false
      };
    case RESTORE_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
        errorRestore: null
      };
    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESTORE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        errorRestore: action.payload.error
      };
    case REFRESH_TOKEN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REFRESH_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case SET_USER_DATA:
      return {
        ...state,
        featuresAccess: action.payload.featuresAccess,
        email: action.payload.email,
        userId: action.payload.id,
      };
    case ADD_ADMIN_PREVIEW:
      return {
        ...state,
        adminData: action.payload,
      };
    case SET_WATCH_USER:
      return {
        ...state,
        watch: action.payload.watch,
      };
    case CLOSE_RESET_SUCCESS:
      return {
        ...state,
        showRecoverySuccess: false
      };
    default:
      return state;
  }
}
