import {
  SUBSCRIPTIONS_LOADING_START,
  SUBSCRIPTIONS_LOADED_SUCCESSFULLY,
  SUBSCRIPTIONS_LOADING_FAILED,
  FREEZE_SUBSCRIPTION_START,
  FREEZE_SUBSCRIPTION_SUCCESS,
  FREEZE_SUBSCRIPTION_FAILED,
  UNFREEZE_SUBSCRIPTION_START,
  UNFREEZE_SUBSCRIPTION_FAILED,
  UNFREEZE_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_TRANSFORM_START,
  GET_SUBSCRIPTION_TRANSFORM_SUCCESS,
  GET_SUBSCRIPTION_TRANSFORM_FAILED,
  SET_SUBSCRIPTION_TRANSFORM_START,
  SET_SUBSCRIPTION_TRANSFORM_SUCCESS,
  SET_SUBSCRIPTION_TRANSFORM_FAILED
} from "../actions/subscriptions";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  error: {
    text: '',
    show: false,
  },
  subscriptionValidUntil: '',
  extendedSubscriptionValidUntil: '',
  subscriptions: [],
  showTransformModal: false,
  suspenses: [
    { id: 0, number: 1, status: 'done', duration: 4, activateDate: new Date() },
    { id: 1, number: 2, status: 'active', duration: 0, activateDate: new Date() },
    { id: 2, number: 3, status: 'available', duration: 0, activateDate: null }
  ],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Загрузка подписок (в профиле пользователя)
    case SUBSCRIPTIONS_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        subscriptions: [],
      };
    case SUBSCRIPTIONS_LOADED_SUCCESSFULLY:
      // Сервер некорректно отвечает на запрос GET /profile/subscriptions?type=extended сразу после вызова POST /subscriptions/freeze, в ответе сервера флаг isFrozen=false.
      // Сразу после перезагрузки страницы сервер возвращает корретный флаг isFrozen=true.
      // По этой причине делаем доп. проверку на статус приостановки по наличию массива freeze.
      const { subscriptions } = action.payload;

      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        subscriptions,
      };
    case SUBSCRIPTIONS_LOADING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //Приостановка подписки
    case FREEZE_SUBSCRIPTION_START:
      return {
        ...state,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case FREEZE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          text: '',
          show: false,
        },
      };
    case FREEZE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //Снятие подписки с паузы
    case UNFREEZE_SUBSCRIPTION_START:
      return {
        ...state,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case UNFREEZE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          text: '',
          show: false,
        },
      };
    case UNFREEZE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

    //Преобразование подписки в подписку + (получение информации)
    case GET_SUBSCRIPTION_TRANSFORM_START:
      return {
        ...state,
        ready: true,
        showTransformModal: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case GET_SUBSCRIPTION_TRANSFORM_SUCCESS:
      const { subscriptionValidUntil, extendedSubscriptionValidUntil } = action.payload;
      return {
        ...state,
        ready: true,
        loading: false,
        showTransformModal: false,
        extendedSubscriptionValidUntil,
        subscriptionValidUntil,
        error: {
          text: '',
          show: false,
        },
      };
    case GET_SUBSCRIPTION_TRANSFORM_FAILED:
      return {
        ...state,
        showTransformModal: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    //Преобразование подписки в подписку +
    case SET_SUBSCRIPTION_TRANSFORM_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case SET_SUBSCRIPTION_TRANSFORM_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
      };
    case SET_SUBSCRIPTION_TRANSFORM_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}
