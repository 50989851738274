import React, { useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useIsAuth from '../../../hooks/useIsAuth';
import { openSignInModal } from '../../../redux/actions/ui';
import useBindActionCreators from '../../../hooks/utils/useBindActionCreators';
import { selectUi } from '../../../redux/selectors/ui';
import paths from '../../../paths';

type AuthGuardPropsType = {
  children: React.ReactElement,
}

const AuthGuard = (props: AuthGuardPropsType): React.ReactElement | null => {
  const { children } = props;
  const isAuth = useIsAuth();
  const history = useHistory();
  const { signInModal: { isOpen } } = useSelector(selectUi);
  const { openSignInModal: openSingIn } = useBindActionCreators({ openSignInModal });

  useEffect(
    () => {
      if (!isAuth) {
        openSingIn();
      }
    },
    [],
  );

  useEffect(
    () => {
      if (!isAuth) {
        history.push(paths.index());
      }
    },
    [isOpen, isAuth],
  );

  if (!isAuth) {
    return null;
  }

  return children;
};

export default AuthGuard;
