// eslint-disable-next-line import/prefer-default-export
import { createSelector } from 'reselect';

export const selectEditFilter = (state: any) => state.editFilter;

export const selectEditFilterInfo = createSelector(
  selectEditFilter,
  (editFilter) => editFilter.filterInfo,
);

export const selectEditFilterRules = createSelector(
  selectEditFilter,
  (editFilter) => editFilter.rules,
);

export const selectEditFilterChanged = createSelector(
  selectEditFilter,
  (editFilter) => editFilter.checked,
);

export const selectIsEditFilter = createSelector(
  selectEditFilter,
  ({ isNew }) => !isNew
);
