export const FilterColors = {
  red: 'red',
  dark_red: 'darkred',
  yellow: 'yellow',
  orange: 'orange',
  light_green: 'lightgreen',
  green: 'green',
  gray: 'gray',
  cyan: 'cyan',
  blue: 'blue',
  black: 'black',
  no_color: '',
};


export const colorsList = () => {
  return Object.keys(FilterColors);
};
