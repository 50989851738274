import React from "react";

const IcClose = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96027 8.29376C8.76069 8.09698 8.44086 8.10054 8.24575 8.3014C8.05055 8.50234 8.054 8.82472 8.25333 9.02142L12.1415 12.8547C12.2399 12.9517 12.3674 12.9999 12.4949 12.9999C12.626 12.9999 12.7571 12.9488 12.856 12.8469C13.0512 12.646 13.0478 12.3236 12.8484 12.1269L8.96027 8.29376Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M6.49586 7.25825C6.59447 7.35762 6.72381 7.40735 6.85316 7.40735C6.98251 7.40735 7.11177 7.35762 7.21046 7.25825C7.40777 7.05943 7.40777 6.73706 7.21046 6.53823L1.8625 1.14912C1.66519 0.950295 1.3452 0.950295 1.14798 1.14912C0.950674 1.34794 0.950674 1.67031 1.14798 1.86913L6.49586 7.25825Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M12.5889 1.22675C12.3916 1.02793 12.0716 1.02793 11.8744 1.22675L1.22415 11.959C1.02685 12.1578 1.02685 12.4801 1.22415 12.679C1.32276 12.7783 1.4521 12.8281 1.58145 12.8281C1.7108 12.8281 1.84006 12.7783 1.93875 12.679L12.5889 1.94685C12.7862 1.74795 12.7862 1.42566 12.5889 1.22675Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);

export default IcClose;
