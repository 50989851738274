import FormData from "form-data";
import concat from "lodash/concat";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { contactsApi } from '../../api/request';

import { showServerErrors } from "./ui";
import { tokenError } from "./auth";

export const LOAD_FILES_START = "Load files start";
export const LOAD_FILES_SUCCESS = "Load files successfull";
export const LOAD_FILES_FAILURE = "Load files failed";

export const SEND_FEEDBACK_START = "Send feedback start";
export const SEND_FEEDBACK_SUCCESS = "Send feedback successfull";
export const SEND_FEEDBACK_FAILURE = "Send feedback failed";

export const DELETE_FILE = "Delete file";

const acceptTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'image/png',
  'image/gif',
];
export const maxSizeMb = 5;
export const maxSize = maxSizeMb * 1024 * 1024;

export const validateType = (file) => {
  return acceptTypes.includes(file.type) ? 'fail_format' : null;
};

export const validateSize = (file) => {
  return file.size > maxSize ? 'fail_large' : null;
};

const fileValidators = [
  // validateType,
  validateSize,
];

const validateFile = (file) => fileValidators
  .map((validator) => validator(file))
  .filter(error => !!error);

export const loadFilesAsync = (files) => (dispatch, getState) => {
  const bodyFormDataNew = new FormData();

  for (let i = 0; i < files.length; i++) {
    const [error] = validateFile(files[i]);

    if (error) {
      dispatch(loadFilesFailure(error));
      return;
    }
    bodyFormDataNew.append('file', files[i]);
  }

  dispatch(loadFilesStart());

  contactsApi.loadFile(bodyFormDataNew)
    .then((res) => {
      if (res.data.fileIds) {
        const newDataFiles = bodyFormDataNew.getAll('file');
        newDataFiles.map((file, index) => {
          file.id = res.data.fileIds[index];
          return file;
        });
        dispatch(loadFilesSuccess(concat(getState().contacts.files, newDataFiles), res.data.fileIds));
      }
    })
    .catch((error) => {
      dispatch(loadFilesFailure(error));
    });
};

const loadFilesStart = () => ({
  type: LOAD_FILES_START,
});


const loadFilesSuccess = (files, filesId) => ({
  type: LOAD_FILES_SUCCESS,
  payload: {
    files,
    filesId
  }
});

const loadFilesFailure = (error) => (dispatch) => {
  tokenError(error, dispatch);

  const errorMessage = error.response && error.response.status === 413 ? 'fail_large' : 'fail_load_files';

  switch (error) {
    case 'fail_large':
      dispatch(showServerErrors('format', 'fail_large', { size: maxSizeMb }));
      break;

    case 'fail_format':
      dispatch(showServerErrors('format', 'fail_format'));
      break;

    default:
      dispatch(showServerErrors(error, errorMessage, { size: maxSizeMb }));
  }

  return {
    type: LOAD_FILES_FAILURE,
    payload: {
      error: errorMessage
    }
  };
};

export const deleteFileAsync = index => {
  return async function (dispatch, getState) {
    const filesNew = cloneDeep(getState().contacts.files);
    filesNew.splice(index, 1);
    const filesIdNew = cloneDeep(getState().contacts.filesId);
    filesIdNew.splice(index, 1);
    return dispatch({ type: DELETE_FILE,
      payload: {
        files: filesNew,
        filesId: filesIdNew
      } });
  };
};

export function sendFeedbackAsync(params, filesId, onSend) {
  return async function (dispatch) {
    dispatch(sendFeedbackStart());
    contactsApi.postFeedback(merge(params, { files: filesId }))
      .then((res) => {
        dispatch(sendFeedbackSuccess());
        onSend();
      })
      .catch((error) => {
        dispatch(sendFeedbackFailure(error, dispatch));
      });
  };
}

const sendFeedbackStart = () => ({
  type: SEND_FEEDBACK_START,
});


const sendFeedbackSuccess = files => ({
  type: SEND_FEEDBACK_SUCCESS,
  payload: {
    files
  }
});

const sendFeedbackFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, 'send_feedback_error', { size: maxSizeMb }));
  return {
    type: SEND_FEEDBACK_FAILURE,
    payload: {
      error: 'send_feedback_error'
    }
  };
};
