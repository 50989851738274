import findIndex from 'lodash/findIndex';
import memoize from 'lodash/memoize';

/* eslint-disable global-require */
const sounds = [
  null,
  require("../assets/sound/sound1.mp3"),
  require("../assets/sound/sound2.mp3"),
  require("../assets/sound/sound3.mp3"),
  require("../assets/sound/sound4.mp3"),
  require("../assets/sound/sound5.mp3"),
  require("../assets/sound/sound6.mp3"),
  require("../assets/sound/sound7.mp3"),
  require("../assets/sound/sound8.mp3"),
  require("../assets/sound/sound9.mp3"),
  require("../assets/sound/sound10.mp3"),
].map((sound, index) => sound && ({
  sound,
  name: `sound_${index}`,
}));

export type SoundType = 'no_sound' | number
  | 'sound_1'
  | 'sound_2'
  | 'sound_3'
  | 'sound_4'
  | 'sound_5'
  | 'sound_6'
  | 'sound_7'
  | 'sound_8'
  | 'sound_9'
  | 'sound_10';

const audios = sounds.map(() => {
  const audio = new Audio();
  audio.autoplay = true;
  return audio;
});
const emptySound = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

export const initPlay = memoize(() => {
  audios.forEach(
    (audio) => {
      audio.src = emptySound;
    },
  );
});

export const playSound = (soundType?: SoundType | null) => {
  if (typeof soundType !== 'number' && typeof soundType !== 'string') return;

  const element = sounds.find((item, index) => {
    return item && (item.name === soundType || index === soundType);
  });

  if (!element) return;
  const index = findIndex(sounds, element);

  const audio = audios[index] || audios[0];

  if (audio) {
    audio.src = emptySound;
    setTimeout(() => {
      audio.src = element.sound;
    }, 1);
  }
};

export default playSound;
