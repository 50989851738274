import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import locales from '../locales';


type LanguageCodeType = 'ru' | 'en' | 'uk' | 'kk';

const cookies = new Cookies();


export const defaultLanguage = 'en';

export const fallbackLng = [defaultLanguage, 'ru', 'uk', 'kk'];

export const getLanguage = () => (i18n.language || defaultLanguage) as LanguageCodeType;


class LanguageDetectorPath extends LanguageDetector {
  detect(detectionOrder?: any): any {
    const lang = super.detect(detectionOrder) as string[];
    if (!fallbackLng.includes(lang[0])) {
      lang.shift();
    }
    const firstLang = typeof lang === 'string' ? lang : lang[0];
    const resultLang = firstLang ? firstLang.split('-')[0] : lang[0];

    return !resultLang || !fallbackLng.includes(resultLang) ? defaultLanguage : resultLang;
  }
}

export const promiseI18n = i18n
  .use(LanguageDetectorPath)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    fallbackNS: ['translation'],
    defaultNS: 'translation',
    debug: process.env.mode === 'development',
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    detection: {
      order: ['querystring', 'path', 'localStorage', 'cookie', 'navigator'],
      lookupCookie: 'i18nextLng',
      lookupQuerystring: 'lang',
    },
    // backend: {},
    backend: {
      backends: [
        // HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
        resourcesToBackend((language, namespace, callback) => {
          locales[namespace as keyof typeof locales][language as LanguageCodeType]()
            .then(({ default: resources }) => { // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        })
      ],
    },
  });

export default i18n;
