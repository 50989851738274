import pick from "lodash/pick";
import { bookmakersApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";

//Загрузка букмекеров (в профиле пользователя)
export const BOOKMAKERS_LOADING_START = "Bookmakers loading start";
export const BOOKMAKERS_LOADED_SUCCESSFULLY = "Bookmakers loaded successfully";
export const BOOKMAKERS_LOADING_FAILED = "Bookmakers loading failed";

//Редактирование букмекера
export const SET_BK_LINK = "Set bookmaker link";
export const SET_BK_CURRENCY = "Set bookmaker currency";
export const SET_BK_ROUNDING = "Set bookmaker rounding";
export const SET_BK_COMMISSION = "Set bookmaker commission";
export const SET_BK_MAX_BET = "Set bookmaker max bet";
export const SET_CURRENT_MIRROR = "Set current bookmaker mirror";

//Редактирование общих настроек букмекера
export const SET_BK_SETTINGS_ROUNDING = "Set rounding in bookmakers settings";
export const SET_BK_SETTINGS_SORTING = "Set sorting in bookmakers settings";
export const TOGGLE_LINKS_DISPLAY = "Toggle links display in bookmakers settings";
export const TOGGLE_SORT_SHOULDERS = "Toggle sort shoulders in bookmakers settings";

//Сохранение новых настроек
export const BOOKMAKERS_EDITING_START = "Bookmakers editing start";
export const BOOKMAKERS_EDITED_SUCCESSFULLY = "Bookmakers edited successfully";
export const BOOKMAKERS_EDITING_FAILED = "Bookmakers editing failed";

//ЗАГРУЗКА БУКМЕКЕРОВ
const startLoading = () => ({
  type: BOOKMAKERS_LOADING_START
});

const loadSuccess = data => ({
  type: BOOKMAKERS_LOADED_SUCCESSFULLY,
  payload: {
    data: data || {}
  }
});


const loadFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_bookers"));
  return {
    type: BOOKMAKERS_LOADING_FAILED,
    payload: {
      error: "fail_load_bookers"
    }
  };
};


export const loadBookmakersAsync = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let bookmakers;
    try {
      bookmakers = await bookmakersApi.getBookmakers();
    } catch (err) {
      return dispatch(loadFailure(err, dispatch));
    }
    dispatch(loadSuccess(bookmakers.data));
  };
};

//РЕДАКТИРОВАНИЕ БУКМЕКЕРОВ

export const setBkLink = (value, index) => ({
  type: SET_BK_LINK,
  payload: {
    value,
    index,
  }
});

export const setBkCurrency = (value, index) => ({
  type: SET_BK_CURRENCY,
  payload: {
    value,
    index,
  }
});

export const setBkRounding = (value, index) => ({
  type: SET_BK_ROUNDING,
  payload: {
    value,
    index,
  }
});

export const setBkCommission = (value, index) => ({
  type: SET_BK_COMMISSION,
  payload: {
    value,
    index,
  }
});

export const setBkMaxBet = (value, index) => ({
  type: SET_BK_MAX_BET,
  payload: {
    value,
    index,
  }
});

//Выбрать зеркало по клику в списке справа
export const setCurrentMirror = (mirror, index) => ({
  type: SET_CURRENT_MIRROR,
  payload: {
    mirror,
    index,
  }
});

//Выбрать зеркало из выпадающего списка слева
export const setMirrorFromDropdown = (mirrorId, index) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    const currentBookmaker = currentState.bookmakers.bookmakers[index];
    const selectedMirror = currentBookmaker.mirrors.filter(mirror => mirror.id === mirrorId)[0];
    dispatch(setCurrentMirror(selectedMirror, index));
  };
};

//РЕДАКТИРОВАНИЕ ОБЩИХ НАСТРОЕК
export const setBkSettingsRounding = value => ({
  type: SET_BK_SETTINGS_ROUNDING,
  payload: {
    value,
  }
});

export const setBkSettingsSorting = value => ({
  type: SET_BK_SETTINGS_SORTING,
  payload: {
    value,
  }
});

export const toggleLinksDisplay = () => ({
  type: TOGGLE_LINKS_DISPLAY,
});

export const toggleSortShoulders = () => ({
  type: TOGGLE_SORT_SHOULDERS,
});

//СОХРАНЕНИЕ НОВЫХ НАСТРОЕК
const startEditing = () => ({
  type: BOOKMAKERS_EDITING_START
});

//Она не изменяет state, так как потом сразу идет загрузка новых данных
const editingSuccess = () => ({
  type: BOOKMAKERS_EDITED_SUCCESSFULLY,
});


const editingFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_edit_booker"));
  return {
    type: BOOKMAKERS_EDITING_FAILED,
    payload: {
      error: "fail_edit_booker"
    }
  };
};


export const saveBookmakersAsync = () => {
  return async (dispatch, getState) => {
    dispatch(startEditing());
    const currentState = getState();
    const currentBookmakers = { ...currentState.bookmakers };
    /* Формируем масив букмееров с нужной структурой объекта */
    /* Сохраняются ID актуального зеркала и его редактируемые поля */
    const newBookmakers = currentBookmakers.bookmakers.map((item) => {
      const currentMirror = { ...item.currentMirror };
      const newBookmaker = pick(item.currentMirror, [
        'currencyId',
        'roundingPrecision',
        'commission',
        'maxBet',
      ]);
      newBookmaker.url = currentMirror.url !== currentMirror.defaultUrl ? (currentMirror.url || null) : null;
      newBookmaker.id = item.id;
      newBookmaker.mirrorId = currentMirror.id;
      return newBookmaker;
    });
    /* Формируем объект для передачи на сервер */
    const data = {};
    data.bookers = newBookmakers;
    data.settings = currentState.bookmakers.settings;

    try {
      await bookmakersApi.saveBookmakers(data);
    } catch (err) {
      return dispatch(editingFailure(err, dispatch));
    }
    dispatch(loadBookmakersAsync());
    return dispatch(editingSuccess());
  };
};
