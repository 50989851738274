import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import find from 'lodash/find';
import { SubscriptionNameType, SubscriptionType } from '../types/auth';
import { SubscriptionName } from '../../enym/SubscriptionName';

export const selectAuth = (state: { auth: any; }) => state.auth;

export const selectIsAuth = createSelector(
  selectAuth,
  (auth): boolean => auth.isAuth
);

export const selectIsAdmin = createSelector(
  selectAuth,
  (auth): boolean => auth.isAdmin
);

export const selectUserEmail = createSelector(
  selectAuth,
  ({ email }) => email,
);

export const selectIsWatch = createSelector(
  selectAuth,
  ({ watch }) => !!watch,
);

export const useIsWatch = (): boolean => useSelector(selectIsWatch);

export const useIsAdmin = (): boolean => useSelector(selectIsAdmin);

export const useAuth = () => useSelector(selectAuth);

export const selectAuthSubscriptions = (state: any): SubscriptionType[] | null => selectAuth(state).subscriptions;

export const selectAuthIsActiveSubscription = createSelector(
  selectAuthSubscriptions,
  (subscriptions: null | SubscriptionType[]) => {
    return subscriptions && subscriptions.some(s => s.isActive);
  },
);

export const selectFreezeSubscriptions = createSelector(
  selectAuthSubscriptions,
  (subscriptions) => (subscriptions || []).filter((subscription) => subscription.isFrozen),
);

export const useSubscriptions = () => useSelector(selectAuthSubscriptions);

export const useFreezeSubscriptions = () => useSelector(selectFreezeSubscriptions);

export const useFreezeSubscription = (type: SubscriptionName) => {
  const subscriptions = useFreezeSubscriptions();

  return find(subscriptions, { type });
};

export const useCurrentSubscriptionByType = (type: SubscriptionName) => {
  const subscriptions = useSubscriptions();

  return find(subscriptions, { type });
};

const findWithExtendedPriority = (subscriptions: SubscriptionType[]) => {
  if (!subscriptions || subscriptions.length === 0) {
    return null;
  }
  const extended = find(subscriptions, { type: 'subscription_ext' });

  return extended || subscriptions[0];
};

export const useCurrentSubscription = (type?: SubscriptionNameType): null | SubscriptionType => {
  const subscriptions = useSubscriptions();

  if (!subscriptions) {
    return null;
  }

  if (type) {
    return find(subscriptions, { type }) || null;
  }
  const activeSubscriptions = subscriptions.filter((subscription) => subscription.isActive);

  const activeSubscription = findWithExtendedPriority(activeSubscriptions);

  if (activeSubscription) {
    return activeSubscription;
  }

  const frozenSubscriptions = subscriptions.filter((subscription) => subscription.isFrozen);
  const frozenSubscription = findWithExtendedPriority(frozenSubscriptions);

  if (frozenSubscription) {
    return frozenSubscription;
  }

  return findWithExtendedPriority(subscriptions);
};

export const useHasActiveSubscription = () => {
  const subscription = useCurrentSubscription();

  return !!subscription && subscription.isActive;
};

export default selectAuth;
