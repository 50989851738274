import update from "immutability-helper";
import {
  CHANGE_FILTER_SETTINGS,
  FILTER_BOOKMAKERS_LOADED_SUCCESSFULLY,
  CHANGE_BREAK_EVENTS_SETTINGS,
  CHANGE_BETS_SETTINGS,
  CHANGE_BETS_SETTINGS_RULES,
  CHANGE_BETS_SETTINGS_BOOKERS,

  // ПОЛУЧЕНИЕ ФИЛЬТРОВ
  CHANGE_RULES_CHECK,

  LOAD_FILTER_START,
  LOAD_FILTER_SUCCESS,
  LOAD_FILTER_FAILED,

  SAVE_NEW_RULES,
  CREATE_NEW_RULE,
  DELETE_RULE,
  CHANGE_ACTIVE_TYPE,
  CHANGE_BETS_SETTINGS_TYPE,

  LOAD_SPORTS_EVENTS_START,
  LOAD_SPORTS_EVENTS_SUCCESS,
  LOAD_SPORTS_BETS_SETTINGS,

  LOAD_SPORTS_BETS_FOR_BK_SETTINGS,
  LOAD_SPORTS_RULES_BETS_SETTINGS,
  LOAD_SPORTS_EVENTS_FAILED,

  EDIT_FILTER_START,
  EDIT_FILTER_SUCCESS,
  EDIT_FILTER_FAILED,

  SELECT_RULE_BET_TYPES,
  EDIT_RULE,
  SAVE_FILTER_SETTINGS_SUCCESS,
  SAVE_FILTER_SETTINGS_START,
  SAVE_FILTER_SETTINGS_FAIL,
  START_LOAD_INITIAL_FILTER,
  LOAD_DEFAULT_VALUES,
  CHANGE_DISABLED_BUTTON,
  EDIT_FILTER_CHANGED, CLEAR_EDIT_FILTER,

} from '../actions/editFilter';
import {
  SET_FILTER_ACTIVE_SUCCESS
} from '../actions/filters';

const INITIAL_STATE = {
  ready: false,
  loading: false,
  saveFilter: false,
  isNew: false,

  changed: false,

  error: {
    text: '',
    show: false,
  },
  rules: '0',
  type: "settings",
  sports: [],
  rulesMas: [],
  loadFilter: false,

  loadingFilterParams: true,
  saveRule: {},
  disabledButton: true,

  errorFilterInfo: {
    minProfit: false,
    maxProfit: false,
    minBets: false,
    maxBets: false,
    minAge: false,
    maxAge: false,
    minCoefficient: false,
    maxCoefficient: false,
    minWidth: false,
    showWAH: false,
    showQAH: false,
    showWAT: false,
    showQAT: false,
    showWAIT: false,
    showQAIT: false,
    showMirrors: false,
  },

  filterInfo: {
    sound: 'sound_1',
    color: 'no_color',
    name: '',
    minProfit: 0,
    maxProfit: 100,
    minBets: 0,
    maxBets: 1000,
    minAge: 0,
    maxAge: 1000,
    minCoefficient: 1,
    maxCoefficient: 1000,
    minWidth: 0,
    onlyOverestimated: true,
    betTypes: null,
    bookers: [],
    allowLeagues: [],
    showWAH: true,
    showQAH: true,
    showWAT: true,
    showQAT: true,
    showWAIT: true,
    showQAIT: true,
    showMirrors: true,
  },
  betsSettings: [],
  filterBookmakers: [],
  rulesBetsSettings: [],
  allFlag: false

};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILTER_ACTIVE_SUCCESS:
      if (state.filterInfo.id !== action.payload.id) {
        return state;
      }
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          isActive: action.payload.active,
        },
      };

    case CLEAR_EDIT_FILTER:
      return INITIAL_STATE;

    case LOAD_SPORTS_EVENTS_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        filters: [],
      };

      //  ЗАГРУЗКА СПИСКА СПОРТИВНЫХ СОБЫТИЙ
    case LOAD_SPORTS_EVENTS_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        filterInfo: {
          ...state.filterInfo,
          sports: state.isNew
            ? action.payload.sports.map(({ id }) => ({ sportId: id, id, isBreakOnly: 'optional' }))
            : state.filterInfo.sports,
        },
        rulesBetsSettings: action.payload.sports,
        sports: action.payload.sports,
      };

      //  ОБНОВЛЕНИЕ СПИСКА ТИПОВ БУКМЕКЕРОВ В ОСНОВНЫХ НАСТРОЙКАХ
    case LOAD_SPORTS_BETS_SETTINGS:
      return {
        ...state,
        ready: true,
        loading: false,
        loadingFilterParams: false,
        error: {
          text: '',
          show: false,
        },
        filterInfo: {
          ...state.filterInfo,
          sports: action.payload.sports,
        },
        betsSettings: action.payload.mas,
        allFlag: action.payload.allFlag
      };

      //  ОБНОВЛЕНИЕ СПИСКА ТИПОВ БУКМЕКЕРОВ В НАСТРЙОКАХ БК
    case LOAD_SPORTS_BETS_FOR_BK_SETTINGS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
      };

      //  ОБНОВЛЕНИЕ СПИСКА ТИПОВ БУКМЕКЕРОВ В ПРАВИЛАХ
    case LOAD_SPORTS_RULES_BETS_SETTINGS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        rulesBetsSettings: action.payload.newRulesSrt
      };

    case LOAD_SPORTS_EVENTS_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

    //  ИЗМЕНЕНИЕ ПАРАМЕТРОВ НАСТРОЕК ФИЛЬТРОВ
    // settingVal - значение, на которое меняем
    case CHANGE_FILTER_SETTINGS:
      const name = action.payload.settingName; //какой параметр мы меняем
      const number = action.payload.settingNum; //индекс элемента

      return (action.payload.settingNum !== false)
        ? update(state, {
          filterInfo: {
            bookers: {
              [number]: {
                [name]: { $set: action.payload.settingVal }
              }
            }
          }
        })
        : update(state, {
          filterInfo: {
            [name]: { $set: action.payload.settingVal }
          },
          errorFilterInfo: { $set: INITIAL_STATE.errorFilterInfo }
        });

      //  ИЗМЕНИТЬ СОБЫТИЯ В ПЕРЕРЫВЕ
    case CHANGE_BREAK_EVENTS_SETTINGS:
      return update(state, {
        filterInfo: {
          sports: {
            [action.payload.sportId]: { isBreakOnly: { $set: action.payload.value } }
          }
        }
      });

    case EDIT_FILTER_CHANGED:
      return update(state, {
        changed: { $set: action.payload },
      });

    case CHANGE_BETS_SETTINGS_TYPE:
      return update(state, {
        betsSettings: {
          [action.payload.mainindex]: {
            betTypeGroups: {
              [action.payload.sportIndex]: {
                check: { $set: action.payload.value }
              }
            }
          }
        }
      });

    //  ИЗМЕНЕНИЕ ЧЕКБОКСА В ТИПАХ ИСХОДОВ (ГЛАВНЫЕ НАСТРОЙКИ)
    case CHANGE_BETS_SETTINGS:
      return update(state, {
        betsSettings: {
          [action.payload.indexMain]: {
            betTypeGroups: {
              [action.payload.indexSport]: {
                check:
                    { $set: action.payload.value }
              }
            }
          }
        },
        filterInfo: {
          betTypes: { $set: action.payload.betMainMas },
        },
      });

      //  ИЗМЕНЕНИЕ ЧЕКБОКСА В ТИПАХ ИСХОДОВ (НАСТРОЙКИ ПРАВИЛ)
    case CHANGE_BETS_SETTINGS_RULES:
      let check;
      if (!action.payload.value) {
        check = false;
      } else if (state.rulesBetsSettings[action.payload.indexMain].betTypeGroups.filter(bet => !bet.check).length === 1) {
        check = true;
      }
      return update(state, {
        rulesBetsSettings: {
          [action.payload.indexMain]: {
            betTypeGroups: {
              [action.payload.indexSport]: {
                check: { $set: action.payload.value },
              }
            },
            maincheck: { $set: check === undefined ? state.rulesBetsSettings[action.payload.indexMain].maincheck : check }
          },
        }
      });

      //  ИЗМЕНЕНИЕ ЧЕКБОКСА В ТИПАХ ИСХОДОВ (НАСТРОЙКИ БК)
    case CHANGE_BETS_SETTINGS_BOOKERS:
      return update(state, {
        filterInfo: {
          bookers: {
            [action.payload.bk]: {
              betTypes: { $set: action.payload.betMainMas },
              sports: {
                [action.payload.indexMain]: {
                  betTypeGroups: {
                    [action.payload.indexSport]: {
                      check: { $set: action.payload.value },
                    }
                  },
                }
              }
            }
          },
        }
      });

    case FILTER_BOOKMAKERS_LOADED_SUCCESSFULLY:
      //добавляем в список букмекеров массив спортивных событий
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        filterInfo: {
          ...state.filterInfo,
          bookers: action.payload.data,
        }
      };

    //  ЗАГРУЗКА ИНФОРМАЦИИ О КОНКРЕТНОМ ФИЛЬТРЕ
    case LOAD_FILTER_START:
      return {
        ...state,
        loading: true,
        loadFilter: false,
        loadingFilterParams: true,
        error: {
          text: '',
          show: false,
        },
      };
    case LOAD_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        loadFilter: true,
        filterInfo: action.payload.filterInfo,
        rules: action.payload.filterInfo.rules ? '1' : '0',
        rulesMas: action.payload.filterInfo.rules ? action.payload.filterInfo.rules : []

      };
    case LOAD_FILTER_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

    //  ЗАГРУЗКА ИНФОРМАЦИИ О КОНКРЕТНОМ ФИЛЬРЕ
    case EDIT_FILTER_START:
      return {
        ...state,
        loading: true,
        saveFilter: false,
        error: {
          text: '',
          show: false,
        },
      };
    case EDIT_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        saveFilter: true,
        changed: false,
      };
    case EDIT_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        saveFilter: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };

      //  ИЗМЕНЕНИЕ ПРАВИЛ
    case CHANGE_RULES_CHECK:
      return {
        ...state,
        rules: action.payload.rules
      };

      //  СОЗДАТЬ НОВОЕ ПРАВИЛО
    case CREATE_NEW_RULE:
      return {
        ...state,
      };
      //  СОХРАНИТЬ НОВОЕ ПРАВИЛО
    case SAVE_NEW_RULES:
      const mas = state.rulesMas;
      action.payload.key ? (mas[action.payload.key] = action.payload.params) : mas.concat(action.payload.params);
      return {
        ...state,
        rulesMas: mas.concat(action.payload.params),
        changed: false,
      };
      //  ИЗМЕНИТЬ ПРАВИЛО
    case EDIT_RULE:
      const oldMas = state.rulesMas;
      oldMas[action.payload.key] = action.payload.params;

      return {
        ...state,
        rulesMas: oldMas,
      };
      //УДАЛИТЬ ПРАВИЛО
    case DELETE_RULE:
      return update(state, {
        rulesMas: { $splice: [[action.payload.key, 1]] }
      });

      //ИЗМЕНИТЬ ТИП СОБЫТИЙ (основные настройки, букмекеры, правила)
    case CHANGE_ACTIVE_TYPE:
      return {
        ...state,
        type: action.payload.type
      };

    case SELECT_RULE_BET_TYPES:
      return {
        ...state,
        saveRule: action.payload.select
      };
    case SAVE_FILTER_SETTINGS_START:
      return {
        ...state,
        errorFilterInfo: INITIAL_STATE.errorFilterInfo
      };
    case SAVE_FILTER_SETTINGS_SUCCESS:
      return {
        ...state,
        ready: false,
        loading: true,
        loadingFilterParams: true,
        error: {
          text: '',
          show: false,
        },
        filterBookmakers: [],
      };
    case SAVE_FILTER_SETTINGS_FAIL:
      return {
        ...state,
        errorFilterInfo: {
          [action.payload.field]: true
        }
      };
    case START_LOAD_INITIAL_FILTER: {
      return {
        ...state,
        isNew: !action.payload.isEditFilter,
        loadingFilterParams: true,
        rules: "0",
        filterInfo: {
          ...INITIAL_STATE.filterInfo,
        },
        type: "settings",
      };
    }
    case LOAD_DEFAULT_VALUES: {
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          name: action.payload.name,
          sound: action.payload.sound,
          color: action.payload.color
        }
      };
    }
    case CHANGE_DISABLED_BUTTON: {
      return {
        ...state,
        disabledButton: action.payload.val
      };
    }
    default:
      return state;
  }
}
