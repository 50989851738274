// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyAction, Store } from 'redux';
import {
  CHANGE_BETS_SETTINGS,
  CHANGE_BETS_SETTINGS_BOOKERS,
  CHANGE_BETS_SETTINGS_RULES,
  CHANGE_BREAK_EVENTS_SETTINGS,
  CHANGE_FILTER_SETTINGS,
  editFilterChanged,
} from '../actions/editFilter';
import { CLOSE_EXIT_FILTER_WINDOW } from '../actions/ui';
import { selectEditFilter, selectEditFilterChanged } from '../selectors/filter';

export default (store: Store) => (next: any) => (action: AnyAction) => {
  const { dispatch } = store;
  if ([
    CHANGE_FILTER_SETTINGS,
    CHANGE_BREAK_EVENTS_SETTINGS,
    CHANGE_BETS_SETTINGS,
    CHANGE_BETS_SETTINGS_RULES,
    CHANGE_BETS_SETTINGS_BOOKERS,
  ].includes(action.type)) {
    dispatch(editFilterChanged(true));
  }

  next(action);
};
