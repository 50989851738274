import React, { Component } from "react";
import PropTypes from 'prop-types';
import "./module.alert.scss";

import IcClose from "./IcClose/IcClose";

class Alert extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    status: PropTypes.string,
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    params: PropTypes.object,
  };

  static defaultProps = {
    status: '',
    params: { alwaysVisible: false },
  };

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };
  }


  static getDerivedStateFromProps(props, state) {
    if (props.isVisible !== state.isVisible) {
      if (props.isVisible && !props.params.alwaysVisible) {
        state.timerHandle = setTimeout(() => {
          props.onClose();
          state.timerHandle = 0;
        }, 3000);
      }
      return {
        isVisible: props.isVisible
      };
    } return null;
  }

  componentWillUnmount = () => {
    const { timerHandle } = this.state;
    if (timerHandle) {
      clearTimeout(timerHandle);
    }
  };

  render() {
    const { status, children, isVisible, onClose } = this.props;
    return (
      <div className={'alert ' + (isVisible ? status : ("remove " + status))}>
        <button type="button" className="alert-close-btn" onClick={() => onClose()}>
          <IcClose />
        </button>
        <p>{children}</p>
      </div>
    );
  }
}

export default Alert;
