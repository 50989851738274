import update from "immutability-helper";
import {
  USER_INFO_LOADING_START,
  USER_INFO_LOADED_SUCCESSFULLY,
  USER_INFO_LOADING_FAILED,
  SET_OLD_PASS,
  SET_NEW_PASS,
  SET_NEW_PASS2,
  //PASSWORD_CHANGE_START,
  //PASSWORD_CHANGE_SUCCESS,
  //PASSWORD_CHANGE_FAILED,
} from "../actions/userProfile";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  user: {},
  error: {
    text: '',
    show: false,
  },
  passwordForm: {
    oldPass: '',
    newPass: '',
    newPass2: '',
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Редактирование секций и полей
    case SET_OLD_PASS:
      return update(state, {
        passwordForm: {
          oldPass: { $set: action.payload.value },
        }
      });
    case SET_NEW_PASS:
      return update(state, {
        passwordForm: {
          newPass: { $set: action.payload.value },
        }
      });
    case SET_NEW_PASS2:
      return update(state, {
        passwordForm: {
          newPass2: { $set: action.payload.value },
        }
      });
    //Загрузка информации о пользователе (вкладка Профиль)
    case USER_INFO_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        user: {},
      };
    case USER_INFO_LOADED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        }, 
        user: action.payload.user,
      };
    case USER_INFO_LOADING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}