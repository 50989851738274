import { createSelector } from 'reselect';
import find from 'lodash/find';
import { BetNameType } from '../types/bets';
import { ForksGroupType } from '../types/forks';

export const selectForks = (state: any) => state.forks;

export const selectForksBookersList = createSelector(
  selectForks,
  (forks) => forks.bookersList,
);

export const selectEventName = createSelector(
  selectForks,
  ({ eventName }) => eventName,
);

export const selectForksEventType = createSelector(
  selectForks,
  (forks) => forks.eventType,
);

export const selectForksIsAutoUpdateOn = createSelector(
  selectForks,
  (forks) => forks.isAutoUpdateOn,
);

export const selectViewGroups = createSelector(
  selectForks,
  (forks) => forks.viewGroups,
);

const switchGroupList = ({
  eventType,
  groupsListLive,
  groupsListMiddlebet,
  groupsListValuebet,
}: {
  eventType: BetNameType,
  groupsListLive: ForksGroupType[],
  groupsListMiddlebet: ForksGroupType[],
  groupsListValuebet: ForksGroupType[],
}) => {
  switch (eventType) {
    case 'surebet':
      return groupsListLive;
    case 'middlebet':
      return groupsListMiddlebet;
    case 'valuebet':
      return groupsListValuebet;
    default:
      return [];
  }
};

export const selectGroupByType = (state: any, eventType: BetNameType) => {
  return switchGroupList({
    ...selectForks(state),
    eventType,
  });
};

export const selectCurrentGroups = createSelector(
  selectForks,
  switchGroupList,
);

export const selectOpenGroupList = createSelector(
  selectForks,
  selectCurrentGroups,
  (
    { openGroupId },
    groups: ForksGroupType[],
  ) => {
    if (openGroupId === null) {
      return [];
    }
    const group = find(groups, { eventsLinkId: openGroupId });

    return group ? group.list : [];
  },
);
