import { DefaultShoulderType } from '../components/template/CalculatorWide/defaultValue';
import { AllShoulderType } from '../redux/types/bets';

export default function formatEventFullName(
  {
    eventName,
    league,
    score,
  }: DefaultShoulderType | AllShoulderType
): string {
  return [eventName, league, score]
    .filter(s => !!s)
    .join(' ');
}