import orderBy from "lodash/orderBy";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import update from "immutability-helper";
import moment from "moment";
import {
  GET_LIVE_FORKS_START,
  GET_LIVE_FORKS_SUCCESS,
  GET_LIVE_FORKS_FAILURE,
} from "../actions/liveforks";
import {
  SIGN_OUT,
  SIGN_IN_SUCCESS,
} from '../actions/auth';
import {
  DO_NOT_SHOW_EVENT_START,
  DO_NOT_SHOW_EVENT_SUCCESS,
  DO_NOT_SHOW_EVENT_FAILURE,
  SET_GROUPS_START,
  SET_GROUPS_SUCCESS,
  SET_GROUPS_FAILURE,
  GET_BET_URL_START,
  GET_BET_URL_SUCCESS,
  GET_BET_URL_FAILURE,
  SHOW_HIDE_EVENTS_START,
  SHOW_HIDE_EVENTS_SUCCESS,
  SHOW_HIDE_EVENTS_FAILURE,
  GET_HIDDEN_EVENTS_START,
  GET_HIDDEN_EVENTS_SUCCESS,
  GET_HIDDEN_EVENTS_FAILURE,
  SET_PAGINATION, SET_SORT,
  OPEN_GROUP_ITEM,
  TOGGLE_VIEW_GROUPS,
  SET_FORKS_TYPE,
  CHANGE_EVENT_NAME,
} from '../actions/forks';
import {
  SWITCH_AUTO_UPDATE,
  SET_FORKS_UPDATING,
  SET_BOOKERS_LOADED,
  CLEAR_FORKS,
} from '../actions/forksUpdating';
import { GET_MIDDLEBETS_FAILURE, GET_MIDDLEBETS_START, GET_MIDDLEBETS_SUCCESS } from "../actions/middlebets";
import { GET_VALUEBETS_START, GET_VALUEBETS_SUCCESS, GET_VALUEBETS_FAILURE } from "../actions/valuebets";

const INITIAL_STATE = {
  loading: false,
  error: '',
  forkslist: [],
  groupsListLive: [],
  groupsListMiddlebet: [],
  groupsListValuebet: [],
  url: '',
  hiddenList: {},
  eventType: "surebet",
  middlebetsList: [],
  valuebetsList: [],
  paginationNum: 20,
  sort: '',
  hideLinks: true,
  bookersList: [],
  bookersLoaded: false,
  onUpdate: false,
  groupItems: [],
  viewGroups: false,
  openGroupId: null,
  isAutoUpdateOn: false,
  isForksUpdating: false,
  eventName: '',
};

const formatRequestTime = (updateTime) => {
  return moment(Date.now()).diff(updateTime, 'seconds');
};

/**
 * Добавляет isHidden к плечу если определен id
 */
const addIsHidden = (item) => {
  const isHidden = !item.id;

  if (item.bet) {
    item.bet.isHidden = isHidden;
  }
  if (item.leftShoulder) {
    item.leftShoulder.isHidden = isHidden;
    item.leftShoulder.requestTime = formatRequestTime(item.updateTime);
  }
  if (item.rightShoulder) {
    item.rightShoulder.isHidden = isHidden;
    item.rightShoulder.requestTime = formatRequestTime(item.updateTime2);
  }
  return item;
};

const prepareList = (list) => {
  return list
    .map(addIsHidden);
};

const setIfBoolean = (value, previousValue) => {
  return typeof value === 'boolean' ? value : previousValue;
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
    case SIGN_OUT:
      return update(state, {
        bookersLoaded: {
          $set: false,
        },
      });
    case SET_FORKS_TYPE:
      return update(state, {
        eventType: { $set: action.payload.eventType },
      });
    case SET_FORKS_UPDATING:
      return update(state, {
        isForksUpdating: { $set: action.payload.isLoading },
        onUpdate: { $set: action.payload.isLoading }
      });
    case SET_BOOKERS_LOADED:
      return update(state, {
        bookersLoaded: {
          $set: action.payload.isLoaded,
        },
      });
    case SWITCH_AUTO_UPDATE:
      return update(state, {
        isAutoUpdateOn: { $set: action.payload.isOn }
      });
    //получение вилок
    case GET_LIVE_FORKS_START:
      return update(state, {
        loading: { $set: !action.payload.isSound },
        error: { $set: null },
        onUpdate: { $set: true },
        eventType: { $set: "surebet" }
      });
    case GET_LIVE_FORKS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        bookersLoaded: { $set: true },
        forkslist: { $set: prepareList(action.payload.forkslist) },
        hideLinks: { $set: setIfBoolean(action.payload.hideLinks, state.hideLinks) },
        bookersList: { $set: action.payload.bookersList || state.bookersList },
      });
    case GET_LIVE_FORKS_FAILURE:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        error: { $set: action.payload.error }
      });

    //получение коридоров
    case GET_MIDDLEBETS_START:
      return update(state, {
        loading: { $set: !action.payload.isSound },
        error: { $set: null },
        onUpdate: { $set: true },
        eventType: { $set: "middlebet" }
      });
    case GET_MIDDLEBETS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        middlebetsList: { $set: prepareList(action.payload.forkslist) },
        hideLinks: { $set: setIfBoolean(action.payload.hideLinks, state.hideLinks) },
        bookersList: { $set: action.payload.bookersList || state.bookersList },
        bookersLoaded: { $set: true },
      });
    case GET_MIDDLEBETS_FAILURE:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        error: { $set: action.payload.error }
      });

      //получение переоценённых вилок
    case GET_VALUEBETS_START:
      return update(state, {
        loading: { $set: !action.payload.isSound },
        error: { $set: null },
        onUpdate: { $set: true },
        eventType: { $set: "valuebet" }
      });
    case GET_VALUEBETS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        valuebetsList: { $set: prepareList(action.payload.forkslist) },
        hideLinks: { $set: setIfBoolean(action.payload.hideLinks, state.hideLinks) },
        bookersList: { $set: action.payload.bookersList || state.bookersList },
        bookersLoaded: { $set: true },
      });
    case GET_VALUEBETS_FAILURE:
      return update(state, {
        loading: { $set: false },
        onUpdate: { $set: false },
        error: { $set: action.payload.error }
      });

      //  скрытие вилок
    case DO_NOT_SHOW_EVENT_START:
      return update(state, {
        error: { $set: null },
      });
    case DO_NOT_SHOW_EVENT_SUCCESS:
      const { eventType, newList } = action.payload;
      return update(state, {
        loading: { $set: false },
        forkslist: { $set: eventType === 'surebet' ? newList : state.forkslist },
        middlebetsList: { $set: eventType === 'middlebet' ? newList : state.middlebetsList },
        valuebetsList: { $set: eventType === 'valuebet' ? newList : state.valuebetsList },
      });
    case DO_NOT_SHOW_EVENT_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error }
      });
      //  формирование групп
    case SET_GROUPS_START:
      return update(state, {
        loading: { $set: !action.payload.isSound },
        error: { $set: null },
        onUpdate: { $set: true },
      });

    case TOGGLE_VIEW_GROUPS:
      return update(state, {
        viewGroups: { $set: action.payload.viewGroups },
        groupsListLive: { $set: [] },
        groupsListMiddlebet: { $set: [] },
        groupsListValuebet: { $set: [] },
      });

    case SET_GROUPS_SUCCESS:
      const { eventTypeGroups, list } = action.payload;
      const eventList = list;
      //сортируем вилки по убыванию процента
      const newGroupsMas = orderBy(eventList, 'profit', 'desc');
      //формируем группы по одинаковым eventsLinkId
      const newGroupList = map(
        groupBy(newGroupsMas, 'eventsLinkId'),
        (lst, eventsLinkId) => ({ eventsLinkId, list: lst }),
      );

      return update(state, {
        loading: { $set: false },
        groupsListLive: { $set: eventTypeGroups === 'surebet' ? newGroupList : state.groupsListLive },
        groupsListMiddlebet: { $set: eventTypeGroups === 'middlebet' ? newGroupList : state.groupsListMiddlebet },
        groupsListValuebet: { $set: eventTypeGroups === 'valuebet' ? newGroupList : state.groupsListValuebet },
        forkslist: { $set: [] },
        middlebetsList: { $set: [] },
        valuebetsList: { $set: [] },
        onUpdate: { $set: false },
        hideLinks: { $set: setIfBoolean(action.payload.hideLinks, state.hideLinks) },
        bookersList: { $set: action.payload.bookersList || state.bookersList },
        bookersLoaded: { $set: true },
      });
    case SET_GROUPS_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error },
        onUpdate: { $set: false },
      });
      //Получить ссылку
    case GET_BET_URL_START:
      return update(state, {
        error: { $set: null },
      });
    case GET_BET_URL_SUCCESS:
      return update(state, {
        url: { $set: action.payload.url }
      });
    case GET_BET_URL_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error }
      });
      //  получить список срытых вилок
    case GET_HIDDEN_EVENTS_START:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case GET_HIDDEN_EVENTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        hiddenList: { $set: action.payload.hiddenList }
      });
    case GET_HIDDEN_EVENTS_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error }
      });
      //  показать скрытые вилки
    case SHOW_HIDE_EVENTS_START:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case SHOW_HIDE_EVENTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
      });
    case SHOW_HIDE_EVENTS_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error }
      });
    case SET_PAGINATION:
      return update(state, {
        paginationNum: { $set: action.payload.paginationNum }
      });
    case SET_SORT:
      return update(state, {
        sort: { $set: action.payload.sort }
      });
    case OPEN_GROUP_ITEM:
      return update(state, {
        openGroupId: { $set: action.payload.groupId },
      });
    case CHANGE_EVENT_NAME:
      return update(state, {
        eventName: { $set: action.payload.eventName },
      });
    case CLEAR_FORKS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
