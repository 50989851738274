import React, { ElementType, lazy, Suspense } from 'react';
import paths, { basePaths } from './paths';
import Preloader from './components/ui/Preloader/Preloader';
import AuthGuard from './components/guards/AuthGuard';


const Main = () => import('./components/pages/Main/Main');
const Tariffs = () => import('./components/pages/Tariffs/Tariffs');
const TariffsPayment = () => import('./components/pages/TariffsPayment/TariffsPayment');
const TariffsOperator = () => import('./components/pages/TariffsOperator/TariffsOperator');
const TermsOfService = () => import('./components/pages/TermsOfService/TermsOfService');
const Plugin = () => import('./components/pages/Plugin/Plugin');
const Questions = () => import('./components/pages/Questions/Questions');
const Contacts = () => import('./components/pages/Contacts/Contacts');
const ContactsSent = () => import('./components/pages/ContactsSent/ContactsSent');
const Profile = () => import('./components/pages/Profile/Profile');
const Referal = () => import('./components/pages/Referal/Referal');
const LiveForks = () => import('./components/pages/LiveForks/LiveForks');
const Middlebets = () => import('./components/pages/Middlebets/Middlebets');
const Valuebets = () => import('./components/pages/Valuebets/Valuebets');
const FiltersPage = () => import('./components/pages/Filters');
const ForksFilter = () => import('./components/ui/ForksFilter/ForksFilter');
const PaymentSuccess = () => import('./components/pages/PaymentSuccess/PaymentSuccess');
const PaymentFail = () => import('./components/pages/PaymentFail/PaymentFail');
const TermsOfUse = () => import('./components/pages/TermsOfUse/TermsOfUse');
const Client = () => import('./components/pages/Client/Client');
const PageNotFound = () => import('./components/pages/PageNotFound/PageNotFound');
const PartnerPage = () => import('./components/pages/Partner/PartnerPage');
const Pid = () => import('./components/pages/Pid');

const createLazy = (lazyComponent: any) => {
  const Component = lazy(lazyComponent);
  return () => (
    <Suspense fallback={<Preloader />}>
      <Component />
    </Suspense>
  );
};

export type RoutesOptionsType = {
  exact?: true,
  guards: ElementType[]
};

export default [
  [Main, [paths.index(null), basePaths.index(null)], { exact: true }],
  [Main, [paths.main(null), basePaths.main(null)]],
  [Main, [paths.restore(null), basePaths.restore(null)]],
  [Main, [paths.signUp(null), basePaths.signUp(null)]],
  [Main, [paths.recovery(null), basePaths.recovery(null)]],

  // Tariffs
  [Tariffs, [paths.tariffs.index(null), basePaths.tariffs.index(null), paths.tariffs.tab(null), basePaths.tariffs.tab(null)], { exact: true }],
  [TariffsPayment, [paths.payment.plan(null), basePaths.payment.plan(null)]],
  [TariffsOperator, [paths.operator(null), basePaths.operator(null)]],
  // END

  [TermsOfService, [paths.terms(null), basePaths.terms(null)]],
  [Plugin, [paths.plugin(null), basePaths.plugin(null)]],
  [Questions, [paths.questions(null), basePaths.questions(null)]],

  [Contacts, [paths.contacts(null), basePaths.contacts(null)]],
  [ContactsSent, [paths.contactsSent(null), basePaths.contactsSent(null)]],

  // Profile
  [Profile, [paths.profile.index(null), basePaths.profile.index(null)], { exact: true }],
  [Profile, [paths.profile.filters.index(null), basePaths.profile.filters.index(null)], { exact: true }],
  [Profile, [paths.profile.bookmakers(null), basePaths.profile.bookmakers(null)]],
  [Profile, [paths.profile.account(null), basePaths.profile.account(null)]],
  [Profile, [paths.profile.subscription(null), basePaths.profile.subscription(null)]],
  [Profile, [paths.profile.subscriptionPlus(null), basePaths.profile.subscriptionPlus(null)]],
  [Profile, [paths.profile.currency(null), basePaths.profile.currency(null)]],
  [Profile, [paths.profile.filters.tab(null), basePaths.profile.filters.tab(null)], { exact: true }],
  [FiltersPage,
    [paths.profile.filters.tab(null), basePaths.profile.filters.tab(null)],
    { guards: [AuthGuard], exact: true }],

  // Create/edit filters
  [ForksFilter,
    [paths.profile.filters.create(null), basePaths.profile.filters.create(null)],
    { guards: [AuthGuard] },
  ],
  [ForksFilter,
    [paths.profile.filters.edit(null), basePaths.profile.filters.edit(null)],
    { guards: [AuthGuard] },
  ],
  // END

  [Referal,
    [paths.referal(null), basePaths.referal(null)],
    { guards: [AuthGuard] },
  ],

  // Bets
  [LiveForks, [paths.surebets(null), basePaths.surebets(null)]],
  [Middlebets, [paths.middlebets(null), basePaths.middlebets(null)]],
  [Valuebets, [paths.valuebets(null), basePaths.valuebets(null)]],

  [PaymentSuccess, [paths.paymentSuccess(null), basePaths.paymentSuccess(null)]],
  [PaymentFail, [paths.paymentFail(null), basePaths.paymentFail(null)]],
  [TermsOfUse, [paths.termsOfUse(null), basePaths.termsOfUse(null)]],

  [Client,
    [paths.client(null), basePaths.client(null)],
    { guards: [AuthGuard] },
  ],

  // Partner
  [PartnerPage,
    [paths.partners.index(null), basePaths.partners.index(null), paths.partners.api(null), basePaths.partners.api(null)],
    { guards: [AuthGuard] },
  ],
  [Pid, [basePaths.pid.pages(null)]],

  [PageNotFound, undefined],
].map(([lazyComponent, path, options]) => [createLazy(lazyComponent), path, options]) as Array<[
  any,
  string[] | undefined,
  RoutesOptionsType | undefined,
]>;
