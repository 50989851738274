import { AnyAction, Store } from 'redux';
import remove from 'lodash/remove';
import delay from 'lodash/delay';
import find from 'lodash/find';
import { ThunkDispatch } from 'redux-thunk';
import { initPlay } from '../../utils/playSound';
import {
  CLEAR_CALC,
  PAUSE_CALC_AUTO_UPDATE,
  RESUME_CALC_AUTO_UPDATE,
  SWITCH_CALC_AUTO_UPDATE,
  switchCalcAutoUpdate,
  updateFork
} from '../actions/calc';
import { SWITCH_AUTO_UPDATE, switchAutoUpdate } from '../actions/forksUpdating';
import { SET_DROP_DOWN, SET_DROP_DOWN_ABUSE } from '../actions/ui';
import { selectCalc } from '../selectors/calc';
import { updateForks } from '../actions/forks';
import { SIGN_OUT } from '../actions/auth';

const TIMEOUT_TIME = 3000;
const TIMEOUT_CALC_LIMIT = 30 * 60 * 1000;
const TIMEOUT_FORKS_LIMIT = 60 * 60 * 1000;

let timeout: number;

interface TimeoutRequest {
  type: 'forks' | 'calc',
  fn: () => void,
  off: () => void,
}

const timeoutLimits: { [type in TimeoutRequest['type']]?: number } = {};

const requests: TimeoutRequest[] = [];

const invokeRequests = (type?: TimeoutRequest['type']) => {
  if (!type) {
    return [...requests].forEach(({ fn }) => fn());
  }
  const request = find(requests, { type });

  if (request) {
    request.fn();
  }
};


const clear = () => {
  clearTimeout(timeout);
};

const invokeOffs = () => {
  if (requests.length === 0) {
    clear();
  }
};

const startLimitTimer = (
  { type, off, timeLimit }: { type: TimeoutRequest['type'], off: () => void, timeLimit: number},
) => {
  const currentTimeout = timeoutLimits[type];

  clearTimeout(currentTimeout);

  timeoutLimits[type] = window.setTimeout(
    () => {
      off();
      invokeOffs();
    },
    timeLimit,
  );
};

const start = (run: boolean, type?: TimeoutRequest['type']) => {
  if (requests.length < 1) return clear();
  if (run) invokeRequests(type);
  clear();
  timeout = window.setTimeout(() => start(true), TIMEOUT_TIME);
};

const pause = () => {
  clear();
};

const functions = {
  forks: {
    timeLimit: TIMEOUT_FORKS_LIMIT,
    play: (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
      dispatch(updateForks(true));
    },
    off: (dispatch: ThunkDispatch<any, any, any>) => {
      dispatch(switchAutoUpdate(false));
    },
  },
  calc: {
    timeLimit: TIMEOUT_CALC_LIMIT,
    play: (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
      const { isLeftAutoUpdate, isRightAutoUpdate } = selectCalc(getState());
      dispatch(updateFork({ shouldUpdateLeft: isLeftAutoUpdate, shouldUpdateRight: isRightAutoUpdate }));
    },
    off: (dispatch: ThunkDispatch<any, any, any>) => {
      dispatch(switchCalcAutoUpdate(false, false, false));
    },
  }
};

const toggleRequest = (enable: boolean, type: 'forks' | 'calc', run = false) => (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
  // Проигрываем пустой файл для разрешения проигрывания на мобильном
  initPlay();
  if (!enable) {
    remove(requests, { type });
    return;
  }
  const {
    play,
    off,
    timeLimit,
  } = functions[type];

  const offFn = () => dispatch(off);

  if (!find(requests, { type })) {
    requests.push({
      fn: () => dispatch(play),
      off: offFn,
      type,
    });
  }

  delay(() => {
    start(run, type);
  }, 0);

  startLimitTimer({
    type,
    timeLimit,
    off: offFn,
  });
};


export default (store: Store) => (next: any) => (action: AnyAction) => {
  const dispatch = store.dispatch as ThunkDispatch<any, any, any>;

  // eslint-disable-next-line default-case
  switch (action.type) {
    case SWITCH_AUTO_UPDATE:
      dispatch(toggleRequest(action.payload.isOn, 'forks', action.payload.isOn));
      break;

      // case SET_CALC_UPDATING:
      //   if (!action.payload.isLoading && selectForksIsAutoUpdateOn(store.getState())) {
      //     dispatch(toggleRequest(true, 'forks', false));
      //   }
      //   break;

    case CLEAR_CALC:
      dispatch(toggleRequest(false, 'calc', false));
      break;

    case SIGN_OUT:
      dispatch(toggleRequest(false, 'calc'));
      break;

    case SWITCH_CALC_AUTO_UPDATE:
      dispatch(toggleRequest(action.payload.isOn, 'calc', action.payload.isOn));
      break;

    case PAUSE_CALC_AUTO_UPDATE:
      pause();
      break;

    case RESUME_CALC_AUTO_UPDATE:
      start(true);
      break;

    case SET_DROP_DOWN:
    case SET_DROP_DOWN_ABUSE:
      if (action.payload.id !== '-1') {
        pause();
      } else {
        start(true);
      }
      break;
  }

  next(action);
};
