import merge from 'lodash/merge';
import { paymentApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";
import paths from '../../paths';

export const GET_PAYMENT_LINK_START = "Get payment link start";
export const GET_PAYMENT_LINK_SUCCESS = "Get payment link successfully";
export const GET_PAYMENT_LINK_FAILURE = "Get payment link failed";

export const GET_PAYMENT_INVOICE_START = "Get payment invoice start";
export const GET_PAYMENT_INVOICE_SUCCESS = "Get payment invoice successfully";
export const GET_PAYMENT_INVOICE_FAILURE = "Get payment invoice failed";


const getPaymentLinkStart = () => ({
  type: GET_PAYMENT_LINK_START
});

const getPaymentLinkSuccess = paymentLink => ({
  type: GET_PAYMENT_LINK_SUCCESS,
});

const getPaymentLinkFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_payment"));
  return {
    type: GET_PAYMENT_LINK_FAILURE,
    payload: {
      error: "fail_payment"
    }
  };
};

export const getPaymentLinkAsync = (props) => {
  const { origin } = window.location;
  const paymentProps = merge(props, {
    successUrl: `${origin}${paths.paymentSuccess()}`,
    failUrl: `${origin}${paths.paymentFail()}`,
  });
  return async (dispatch) => {
    dispatch(getPaymentLinkStart());
    let paymentLink;
    try {
      paymentLink = await paymentApi.getPaymentLink(paymentProps);
    } catch (err) {
      return dispatch(getPaymentLinkFailure(err, dispatch));
    }
    window.open(paymentLink.data.link);
    return dispatch(getPaymentLinkSuccess(paymentLink));
  };
};


const getPaymentInvoiceStart = () => ({
  type: GET_PAYMENT_INVOICE_START
});

const getPaymentInvoiceSuccess = paymentInvoice => ({
  type: GET_PAYMENT_INVOICE_SUCCESS,
  payload: {
    paymentInvoice
  }
});

const getPaymentInvoiceFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_payment"));
  return {
    type: GET_PAYMENT_INVOICE_FAILURE,
    payload: {
      error: "fail_payment"
    }
  };
};

export const getPaymentInvoiceAsync = (props) => {
  return async (dispatch) => {
    dispatch(getPaymentInvoiceStart());
    let paymentInvoice;
    try {
      paymentInvoice = await paymentApi.getPaymentInvoice(props);
    } catch (err) {
      return dispatch(getPaymentInvoiceFailure(err, dispatch));
    }
    return dispatch(getPaymentInvoiceSuccess(paymentInvoice.data));
  };
};
