import { getRecaptcha3Token } from '../../utils/recaptcha';
import { selectForks } from '../selectors/forks';

export const SWITCH_AUTO_UPDATE = "Switch auto update";
export const SET_FORKS_UPDATING = "Set forks updating";

export const SET_BOOKERS_LOADED = 'Set bookers loaded';

export const CLEAR_FORKS = 'clear forks';

export const clearForks = () => ({
  type: CLEAR_FORKS,
});

export const setForksUpdating = (isLoading) => ({
  type: SET_FORKS_UPDATING,
  payload: {
    isLoading
  }
});

export function toggleAutoUpdate(isOn) {
  return async function (dispatch) {
    dispatch(switchAutoUpdate(isOn));
  };
}

export const switchAutoUpdate = (isOn) => ({
  type: SWITCH_AUTO_UPDATE,
  payload: {
    isOn
  }
});

export const setBookersLoaded = (isLoaded) => ({
  type: SET_BOOKERS_LOADED,
  payload: {
    isLoaded,
  }
});

export const fetchForks = (
  params,
  fetchFn,
  startAction,
  successAction,
  failureAction
) => async (dispatch, getState) => {
  const { isSound } = params;
  const state = getState();
  dispatch(startAction(isSound));
  dispatch(setForksUpdating(true));

  const recaptchaToken = await getRecaptcha3Token();


  const {
    bookersLoaded,
    paginationNum,
    eventName,
  } = selectForks(state);

  const options = {
    limit: paginationNum,
  };

  if (!bookersLoaded) {
    options.bookers = true;
  }

  if (eventName) {
    options.eventName = eventName;
  }

  if (recaptchaToken) {
    options.recaptchaToken = recaptchaToken;
  }

  fetchFn(options, { recaptchaToken })
    .then(({ data }) => {
      const commonParams = {
      };
      if (data.bookersList) {
        Object.assign(commonParams, { bookersList: data.bookersList });
      }
      if (data.bookersSettings) {
        Object.assign(commonParams, { hideLinks: data.bookersSettings.hideLinks });
      }

      dispatch(successAction(data, isSound, commonParams));
      dispatch(setForksUpdating(false));
    })
    .catch((error) => {
      dispatch(toggleAutoUpdate(false));
      dispatch(failureAction(error));
      dispatch(setForksUpdating(false));
    });
};
