import find from 'lodash/find';
import some from 'lodash/some';
import config from '../../config';
import { liveForksApi } from '../../api/request';
import { showResponseMessage, showServerErrors } from "./ui";
import { tokenError } from "./auth";
import {
  selectBkMasValues,
  selectCalc,
  selectLeftShoulderBets,
  selectRightShoulderBets,
} from '../selectors/calc';
import { concatKeys } from '../../hooks/useForkId';
import { concatKeysForList } from '../../hooks/useBetList';
import { selectForks } from '../selectors/forks';
import { isInterrupt } from '../../api/interrupt';
import { getRecaptcha3Token } from '../../utils/recaptcha';
//Перемещение в калькулятор
export const MOVE_TO_CALC_START = "Start moving to calc";
export const MOVE_TO_CALC_SUCCESS = "Move to calc success";
export const MOVE_TO_CALC_FAILURE = "Move to calc failed";

//Обновить вилку
export const UPDATE_FORK_START = "Update fork start";
export const UPDATE_FORK_SUCCESS = "Update fork success";
export const UPDATE_FORK_FAILURE = "Update fork failed";
export const FORK_IS_GONE = "Fork is gone";
export const CLEAR_CALC = "CLEAR Calc";
export const CLEAR_CALC_SUCCESS = "CLEAR Calc success";
export const ONLY_MY_BK_CHANGE = "change only my bk check";
export const UPDATE_CALC_SHOULDER_BET = "UPDATE_CALC_SHOULDER_BET";

export const SWITCH_CALC_AUTO_UPDATE = "Switch calc auto update";
export const PAUSE_CALC_AUTO_UPDATE = "Pause calc auto update";
export const RESUME_CALC_AUTO_UPDATE = "Resume calc auto update";
export const SET_CALC_UPDATING = "Set calc updating";

export const RESET_CALC = "Reset calc";


export const setCalcUpdating = (isLoading) => ({
  type: SET_CALC_UPDATING,
  payload: {
    isLoading
  }
});

export const switchCalcAutoUpdate = (isOn, isLeftAutoUpdate, isRightAutoUpdate) => dispatch => {
  dispatch({
    type: SWITCH_CALC_AUTO_UPDATE,
    payload: {
      isOn,
      isLeftAutoUpdate,
      isRightAutoUpdate,
    }
  });
};

export const pauseCalcAutoUpdate = () => ({
  type: PAUSE_CALC_AUTO_UPDATE,
});

export const resumeCalcAutoUpdate = () => ({
  type: RESUME_CALC_AUTO_UPDATE,
});

export const toggleCalcAutoUpdate = isOn => (isOn ? resumeCalcAutoUpdate() : pauseCalcAutoUpdate());

//перемещение в калькулятор
export const moveToCalc = (forkInfo, eventType, id) => (dispatch) => {
  liveForksApi.interruptUpdeteFork();
  dispatch(moveToCalcSuccess(forkInfo, eventType, id));
};

//добавление вилки в калькулятор
const moveToCalcStart = () => {
  return {
    type: MOVE_TO_CALC_START,
  };
};


const moveToCalcSuccess = (forkInfo, eventType, id) => {
  return {
    type: MOVE_TO_CALC_SUCCESS,
    payload: {
      forkInfo,
      eventType,
      id
    }
  };
};

const moveToCalcFailure = message => ({
  type: MOVE_TO_CALC_FAILURE,
  payload: {
    error: message
  }
});

//очищение калькулятора
export function cleanCalc() {
  return async function (dispatch) {
    dispatch(clearCalcStart()).then(() => {
      dispatch({
        type: CLEAR_CALC_SUCCESS,
      });
    });
  };
}

const clearCalcStart = () => {
  return async function (dispatch) {
    dispatch({
      type: CLEAR_CALC,
    });
  };
};

export const resetCalc = () => {
  return function (dispatch) {
    dispatch({
      type: RESET_CALC,
    });
  };
};

export const changeBK = (params) => (dispatch, getState) => {
  const state = getState();
  const {
    newShoulder1: prevNewShoulder1,
    newShoulder2: prevNewShoulder2,
  } = selectCalc(state);
  const { shoulderLeft, shoulderRight } = params;

  const { bookersList } = selectForks(state);

  const selectBet = (shoulderValue, selectBets) => {
    if (!shoulderValue) return null;
    const bets = selectBets(state);
    const bet = find(bets, shoulderValue);
    if (!bet) {
      return null;
    }
    const booker = find(bookersList, { id: bet.bookerId });
    if (!booker) {
      return null;
    }
    bet.bookerRoundingPrecision = Number(booker.round_precision);
    bet.currencyId = Number(booker.currency_id);
    return bet;
  };


  const payload = {};
  if (shoulderLeft) {
    const newShoulder1 = selectBet(shoulderLeft, selectLeftShoulderBets) || prevNewShoulder1;
    Object.assign(payload, {
      newShoulder1,
      shoulderBKvalue1: concatKeysForList(newShoulder1),
    });
  }
  if (shoulderRight) {
    const newShoulder2 = selectBet(shoulderRight, selectRightShoulderBets) || prevNewShoulder2;
    Object.assign(payload, {
      newShoulder2,
      shoulderBKvalue2: concatKeysForList(newShoulder2),
    });
  }
  payload.openCalcFlag = concatKeys(
    payload.newShoulder1 || prevNewShoulder1,
    payload.newShoulder2 || prevNewShoulder2 || null,
  );

  dispatch({
    type: UPDATE_CALC_SHOULDER_BET,
    payload,
  });
};


//обновление вилки
export function updateFork({
  shouldUpdateLeft = false,
  shouldUpdateRight = false,
  getAllCoefficients = false,
}) {
  return async function (dispatch, getState) {
    let state = getState();
    const {
      onlyMyBk,
      eventType,
      toCalc: {
        forkInfo: {
          eventsLinkId,
          bet,
          leftShoulder,
          rightShoulder,
        },
      },
    } = selectCalc(state);

    if (!eventsLinkId) {
      return;
    }

    dispatch(setCalcUpdating(true));
    // if (!hideProgress) {
    dispatch(updateForkStart(shouldUpdateLeft, shouldUpdateRight));
    // }

    const leftShoulderOrBet = leftShoulder || bet;
    const shoulderLeft = leftShoulderOrBet ? {
      leftShoulderEventId: leftShoulderOrBet.eventId,
      leftShoulderBetId: leftShoulderOrBet.betId,
      leftShoulderBookerId: leftShoulderOrBet.bookerId,
      leftShoulderBetKey: leftShoulderOrBet.betKey,
    } : {};

    const shoulderRight = rightShoulder ? {
      rightShoulderEventId: rightShoulder.eventId,
      rightShoulderBetId: rightShoulder.betId,
      rightShoulderBookerId: rightShoulder.bookerId,
      rightShoulderBetKey: rightShoulder.betKey,
    } : {};
    const finalParams = { eventsLinkId, ...shoulderLeft, ...shoulderRight, getAllCoefficients };
    const recaptchaToken = await getRecaptcha3Token();
    liveForksApi.updeteFork(onlyMyBk ? { ...finalParams, eventType } : finalParams, { recaptchaToken })
      .then((res) => {
        dispatch(updateForkSuccess(res.data, getAllCoefficients, shouldUpdateLeft, shouldUpdateRight));
        const isEmpty1 = shouldUpdateLeft && !some(res.data.leftShoulderBets, { betId: leftShoulder && leftShoulder.betId });
        const isEmpty2 = shouldUpdateRight && !some(res.data.rightShoulderBets, { betId: rightShoulder && rightShoulder.betId });
        if (isEmpty1 || isEmpty2) {
          dispatch(forkIsGone(isEmpty1 ? 'empty' : '', isEmpty2 ? 'empty' : ''));
        }
        dispatch(setCalcUpdating(false));
      })
      .catch((error) => {
        dispatch(setCalcUpdating(false));

        if (isInterrupt(error)) {
          return;
        }

        dispatch(updateForkFailure(error));

        if (!error.response || error.response.status !== 200) {
          dispatch(forkIsGone(shouldUpdateLeft ? 'error' : '', shouldUpdateRight ? 'error' : '' ));
        }
      });
  };
}
//получение вилки

const updateForkStart = (isLeft, isRight) => ({
  type: UPDATE_FORK_START,
  payload: {
    isLeftUpdate: isLeft,
    isRightUpdate: isRight,
    isBothUpdate: isLeft && isRight
  }
});


const getShoulderBets = (shoulderBetsMas, bkmas) => {
  return shoulderBetsMas.map((item) => {
    const {
      bookerName,
      coefficient,
      bookerId,
      betId,
      eventId,
    } = item;

    return {
      text: `${bookerName}  ${coefficient}`,
      key: bookerId,
      value: `${betId}/${eventId}`,
    };
  });
};

const updateForkSuccess = (fork, getAllCoefficients, shouldUpdateLeft, shouldUpdateRight) => {
  return async function (dispatch, getState) {
    const state = getState();
    const {
      newShoulder1 = {},
      newShoulder2 = {},
    } = selectCalc(state);

    const {
      masShoulder1,
      masShoulder2,
      shoulderBKvalue1,
      shoulderBKvalue2,
    } = selectBkMasValues(state);
    let bkmas1 = masShoulder1;
    let bkmas2 = masShoulder2;

    if (fork.leftShoulderBets && shouldUpdateLeft) {
      bkmas1 = getShoulderBets(fork.leftShoulderBets, masShoulder1);
    }

    if (fork.rightShoulderBets && shouldUpdateRight) {
      bkmas2 = getShoulderBets(fork.rightShoulderBets, masShoulder2);
    }

    const nextLeft = find(fork.leftShoulderBets, { betId: newShoulder1.betId });
    const nextRight = find(fork.rightShoulderBets, { betId: newShoulder2.betId });

    const isNewShoulder1 = fork.leftShoulderBets
      && fork.leftShoulderBets.length > 0
      && shouldUpdateLeft
      && nextLeft;

    const isNewShoulder2 = fork.rightShoulderBets
      && fork.rightShoulderBets.length > 0
      && shouldUpdateRight
      && nextRight;


    const payload = {
      fork,
      getAllCoefficients,
      masShoulder1: bkmas1,
      masShoulder2: bkmas2,

      newShoulder1: isNewShoulder1 ? nextLeft : newShoulder1,
      newShoulder2: isNewShoulder2 ? nextRight : newShoulder2,

      valueBkMas1: shoulderBKvalue1,
      valueBkMas2: shoulderBKvalue2,
    };

    dispatch({
      type: UPDATE_FORK_SUCCESS,
      payload,
    });
  };
};

const updateForkFailure = (error) => (dispatch, getState) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_update_fork"));
  dispatch(showResponseMessage(error));

  const { errorsCount } = selectCalc(getState());

  if (errorsCount + 1 >= config.maxCalcRequestErrors) {
    dispatch(switchCalcAutoUpdate(false, false, false));
  }

  dispatch({
    type: UPDATE_FORK_FAILURE,
    payload: {
      error: "fail_update_fork"
    }
  });
};


const forkIsGone = (isLeft, isRight) => ({
  type: FORK_IS_GONE,
  payload: {
    expired1: isLeft,
    expired2: isRight,
  }
});

export const changeOnlyMyBk = () => ({
  type: ONLY_MY_BK_CHANGE,
});
