import {
  HIDE_CHANGE_PASS_ALERT,
  HIDE_REG_SUCCESS_ALERT,
  OPEN_DELETE_FILTER_MODAL,
  CLOSE_DELETE_FILTER_MODAL, OPEN_EXIT_FILTER_WINDOW, CLOSE_EXIT_FILTER_WINDOW,
  OPEN_SIGN_IN_MODAL,
  CLOSE_SIGN_IN_MODAL,
  OPEN_SIGN_UP_MODAL,
  CLOSE_SIGN_UP_MODAL,
  OPEN_SUBSCRIBE_NOTIFICATION,
  HIDE_SUBSCRIBE_NOTIFICATION,
  SHOW_SERVER_ERROR,
  CLOSE_SERVER_ERROR,
  SUBSCRIPTION_REQUIRED,
  SUBSCRIPTION_REQUIRED_CLOSE,
  SHOW_ERROR,
  CLOSE_SHOW_ERROR,
  GET_LOGOUT,
  SET_DROP_DOWN,
  FORK_IS_GONE_ERROR,
  CLOSE_FORK_IS_GONE_ERROR,
  OPEN_RESTORE_MODAL,
  OPEN_RESET_MODAL,
  SET_ABUSE_START,
  SET_ABUSE_SUCCESS,
  SET_DROP_DOWN_ABUSE,
  CLOSE_SIGN_UP_BLOCK,
  ENTER_MODAL, CLOSE_MODAL, CLEAN_MODAL,
  OPEN_SHOW_WARN, CLOSE_SHOW_WARN,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
} from "../actions/ui";

import {
  SIGN_UP_SUCCESS,
} from "../actions/auth";

import {
  PASSWORD_CHANGE_SUCCESS,
} from "../actions/userProfile";

import {
  FILTER_DELETED_SUCCESSFULLY,
} from "../actions/filters";

const INITIAL_STATE = {
  changePassAlert: {
    isOpen: false,
  },
  regSuccessAlert: {
    isOpen: false,
  },
  deleteFilterModal: {
    isOpen: false,
  },
  exitFilterModal: {
    isOpen: false,
  },
  signInModal: {
    isOpen: false
  },
  signUpModal: {
    isOpen: false
  },
  showServerError: {
    isOpen: false
  },
  subscriptionError: {
    isOpen: false
  },
  subscribeNotification: {
    isOpen: true,
  },
  forkIsGone: {
    isOpen: false
  },
  otherError: {
    isOpen: false,
    message: ''
  },
  notification: {
    message: null,
    type: null,
  },
  deleteFilterId: 0,
  deleteFilterType: 'surebet',
  unAuthorised: false,
  setDropDown: '-1',
  setDropDownAbuse: '-1',
  openRestorePasswordModal: false,
  openResetModal: false,
  resetCode: '',
  closeSignUpBlock: false,
  modal: '',
  button: '',
  showWarningEvents: false,
  showWarningText: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        changePassAlert: {
          isOpen: true,
        },
      };
    case HIDE_CHANGE_PASS_ALERT:
      return {
        ...state,
        changePassAlert: INITIAL_STATE.changePassAlert,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        regSuccessAlert: {
          isOpen: true,
        },
      };
    case HIDE_REG_SUCCESS_ALERT:
      return {
        ...state,
        regSuccessAlert: INITIAL_STATE.regSuccessAlert,
      };
    //Удаление фильтра
    case OPEN_DELETE_FILTER_MODAL:
      return {
        ...state,
        deleteFilterModal: {
          isOpen: true,
        },
        deleteFilterId: action.payload.id,
        deleteFilterType: action.payload.type
      };
    case CLOSE_DELETE_FILTER_MODAL:
    case FILTER_DELETED_SUCCESSFULLY:
      return {
        ...state,
        deleteFilterModal: INITIAL_STATE.deleteFilterModal,
        deleteFilterType: INITIAL_STATE.deleteFilterType,
      };
    // Закрытие страницы фильтров
    case OPEN_EXIT_FILTER_WINDOW:
      return {
        ...state,
        exitFilterModal: {
          isOpen: true,
          action: action.payload.action,
          location: action.payload.location,
        },
      };
    case CLOSE_EXIT_FILTER_WINDOW:
    // case EXIT_FILTER_WINDOW_SUCCESS:
      return {
        ...state,
        exitFilterModal: INITIAL_STATE.exitFilterModal,
      };
    case OPEN_SIGN_IN_MODAL:
      return {
        ...state,
        signInModal: {
          isOpen: true,
        },
        signUpModal: {
          isOpen: false
        }
      };
    case CLOSE_SIGN_IN_MODAL:
      return {
        ...state,
        signInModal: {
          isOpen: false,
        },
      };
    case OPEN_SIGN_UP_MODAL:
      return {
        ...state,
        signInModal: {
          isOpen: false,
        },
        signUpModal: {
          isOpen: true
        }
      };
    case CLOSE_SIGN_UP_MODAL:
      return {
        ...state,
        signUpModal: {
          isOpen: false,
        },
      };
    case CLOSE_SIGN_UP_BLOCK:
      return {
        ...state,
        closeSignUpBlock: true
      };
    case OPEN_SUBSCRIBE_NOTIFICATION:
      return {
        ...state,
        subscribeNotification: {
          isOpen: JSON.parse(sessionStorage.getItem('subscribeNotification')) || true,
        },
      };
    case HIDE_SUBSCRIBE_NOTIFICATION:
      return {
        ...state,
        subscribeNotification: {
          isOpen: false,
        },
      };
    case SHOW_SERVER_ERROR:
      return {
        ...state,
        showServerError: {
          isOpen: true,
        },
      };
    case CLOSE_SERVER_ERROR:
      return {
        ...state,
        showServerError: {
          isOpen: false,
        },
      };
    case SUBSCRIPTION_REQUIRED:
      return {
        ...state,
        subscriptionError: {
          isOpen: true,
        },
      };
    case SUBSCRIPTION_REQUIRED_CLOSE:
      return {
        ...state,
        subscriptionError: {
          isOpen: false,
        },
      };
    case FORK_IS_GONE_ERROR:
      return {
        ...state,
        forkIsGone: {
          isOpen: true,
        },
      };
    case CLOSE_FORK_IS_GONE_ERROR:
      return {
        ...state,
        forkIsGone: {
          isOpen: false,
        },
      };
    case SHOW_ERROR:
      return {
        ...state,
        otherError: {
          isOpen: true,
          message: action.payload.message,
          params: action.payload.params,
        },
      };
    case CLOSE_SHOW_ERROR:
      return {
        ...state,
        otherError: {
          isOpen: false,
          message: ''
        },
      };
    case GET_LOGOUT:
      return {
        ...state,
        unAuthorised: true,
      };
    case SET_DROP_DOWN:
      return {
        ...state,
        setDropDown: action.payload.id === undefined || state.setDropDown === 'calc' ? '-1' : action.payload.id,
        setDropDownAbuse: action.payload.id === '-1' ? state.setDropDownAbuse : '-1',
      };
    case SET_DROP_DOWN_ABUSE:
      return {
        ...state,
        setDropDown: action.payload.id === '-1' ? state.setDropDown : '-1',
        setDropDownAbuse: action.payload.id === undefined || state.setDropDownAbuse === 'calc' ? '-1' : action.payload.id,
      };
    case OPEN_RESTORE_MODAL:
      return {
        ...state,
        openRestorePasswordModal: !state.openRestorePasswordModal,
        resetCode: action.payload.resetCode
      };
    case OPEN_RESET_MODAL:
      return {
        ...state,
        openResetModal: action.payload.onlyClose ? false : !state.openResetModal,
      };
    case SET_ABUSE_START:
      return {
        ...state,
      };
    case SET_ABUSE_SUCCESS:
      return {
        ...state,
      };
    case ENTER_MODAL:
      return {
        ...state,
        modal: action.payload.modal,
        button: 'enter'
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: action.payload.modal,
        button: 'close'
      };
    case CLEAN_MODAL:
      return {
        ...state,
        modal: '',
        button: ''
      };
    case OPEN_SHOW_WARN:
      return {
        ...state,
        showWarningEvents: true,
        showWarningText: action.payload
      };
    case CLOSE_SHOW_WARN:
      return {
        ...state,
        showWarningEvents: false,
        showWarningText: '',
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notification: INITIAL_STATE.notification,
      };
    default:
      return state;
  }
}
