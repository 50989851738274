import { valuebetsApi } from '../../api/request';
import { getEventsList, showServerErrors } from "./ui";
import { tokenError } from "./auth";
import { fetchForks } from './forksUpdating';

export const GET_VALUEBETS_START = "Start get valuebets forks";
export const GET_VALUEBETS_SUCCESS = "Get valuebets successfull";
export const GET_VALUEBETS_FAILURE = "Get valuebets failed";


//получение списка вилок
const getValuebetsStart = isSound => ({
  type: GET_VALUEBETS_START,
  payload: {
    isSound
  }
});

const getValuebetsSuccess = (forkslist = false, isSound, commonParams) => {
  return async function (dispatch, getState) {
    const newMas = getEventsList(forkslist.valuebets, getState().forks.valuebetsList, isSound);
    dispatch({
      type: GET_VALUEBETS_SUCCESS,
      payload: {
        forkslist: newMas,
        ...commonParams,
      }
    });
  };
};

const getValuebetsFailure = (error) => dispatch => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_valuebets"));
  dispatch({
    type: GET_VALUEBETS_FAILURE,
    payload: {
      error: "fail_valuebets"
    }
  });
};

//получить список коридоров
export const getValuebetsList = (isSound = false) => (dispatch) => {
  dispatch(fetchForks(
    { isSound },
    valuebetsApi.getlist,
    getValuebetsStart,
    getValuebetsSuccess,
    getValuebetsFailure,
  ));
};
