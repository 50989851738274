import { middlebetsApi } from '../../api/request';
import { getEventsList, showServerErrors } from "./ui";
import { tokenError } from "./auth";
import { fetchForks } from './forksUpdating';

export const GET_MIDDLEBETS_START = "Start get middlebest forks";
export const GET_MIDDLEBETS_SUCCESS = "Get middlebets successfull";
export const GET_MIDDLEBETS_FAILURE = "Get middlebets failed";

//получение списка вилок
const getMiddlebetsStart = isSound => ({
  type: GET_MIDDLEBETS_START,
  payload: {
    isSound
  }
});


const middlebetSort = (sortByMiddlebet) => {
  switch (sortByMiddlebet) {
    case 0: return 'expectedValue';
    case 1: return 'profit';
    case 2: return 'estimatedProbability';
    case 3: return 'width';
    default: return false;
  }
};

const getMiddlebetsSuccess = (forkslist = false, isSound, commonParams) => {
  return async function (dispatch, getState) {
    const sort = forkslist.bookersSettings ? forkslist.bookersSettings.middlebetsSortField : false;
    const newMas = getEventsList(forkslist.middlebets, getState().forks.middlebetsList, isSound, middlebetSort(sort));
    dispatch({
      type: GET_MIDDLEBETS_SUCCESS,
      payload: {
        forkslist: newMas,
        ...commonParams,
      }
    });
  };
};

const getMiddlebetsFailure = (error) => dispatch => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_middlebets"));
  return {
    type: GET_MIDDLEBETS_FAILURE,
    payload: {
      error: "fail_middlebets"
    }
  };
};

//получить список коридоров
export const getMiddlebetsList = (isSound = false) => async (dispatch) => {
  dispatch(fetchForks(
    { isSound },
    middlebetsApi.getlist,
    getMiddlebetsStart,
    getMiddlebetsSuccess,
    getMiddlebetsFailure,
  ));
};
