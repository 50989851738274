import { statisticProfileApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";

//Загрузка статистики (в профиле пользователя)
export const LOAD_STATISTIC_START = "Statistic loading start";
export const LOAD_STATISTIC_SUCCESS = "Statistic loaded successfully";
export const LOAD_STATISTIC_FAILED = "Statistic loading failed";

export const LOAD_DETAILS_START = "Details loading start";
export const LOAD_DETAILS_SUCCESS = "Details loaded successfully";
export const LOAD_DETAILS_FAILED = "Details loading failed";


export const LOAD_USERS_TABLE_START = "Users table loading start";
export const LOAD_USERS_TABLE_SUCCESS = "Users table loaded successfully";
export const LOAD_USERS_TABLE_FAIL = "Users table loading failed";

//ЗАГРУЗКА СТАТИСТИКИ
const startLoadStatistic = () => ({
  type: LOAD_STATISTIC_START
});

const successLoadStatistic = data => ({
  type: LOAD_STATISTIC_SUCCESS,
  payload: {
    statistic: data || {}
  }
});


const failLoadStatistic = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_statistic"));
  return {
    type: LOAD_STATISTIC_FAILED,
    payload: {
      error: "fail_load_statistic"
    }
  };
};


export const loadStatisticAsync = (type) => {
  return async (dispatch) => {
    dispatch(startLoadStatistic());
    let stat;
    try {
      stat = await statisticProfileApi.loadStatistic(type);
    } catch (err) {
      return dispatch(failLoadStatistic(err, dispatch));
    }
    return dispatch(successLoadStatistic(stat.data));
  };
};

//Получение детальной информации за определенный период
const startLoadDetailsInfo = () => ({
  type: LOAD_DETAILS_START
});

const successLoadDetails = (data = false, period) => ({
  type: LOAD_DETAILS_SUCCESS,
  payload: {
    details: data,
    period
  }
});


const failLoadDetails = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_details"));
  return {
    type: LOAD_DETAILS_FAILED,
    payload: {
      error: "fail_load_details"
    }
  };
};


export const loadDetailsInfoAsync = (type, period) => {
  return async (dispatch) => {
    dispatch(startLoadDetailsInfo());

    let details;
    try {
      if (Number(period) !== -1) {
        details = await statisticProfileApi.loadDetails(period, type);
      }
    } catch (err) {
      return dispatch(failLoadDetails(err, dispatch));
    }
    return dispatch(successLoadDetails(details ? details.data : false, period));
  };
};


//Получение списка привлечённых пользователей
const startUsersTableLoad = () => ({
  type: LOAD_USERS_TABLE_START
});

const successUsersTableLoad = users => ({
  type: LOAD_USERS_TABLE_SUCCESS,
  payload: {
    users
  }
});


const failUsersTableLoad = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_load_users_table"));
  return {
    type: LOAD_USERS_TABLE_FAIL,
    payload: {
      error: "fail_load_users_table"
    }
  };
};


export const loadUsersTableAsync = (type) => {
  return async (dispatch) => {
    dispatch(startUsersTableLoad());
    let users;
    try {
      users = await statisticProfileApi.loadUsersTable(type);
    } catch (err) {
      return dispatch(failUsersTableLoad(err, dispatch));
    }
    return dispatch(successUsersTableLoad(users.data));
  };
};
