import { ExtractRouteParams, generatePath } from 'react-router';
import qs from 'qs';

interface Path {
  [key: string]: string | Path,
}

interface ResultPath {
  [key: string]: ((params?: ExtractRouteParams<any>) => string) | ResultPath
}

type CreatePathOptionsType = {
  getDefaultParams: () => ({ [key: string]: any }),
  getDefaultQuery?: () => (({ [key: string]: any }) | undefined),
  prefix?: string,
};

const cratePaths = (
  structure: Path,
  options?: CreatePathOptionsType,
): any => {
  const {
    getDefaultParams,
    getDefaultQuery = () => null,
    prefix = '',
  } = options || {};
  const getParam = (p?: ExtractRouteParams<any>) => ({
    ...(getDefaultParams ? getDefaultParams() : {}),
    ...(p || {}),
  });


  const getPath = (pathTpl: string, params?: ExtractRouteParams<any>) => {
    const path = generatePath(pathTpl, getParam(params));
    const query = getDefaultQuery();
    return query ? `${path}?${qs.stringify(query, { encode: false })}` : path;
  };

  const paths: ResultPath = {
    index: (p?: null | ExtractRouteParams<any>) => (
      p === null
        ? `${prefix}`
        : getPath(`${prefix}`, p)
    ),
  };

  Object.keys(structure).forEach((key: string) => {
    const value = structure[key];

    if (typeof value === 'object') {
      paths[key] = cratePaths(
        structure[key] as Path,
        { ...options, prefix: `${prefix}/${value.index || key}` } as CreatePathOptionsType
      );
      return;
    }

    if (key !== 'index') {
      paths[key] = (p?: null | ExtractRouteParams<any>) => (
        p === null
          ? `${prefix}/${structure[key]}`
          : getPath(`${prefix}/${structure[key]}`, p));
    }
  });

  return paths;
};

export default cratePaths;
