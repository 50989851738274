import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import forks from './forks';
import ui from './ui';
import userProfile from './userProfile';
import currencyRates from './currencyRates';
import subscriptions from './subscriptions';
import filters from "./filters";
import calc from './calc';
import bookmakers from "./bookmakers";
import tariffs from "./tariffs";
import editFilter from "./editFilter";
import payment from "./payment";
import contacts from "./contacts";
import referal from "./referal";
import statistic from "./statistic";
import bookersLeagues from "./bookersLeaguesReducer";

export default history => combineReducers({
  router: connectRouter(history),
  auth,
  forks,
  ui,
  userProfile,
  currencyRates,
  subscriptions,
  filters,
  calc,
  bookmakers,
  tariffs,
  editFilter,
  payment,
  contacts,
  referal,
  statistic,
  bookersLeagues,
});