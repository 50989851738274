import { push } from 'react-router-redux';
import i18next from 'i18next';
import axios from 'axios';
import { userProfileApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";
import { fallbackLng } from '../../utils/i18n';

export const CHANGE_USER_LANGUAGE = "Change user language";

//Загрузка информации о пользователе (вкладка Профиль)
export const USER_INFO_LOADING_START = "User info loading start";
export const USER_INFO_LOADED_SUCCESSFULLY = "User info loaded successfully";
export const USER_INFO_LOADING_FAILED = "User info loading failed";

//Редактирование формы смены пароля
export const SET_OLD_PASS = "Set old password";
export const SET_NEW_PASS = "Set new password";
export const SET_NEW_PASS2 = "Set new password 2";

//Смена пароля
export const PASSWORD_CHANGE_START = "Password change start";
export const PASSWORD_CHANGE_SUCCESS = "Password changed successfully";
export const PASSWORD_CHANGE_FAILED = "Password change failed";

export const changeLang = (lang, replacePath = false) => (dispatch) => {
  const { pathname } = window.location;

  axios.defaults.headers.common['Accept-Language'] = lang;

  i18next.changeLanguage(lang);
  const regExp = new RegExp(`^\\/(${fallbackLng.join('|')})`, 'g');

  if (replacePath) {
    dispatch(push(pathname.replace(regExp, `/${lang}`)));
  }

  dispatch({
    type: CHANGE_USER_LANGUAGE,
    payload: {
      lang,
    },
  });
};

//ЗАГРУЗКА ИНФОРМАЦИИ О ПОЛЬЗОВАТЕЛЕ
const startLoading = () => ({
  type: USER_INFO_LOADING_START
});

const loadSuccess = user => ({
  type: USER_INFO_LOADED_SUCCESSFULLY,
  payload: {
    user: user || {}
  }
});

const loadFailure = (error, dispatch) => {
  window.location.href = '/';
  tokenError(error, dispatch);
  dispatch(showServerErrors(error.response ? error.response.status : "fail_profile"));
  return {
    type: USER_INFO_LOADING_FAILED,
    payload: {
      error: "fail_profile"
    }
  };
};

export const loadUserInfoAsync = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let user;
    try {
      user = await userProfileApi.loadUserInfoRequest();
    } catch (err) {
      dispatch(loadFailure(err, dispatch));
    }
    return dispatch(loadSuccess(user.data));
  };
};

//СМЕНА ПАРОЛЯ

//Редактирование формы смены пароля
export const setOldPass = value => ({
  type: SET_OLD_PASS,
  payload: {
    value
  }
});

export const setNewPass = value => ({
  type: SET_NEW_PASS,
  payload: {
    value
  }
});

export const setNewPass2 = value => ({
  type: SET_NEW_PASS2,
  payload: {
    value
  }
});

//Смена пароля
const passwordChangeStart = () => ({
  type: PASSWORD_CHANGE_START,
});

const passwordChangeSuccess = () => ({
  type: PASSWORD_CHANGE_SUCCESS,
});

const passwordChangeFailded = (error, dispatch) => {
  tokenError(error, dispatch);
  const text = error.response.data.error === 'wrong_current_password' ? 'wrong_current_password' : 'fail_password';
  dispatch(showServerErrors(error, text));
  return {
    type: PASSWORD_CHANGE_FAILED,
    payload: {
      error: "fail_password"
    }
  };
};
export const changePasswordAsync = (params) => {
  return async (dispatch) => {
    dispatch(passwordChangeStart());
    try {
      await userProfileApi.changePasswordRequest(params);
    } catch (err) {
      return dispatch(passwordChangeFailded(err, dispatch));
    }
    return dispatch(passwordChangeSuccess());
  };
};
