import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUi } from '../../../redux/selectors/ui';
import {
  closeNotification,
} from '../../../redux/actions/ui';
import Alert from '../../ui/Alert/Alert';
import useBindActionCreators from '../../../hooks/utils/useBindActionCreators';

const DEFAULT_TIMEOUT = 3000;

const Notification = () => {
  const {
    notification: {
      message,
      type,
    },
  } = useSelector(selectUi);

  const {
    closeNotification: closeNotificationFn,
  } = useBindActionCreators({
    closeNotification,
  });

  const [show, setShow] = useState(false);

  useEffect(
    () => {
      if (message) {
        setShow(true);
      }
    },
    [message],
  );

  useEffect(
    () => {
      if (!show) {
        return;
      }

      setTimeout(
        () => {
          closeNotificationFn();
          setShow(false);
        },
        DEFAULT_TIMEOUT,
      );
    },
    [show],
  );

  return (
    <Alert
      isVisible={show}
      status={type}
      onClose={() => setShow(false)}
    >
      {message}
    </Alert>
  );
};

export default Notification;
