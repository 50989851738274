import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import paths, { basePaths } from '../paths';

import useBindActionCreators from './utils/useBindActionCreators';
import {
  openResetModal,
  openRestorePasswordModal,
  openSignUpModal,
} from '../redux/actions/ui';
import useQuery from './utils/useQuery';


const entarances = [
  {
    path: basePaths.recovery(null),
    create: () => {
      const {
        openResetModal: openResetModalFunc,
      } = useBindActionCreators({
        openResetModal,
      });

      return () => {
        setTimeout(() => openResetModalFunc(), 100);
      };
    },
  },

  {
    path: basePaths.restore(),
    create: () => {
      const query = useQuery();
      const history = useHistory();
      const {
        openRestorePasswordModal: openRestorePasswordModalFunc,
      } = useBindActionCreators({
        openRestorePasswordModal
      });
      return () => {
        const { resetCode } = query || {};
        history.push(paths.index());
        setTimeout(() => openRestorePasswordModalFunc(resetCode), 100);
      };
    }
  },

  {
    path: basePaths.signUp(),
    create: () => {
      const history = useHistory();
      const {
        openSignUpModal: openSignUpModalFunc,
      } = useBindActionCreators({
        openSignUpModal
      });

      return () => {
        history.push(paths.index());
        setTimeout(() => openSignUpModalFunc(), 100);
      };
    }
  }
];


const useEntrance = () => {
  const { pathname } = useLocation();

  const effects = entarances
    .map<([() => any, (path: string) => boolean])>(({ path, create }) => {
      return [create(), (p: string) => p.indexOf(path) !== -1];
    });


  useEffect(
    () => {
      effects.forEach(
        ([effect, needRun]) => {
          if (needRun(pathname)) {
            effect();
          }
        }
      );
    },
    [pathname],
  );
};

export default useEntrance;
