import axios from 'axios';

const CancelToken = axios.CancelToken;

const interruptTokens = {};

export const addInterruptToken = (name) => {
  const token = CancelToken.source();
  interruptTokens[name] = token;
  return token;
};

export const interruptRequest = (name, reason = 'Operation canceled by the user.') => {
  const token = interruptTokens[name];

  if (!token) {
    return;
  }

  token.cancel(reason);
};

export const isInterrupt = (error) => {
  return axios.isCancel(error);
};