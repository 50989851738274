import { subscriptionsApi } from '../../api/request';
import { showError, showServerErrors } from './ui';
import { tokenError, authCheck } from "./auth";

//Загрузка подписок (в профиле пользователя)
export const SUBSCRIPTIONS_LOADING_START = "Subscriptions loading start";
export const SUBSCRIPTIONS_LOADED_SUCCESSFULLY = "Subscriptions loaded successfully";
export const SUBSCRIPTIONS_LOADING_FAILED = "Subscriptions loading failed";

//Приостановка подписки
export const FREEZE_SUBSCRIPTION_START = "Freeze subscription start";
export const FREEZE_SUBSCRIPTION_SUCCESS = "Freeze subscription successfully";
export const FREEZE_SUBSCRIPTION_FAILED = "Freeze subscription failed";

//Снятие подписки с паузы
export const UNFREEZE_SUBSCRIPTION_START = "Unfreeze subscription start";
export const UNFREEZE_SUBSCRIPTION_SUCCESS = "Unfreeze subscription successfully";
export const UNFREEZE_SUBSCRIPTION_FAILED = "Unfreeze subscription failed";

//Преобразование подписки в подписку + (получение информации)
export const GET_SUBSCRIPTION_TRANSFORM_START = "Get subscription transform start";
export const GET_SUBSCRIPTION_TRANSFORM_SUCCESS = "Get subscription transform successfully";
export const GET_SUBSCRIPTION_TRANSFORM_FAILED = "Get subscription transform failed";

//Преобразование подписки в подписку +
export const SET_SUBSCRIPTION_TRANSFORM_START = "Set subscription transform start";
export const SET_SUBSCRIPTION_TRANSFORM_SUCCESS = "Set subscription transform successfully";
export const SET_SUBSCRIPTION_TRANSFORM_FAILED = "Set subscription transform failed";

//ЗАГРУЗКА ПОДПИСОК
const startLoading = () => ({
  type: SUBSCRIPTIONS_LOADING_START
});

const loadSuccess = subscriptions => ({
  type: SUBSCRIPTIONS_LOADED_SUCCESSFULLY,
  payload: {
    subscriptions: subscriptions || []
  }
});

const loadFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_subscriptions"));
  return {
    type: SUBSCRIPTIONS_LOADING_FAILED,
    payload: {
      error: "fail_subscriptions"
    }
  };
};

export const loadSubscriptionsAsync = type => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      subscriptionsApi.getProfileSubscriptions(type)
        .then(subscriptions => dispatch(loadSuccess(subscriptions.data.subscriptions)));
    } catch (err) {
      return dispatch(loadFailure(err, dispatch));
    }
  };
};

//ПРИОСТАНОВКА ПОДПИСКИ
const freezeSubscriptionStart = () => ({
  type: FREEZE_SUBSCRIPTION_START
});

const freezeSubscriptionSuccess = () => {
  return async (dispatch) => {
    dispatch({ type: FREEZE_SUBSCRIPTION_SUCCESS });
  };
};

const freezeSubscriptionFailure = (error, dispatch) => {
  tokenError(error, dispatch);

  dispatch(showServerErrors(error, "error_set_freeze"));
  return {
    type: FREEZE_SUBSCRIPTION_FAILED,
    payload: {
      error: "error_set_freeze"
    }
  };
};

export const freezeSubscriptionAsync = type => {
  return async (dispatch) => {
    dispatch(freezeSubscriptionStart());
    try {
      await subscriptionsApi.setSubscriptionFreeze(type);
      dispatch(authCheck());
      dispatch(freezeSubscriptionSuccess());
      dispatch(loadSubscriptionsAsync(type));
    } catch (err) {
      return dispatch(freezeSubscriptionFailure(err, dispatch));
    }
  };
};

//СНЯТИЕ ПОДПИСКИ С ПАУЗЫ
const unFreezeSubscriptionStart = () => ({
  type: UNFREEZE_SUBSCRIPTION_START
});

const unFreezeSubscriptionSuccess = () => {
  return async (dispatch) => {
    dispatch({ type: UNFREEZE_SUBSCRIPTION_SUCCESS });
  };
};


const unFreezeSubscriptionFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_unfreeze"));
  return {
    type: UNFREEZE_SUBSCRIPTION_FAILED,
    payload: {
      error: "fail_unfreeze"
    }
  };
};


export const unFreezeSubscriptionAsync = type => {
  return async (dispatch) => {
    dispatch(unFreezeSubscriptionStart());
    try {
      await subscriptionsApi.setSubscriptionUnFreeze(type);
    } catch (err) {
      return dispatch(unFreezeSubscriptionFailure(err, dispatch));
    }
    return dispatch(unFreezeSubscriptionSuccess())
      .then(() => dispatch(authCheck())) // необходимо для обновления информации о подписке в профиле пользователя
      .then(() => dispatch(loadSubscriptionsAsync(type)));
  };
};

//ПРЕОБРАЗОВАНИЕ ПОДПИСКИ В ПОДПИСКУ + ( получение инфо о подписке)
const getSubscriptionTransformStart = () => ({
  type: GET_SUBSCRIPTION_TRANSFORM_START
});

const getSubscriptionTransformSuccess = (extendedSubscriptionValidUntil, subscriptionValidUntil) => ({
  type: GET_SUBSCRIPTION_TRANSFORM_SUCCESS,
  payload: {
    extendedSubscriptionValidUntil,
    subscriptionValidUntil
  }
});

const getSubscriptionTransformFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  if (error.response.status === 409) {
    dispatch(showError(error.response.data.message));
  } else {
    dispatch(showServerErrors(error, "fail_transform_get"));
  }

  return {
    type: GET_SUBSCRIPTION_TRANSFORM_FAILED,
    payload: {
      error: "fail_transform_get"
    }
  };
};


export const getSubscriptionTransformAsync = () => {
  return async (dispatch) => {
    dispatch(getSubscriptionTransformStart());
    let transformInfo;
    try {
      transformInfo = await subscriptionsApi.getSubscriptionTransform();
    } catch (err) {
      return dispatch(getSubscriptionTransformFailure(err, dispatch));
    }
    if (transformInfo.data) {
      return dispatch(getSubscriptionTransformSuccess(transformInfo.data.extendedSubscriptionValidUntil, transformInfo.data.subscriptionValidUntil));
    }
  };
};

//ПРЕОБРАЗОВАНИЕ ПОДПИСКИ В ПОДПИСКУ +
const setSubscriptionTransformStart = () => ({
  type: SET_SUBSCRIPTION_TRANSFORM_START
});

const setSubscriptionTransformSuccess = () => ({
  type: SET_SUBSCRIPTION_TRANSFORM_SUCCESS
});

const setSubscriptionTransformFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_transform"));
  return {
    type: SET_SUBSCRIPTION_TRANSFORM_FAILED,
    payload: {
      error: "fail_transform"
    }
  };
};

export const setSubscriptionTransformAsync = () => {
  return async (dispatch) => {
    dispatch(setSubscriptionTransformStart());
    try {
      await subscriptionsApi.setSubscriptionTransform();
    } catch (err) {
      return dispatch(setSubscriptionTransformFailure(err, dispatch));
    }
    dispatch(setSubscriptionTransformSuccess());
    dispatch(authCheck());
  };
};
