export default {
  translation: {
    ru: () => import('./translation.ru.json'),
    en: () => import('./translation.en.json'),
    kk: () => import('./translation.kk.json'),
    uk: () => import('./translation.uk.json'),
  },
  questions: {
    ru: () => import('./questions.ru.json'),
    en: () => import('./questions.en.json'),
    kk: () => import('./questions.kk.json'),
    uk: () => import('./questions.uk.json'),
  },
  referal: {
    ru: () => import('./referal.ru.json'),
    en: () => import('./referal.en.json'),
    kk: () => import('./referal.kk.json'),
    uk: () => import('./referal.uk.json'),
  },
  statistic: {
    ru: () => import('./statistic.ru.json'),
    en: () => import('./statistic.en.json'),
    kk: () => import('./statistic.kk.json'),
    uk: () => import('./statistic.uk.json'),
  },
  contacts: {
    ru: () => import('./contacts.ru.json'),
    en: () => import('./contacts.en.json'),
    kk: () => import('./contacts.kk.json'),
    uk: () => import('./contacts.uk.json'),
  },
};
