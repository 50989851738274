import { Dispatch } from 'redux';
import { bookersApi } from '../../api/request';
import { BookerLeagueType } from '../types/bookersLeaguesTypes';

//Загрузка фильтров (в профиле пользователя)
export const BOOKERS_LEAGUES_LOADING_START = "BOOKERS_LEAGUES_LOADING_START";
export const BOOKERS_LEAGUES_SUCCESSFULLY = "BOOKERS_LEAGUES_SUCCESSFULLY";
export const BOOKERS_LEAGUES_FAILED = "BOOKERS_LEAGUES_FAILED";
export const BOOKERS_LEAGUES_CLEAR = "BOOKERS_LEAGUES_CLEAR";


export type StartBookersLeaguesActionType = {
  type: typeof BOOKERS_LEAGUES_LOADING_START,
};

export type FailedBookersLeaguesActionType = {
  type: typeof BOOKERS_LEAGUES_FAILED,
};

export type SuccessBookersLeaguesActionType = {
  type: typeof BOOKERS_LEAGUES_SUCCESSFULLY,
  payload: BookerLeagueType[]
};

export type ClearBookersLeaguesActionType = {
  type: typeof BOOKERS_LEAGUES_CLEAR,
};

export const startBookersLeagues = (): StartBookersLeaguesActionType => ({
  type: BOOKERS_LEAGUES_LOADING_START,
});

export const failedBookersLeagues = (): FailedBookersLeaguesActionType => ({
  type: BOOKERS_LEAGUES_FAILED,
});


export const successBookersLeagues = (payload: BookerLeagueType[]): SuccessBookersLeaguesActionType => ({
  type: BOOKERS_LEAGUES_SUCCESSFULLY,
  payload,
});

export const clearBookersLeagues = (): ClearBookersLeaguesActionType => ({
  type: BOOKERS_LEAGUES_CLEAR,
});


export const loadBookersLeaguesAsync = () => async (dispatch: Dispatch) => {
  dispatch(startBookersLeagues());
  try {
    const response = await bookersApi.getLeagues() as any;
    const leagues: BookerLeagueType[] = response.data;

    dispatch(successBookersLeagues(leagues));
  } catch (e) {
    console.error(e);
    dispatch(failedBookersLeagues());
  }
};
