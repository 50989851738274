import { liveForksApi } from '../../api/request';
import { getEventsList, showResponseMessage, showServerErrors } from './ui';
import { tokenError } from './auth';
import { fetchForks } from './forksUpdating';

export const GET_LIVE_FORKS_START = "Start get live forks";
export const GET_LIVE_FORKS_SUCCESS = "Get live forks success";
export const GET_LIVE_FORKS_FAILURE = "Get live forks failed";

//получение списка вилок
const getLiveForksStart = (isSound) => ({
  type: GET_LIVE_FORKS_START,
  payload: {
    isSound
  }
});

const getLiveForksSuccess = (forkslist = false, isSound, commonParams) => {
  return async function (dispatch, getState) {
    const newMas = getEventsList(forkslist.surebets, getState().forks.forkslist, isSound);
    dispatch({
      type: GET_LIVE_FORKS_SUCCESS,
      payload: {
        forkslist: newMas,
        ...commonParams,
      },
    });
  };
};

const getLiveForksFailure = (error) => (dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_forks"));
  dispatch(showResponseMessage(error));
  return {
    type: GET_LIVE_FORKS_FAILURE,
    payload: {
      error: "fail_forks"
    }
  };
};

//получить список вилок
export const getListLiveForks = (isSound = false) => (dispatch) => {
  dispatch(fetchForks(
    { isSound },
    liveForksApi.getlist,
    getLiveForksStart,
    getLiveForksSuccess,
    getLiveForksFailure
  ));
};
