import {
  LOAD_FILES_START,
  LOAD_FILES_FAILURE,
  LOAD_FILES_SUCCESS,
  SEND_FEEDBACK_SUCCESS,
  DELETE_FILE,
} from "../actions/contacts";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  files: [],
  filesId: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_FILES_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case LOAD_FILES_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        files: action.payload.files,
        filesId: action.payload.filesId
      };
    case LOAD_FILES_FAILURE:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    case DELETE_FILE:
      return {
        ...state,
        files: action.payload.files,
        filesId: action.payload.filesId
      };
    case SEND_FEEDBACK_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}