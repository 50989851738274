import filter from "lodash/filter";
import { liveForksApi, hideEventsApi, getForksApi } from '../../api/request';
import { getMiddlebetsList } from './middlebets';
import { getListLiveForks } from './liveforks';
import { getValuebetsList } from './valuebets';
import { getEventsList, showServerErrors } from "./ui";
import { tokenError } from "./auth";
import { fetchForks } from './forksUpdating';
import { selectForksEventType, selectGroupByType, selectViewGroups } from '../selectors/forks';


//Скрывать данное событие для переданных БК (или для всех БК)
export const DO_NOT_SHOW_EVENT_START = "Do not show event for BC data start";
export const DO_NOT_SHOW_EVENT_SUCCESS = "Do not show event for BC data success";
export const DO_NOT_SHOW_EVENT_FAILURE = "Do not show event for BC data failed";

//Сформировать группы
export const SET_GROUPS_START = "Set groups start";
export const SET_GROUPS_SUCCESS = "Set groups success";
export const SET_GROUPS_FAILURE = "Set groups failed";
export const TOGGLE_VIEW_GROUPS = "Toggle view groups";

//Получить ссылку
export const GET_BET_URL_START = "Get bet url start";
export const GET_BET_URL_SUCCESS = "Get bet url success";
export const GET_BET_URL_FAILURE = "Get bet url failed";

//Показать скрытые вилки (плечи)
export const SHOW_HIDE_EVENTS_START = "Show hide events start";
export const SHOW_HIDE_EVENTS_SUCCESS = "Show hide events success";
export const SHOW_HIDE_EVENTS_FAILURE = "Show hide events failed";

//Получение скрытых вилок/событий
export const GET_HIDDEN_EVENTS_START = "Get hidden events start";
export const GET_HIDDEN_EVENTS_SUCCESS = "Get hidden events success";
export const GET_HIDDEN_EVENTS_FAILURE = "Get hidden events failed";

export const SET_FORKS_TYPE = "Set forks type";
export const SET_PAGINATION = "Set pagination";
export const SET_SORT = "Set sort";
export const OPEN_GROUP_ITEM = "Open group item";

export const CHANGE_EVENT_NAME = "Change event name";

//скрыть события
export function doNotShowEvent(params) {
  return async function (dispatch) {
    dispatch(doNotShowEventStart());
    hideEventsApi.hide(params)
      .then(() => {
        dispatch(doNotShowEventSuccess(params));
        dispatch(getHidden());
      })
      .catch((err) => {
        dispatch(doNotShowEventFailure(err, dispatch));
      });
  };
}

//сформировать группы
export function setGroups(eventType, isSound) {
  return async function (dispatch) {
    dispatch(setGroupsStart(isSound));
    // try {
    //   if (eventType === 'surebet') groupData = await liveForksApi.getlist();
    //   if (eventType === 'middlebet') groupData = await middlebetsApi.getlist();
    //   if (eventType === 'valuebet') groupData = await valuebetsApi.getlist();
    // } catch (err) {
    //   dispatch(setForksUpdating(false));
    //   return dispatch(setGroupsFailure(err, dispatch));
    // }
    // dispatch(setForksUpdating(false));
    // dispatch(setGroupsSuccess(groupData.data, eventType, isSound));
  };
}

// показать скрытые вилки
export function showHideEvents(params) {
  return async function (dispatch) {
    dispatch(showHideEventsStart());
    hideEventsApi.show(params)
      .then(() => {
        dispatch(showHideEventSuccess());
        params.eventType === "surebets" && dispatch(getListLiveForks());
        params.eventType === "middlebet" && dispatch(getMiddlebetsList());
        params.eventType === "valuebet" && dispatch(getValuebetsList());
        dispatch(getHidden());
      })
      .catch((error) => {
        dispatch(showHideEventsFailure(error, dispatch));
      });
    dispatch(showHideEventSuccess(params));
  };
}

//получение групп

const setGroupsStart = (isSound) => ({
  type: SET_GROUPS_START,
  payload: {
    isSound
  }
});

const switchListDataByType = (list, eventType) => {
  switch (eventType) {
    case 'surebet':
      return list.surebets;
    case 'middlebet':
      return list.middlebets;
    case 'valuebet':
      return list.valuebets;
    default:
      return [];
  }
};


const setGroupsSuccess = (list, eventTypeGroups, isSound, commonParams = {}) => (dispatch, getState) => {
  const oldGroupList = selectGroupByType(getState(), eventTypeGroups);
  const oldList = [].concat(...oldGroupList.map(({ list: groupList }) => groupList));
  const newList = switchListDataByType(list, eventTypeGroups);
  const newMas = getEventsList(newList, oldList, isSound);

  dispatch({
    type: SET_GROUPS_SUCCESS,
    payload: {
      list: newMas,
      eventTypeGroups,
      ...commonParams,
    }
  });
};

const setGroupsFailure = (error) => dispatch => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_groups"));
  dispatch({
    type: SET_GROUPS_FAILURE,
    payload: {
      error: "fail_groups"
    }
  });
};

//скрытие события

const doNotShowEventSuccess = (params) => {
  return async function (dispatch, getState) {
    const type = getState().forks.eventType;
    const list = type === 'surebet' ? getState().forks.forkslist : type === 'middlebet' ? getState().forks.middlebetsList : getState().forks.valuebetsList;
    let newForkList;
    const paramName = Object.keys(params)[1];
    switch (paramName) {
      case 'eventsLinkId':
        newForkList = filter(list, item => item.eventsLinkId !== params[paramName]);
        break;
      case 'rowId':
        newForkList = filter(list, item => item.id !== params[paramName]);
        break;
      case 'leftShoulderEventId':
        newForkList = type !== 'valuebet'
          ? filter(list, item => item.leftShoulder.eventId !== params[paramName])
          : filter(list, item => item.bet.eventId !== params[paramName]);
        break;
      case 'rightShoulderEventId':
        newForkList = filter(list, item => item.rightShoulder.eventId !== params[paramName]);
        break;
      case 'leftShoulderBetId':
        newForkList = type !== 'valuebet'
          ? filter(list, item => item.leftShoulder.betId !== params[paramName])
          : filter(list, item => item.bet.betId !== params[paramName]);
        break;
      case 'rightShoulderBetId':
        newForkList = filter(list, item => item.rightShoulder.betId !== params[paramName]);
        break;
      default:
        return '';
    }
    dispatch({
      type: DO_NOT_SHOW_EVENT_SUCCESS,
      payload: {
        params,
        list,
        newList: newForkList,
        eventType: getState().forks.eventType
      } });
  };
};


const doNotShowEventStart = () => {
  return {
    type: DO_NOT_SHOW_EVENT_START,
  };
};


const doNotShowEventFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_hide"));
  return {
    type: DO_NOT_SHOW_EVENT_FAILURE,
    payload: {
      error: "fail_hide"
    }
  };
};

//показать скрытые

const showHideEventsStart = () => {
  return {
    type: SHOW_HIDE_EVENTS_START,
  };
};

const showHideEventSuccess = () => ({
  type: SHOW_HIDE_EVENTS_SUCCESS,
});


const showHideEventsFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_hide_show"));
  return {
    type: SHOW_HIDE_EVENTS_FAILURE,
    payload: {
      error: "fail_hide_show"
    }
  };
};

//Перейти по ссылке
export function goToUrl(e, fork, num, isAuth, hideLinks, isCopy = false, isBooker = false) {
  return async function (dispatch, getState) {
    e.stopPropagation();

    if (isAuth && hideLinks && (e.button === 0 || e.button === 1) && getState().ui.setDropDownAbuse == -1 && getState().ui.setDropDown == -1) {
      const forkBet = num === 1 ? fork.leftShoulder : fork.rightShoulder;
      dispatch(getUrl({
        eventsLinkId: fork.eventsLinkId,
        bookerId: forkBet ? forkBet.bookerId : fork.bet.bookerId,
        eventId: forkBet ? forkBet.eventId : fork.bet.eventId
      }, isCopy, isBooker));
    }
  };
}

//Получить ссылку
export function getUrl(params, isCopy, isBooker) {
  return async function (dispatch) {
    dispatch(getBetUrlStart());
    liveForksApi.getUrl(params)
      .then((res) => {
        if (res.data.url !== '') {
          const bookerUrl = res.data.url.split('/')[0] + '//' + res.data.url.split('/')[2];

          !isBooker
            ? isCopy
              ? navigator.clipboard.writeText(isCopy)
              : window.open(res.data.url, 'hide_referrer')
            : window.open(bookerUrl, 'hide_referrer');

          dispatch(getBetUrlSuccess(res.data.url));
        } else dispatch(getBetUrlFailure(res, dispatch));
      })
      .catch((error) => {
        dispatch(getBetUrlFailure(error, dispatch));
      });
  };
}

//получение ссылки для плеча
const getBetUrlStart = () => ({
  type: GET_BET_URL_START,
});

const getBetUrlSuccess = (url) => {
  return {
    type: GET_BET_URL_SUCCESS,
    payload: {
      url
    }
  };
};


const getBetUrlFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_get_url"));
  return {
    type: GET_BET_URL_FAILURE,
    payload: {
      error: "fail_get_url"
    }
  };
};


export const openGroup = (groupId) => ({
  type: OPEN_GROUP_ITEM,
  payload: {
    groupId,
  }
});


//Получить количество скрытых вилок/событий
export function getHidden() {
  return async function (dispatch) {
    dispatch(getBetUrlStart());
    hideEventsApi.getHidden()
      .then((res) => {
        dispatch(getHiddenSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getHiddenFailure(error, dispatch));
      });
  };
}

const getHiddenStart = () => ({
  type: GET_HIDDEN_EVENTS_START,
});


const getHiddenSuccess = (hiddenList) => {
  return {
    type: GET_HIDDEN_EVENTS_SUCCESS,
    payload: {
      hiddenList
    }
  };
};

const getHiddenFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_get_hide"));
  return {
    type: GET_HIDDEN_EVENTS_FAILURE,
    payload: {
      error: "fail_get_hide"
    }
  };
};

//Изменить количество отображаемых элементов
export function paginationChange(paginationNum) {
  return async function (dispatch) {
    dispatch(setPagination(paginationNum));
  };
}

const setPagination = (paginationNum) => {
  return {
    type: SET_PAGINATION,
    payload: {
      paginationNum
    }
  };
};

//Изменить сортировку коридоров
export function sortChange(sort) {
  return async function (dispatch) {
    dispatch(setSort(sort));
  };
}

export const toggleViewGroups = (viewGroups) => (dispatch) => {
  dispatch({
    type: TOGGLE_VIEW_GROUPS,
    payload: {
      viewGroups,
    },
  });
};

const setSort = (sort) => {
  return {
    type: SET_SORT,
    payload: {
      sort
    }
  };
};

export const updateForks = (isSound = false) => async (dispatch, getState) => {
  const state = getState();
  const eventType = selectForksEventType(state);
  const viewGroups = selectViewGroups(state);
  if (viewGroups) {
    dispatch(fetchForks(
      { isSound },
      getForksApi[eventType],
      setGroupsStart,
      (result, nextSound, commonParams) => () => dispatch(
        setGroupsSuccess(result, eventType, isSound, commonParams)
      ),
      setGroupsFailure,
    ));

    // dispatch(setGroupsSuccess(groupData.data, eventType, true));
  } else {
    switch (eventType) {
      case 'surebet': return dispatch(getListLiveForks(isSound));
      case 'middlebet': return dispatch(getMiddlebetsList(isSound));
      case 'valuebet': return dispatch(getValuebetsList(isSound));
      default: console.error('No eventType');
    }
  }
};

export const mainFetch = (eventType) => (dispatch) => {
  dispatch({
    type: SET_FORKS_TYPE,
    payload: { eventType },
  });

  dispatch(updateForks(false));
};

export const changeEventName = (eventName) => ({
  type: CHANGE_EVENT_NAME,
  payload: { eventName },
});