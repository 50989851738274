import React from "react";
import i18n from 'i18next';

export const soundsList = (playClick) => {
  return [
    {
      key: "1",
      text: i18n.t('filters.sound_1'),
      value: "sound_1",
      content: (<div>{ i18n.t('filters.sound_1')}<div className="play" id="sound_1" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "2",
      text: i18n.t('filters.sound_2'),
      value: "sound_2",
      content: (<div>{ i18n.t('filters.sound_2')}<div className="play" id="sound_2" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "3",
      text: i18n.t('filters.sound_3'),
      value: "sound_3",
      content: (<div>{ i18n.t('filters.sound_3')}<div className="play" id="sound_3" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "4",
      text: i18n.t('filters.sound_4'),
      value: "sound_4",
      content: (<div>{ i18n.t('filters.sound_4')}<div className="play" id="sound_4" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "5",
      text: i18n.t('filters.sound_5'),
      value: "sound_5",
      content: (<div>{ i18n.t('filters.sound_5')}<div className="play" id="sound_5" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "6",
      text: i18n.t('filters.sound_6'),
      value: "sound_6",
      content: (<div>{ i18n.t('filters.sound_6')}<div className="play" id="sound_6" onClick={(e) => playClick(e)} /></div>)

    },
    {
      key: "7",
      text: i18n.t('filters.sound_7'),
      value: "sound_7",
      content: (<div>{ i18n.t('filters.sound_7')}<div className="play" id="sound_7" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "8",
      text: i18n.t('filters.sound_8'),
      value: "sound_8",
      content: (<div>{ i18n.t('filters.sound_8')}<div className="play" id="sound_8" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "9",
      text: i18n.t('filters.sound_9'),
      value: "sound_9",
      content: (<div>{ i18n.t('filters.sound_9')}<div className="play" id="sound_9" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "10",
      text: i18n.t('filters.sound_10'),
      value: "sound_10",
      content: (<div>{ i18n.t('filters.sound_10')}<div className="play" id="sound_10" onClick={(e) => playClick(e)} /></div>)
    },
    {
      key: "11",
      text: i18n.t('filters.no_sound'),
      value: "no_sound",
      content: (<div>{ i18n.t('filters.no_sound')}<div id="no_sound" /></div>)
    }
  ];
};


export default soundsList;