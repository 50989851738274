import { tariffsApi } from '../../api/request';
import { showServerErrors } from "./ui";
import { tokenError } from "./auth";
import paths from '../../paths';

//Загрузка списка тарифов
export const TARIFFS_LIST_LOADING_START = "Tariffs list loading start";
export const TARIFFS_LIST_LOADED_SUCCESSFULLY = "Tariffs list loaded successfully";
export const TARIFFS_LIST_LOADING_FAILED = "Tariffs list loading failed";

//Переключить на Подписку или Подписку+
export const TOGGLE_TARIFFS_PLUS = "Toggle tariffs to Subscribe plus";

//Выбрать тарифный план
export const SELECT_TARIFFS_PLAN = "Select tariffs plan";

//Выбрать оператора для оплаты
export const CHANGE_PAYMENT_OPERATOR = "Select payment operator";

//ЗАГРУЗКА СПИСКА ТАРИФОВ
const startLoading = () => ({
  type: TARIFFS_LIST_LOADING_START
});

const loadSuccess = tariffs => ({
  type: TARIFFS_LIST_LOADED_SUCCESSFULLY,
  payload: {
    tariffs: tariffs || []
  }
});

const loadFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, "fail_tariffs"));
  return {
    type: TARIFFS_LIST_LOADING_FAILED,
    payload: {
      error: "fail_profile"
    }
  };
};

export const loadTariffsListAsync = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let tariffs;
    try {
      tariffs = await tariffsApi.getTariffs();
    } catch (err) {
      return dispatch(loadFailure(err, dispatch));
    }
    return dispatch(loadSuccess(tariffs.data.subscriptions));
  };
};

//Переключить на Подписку или Подписку+
export const toggleTariffsPlus = (isTransition = false, plus) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_TARIFFS_PLUS,
      payload: {
        isTransition,
        plus
      }
    });
  };
};

const selectTariffLink = (tariff) => {
  switch (tariff.code) {
    case 'Subscription1day':
      return paths.payment.plan({ plan: '1-day' });
    case 'Subscription1week':
      return paths.payment.plan({ plan: '1-week' });
    case 'Subscription1month':
      return paths.payment.plan({ plan: '1-month' });
    case 'Subscription1quarter':
      return paths.payment.plan({ plan: '1-quarter' });
    case 'SubscriptionExt1day':
      return paths.payment.plan({ plan: '1-day-plus' });
    case 'SubscriptionExt1week':
      return paths.payment.plan({ plan: '1-week-plus' });
    case 'SubscriptionExt1month':
      return paths.payment.plan({ plan: '1-month-plus' });
    case 'SubscriptionExt1quarter':
      return paths.payment.plan({ plan: '1-quarter-plus' });
    default:
      return paths.payment.plan({ plan: '1-day' });
  }
};

//Перейти к оплате тарифа
export const selectTariffPlanToPayment = (tariff, isLink = true) => {
  return async (dispatch) => {
    if (isLink) location.href = selectTariffLink(tariff);
    return dispatch({
      type: SELECT_TARIFFS_PLAN,
      payload: {
        tariff
      }
    });
  };
};
export const selectTariffPlan = (tariff) => {
  return async (dispatch) => {
    dispatch(selectTariffPlanToPayment(tariff)).then();
  };
};

export const changePaymentOperatorAsync = type => {
  return async (dispatch) => {
    return dispatch({
      type: CHANGE_PAYMENT_OPERATOR,
      payload: {
        paymentType: type
      }
    });
  };
};
