import {
  ENTER_PARTNER_START,
  ENTER_PARTNER_SUCCESS,
  ENTER_PARTNER_FAILURE
} from "../actions/referal";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  referal: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ENTER_PARTNER_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
      };
    case ENTER_PARTNER_SUCCESS:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        referal: action.payload.referal
      };
    case ENTER_PARTNER_FAILURE:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}
