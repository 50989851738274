import update from "immutability-helper";
import {
  CURRENCY_RATES_LOADING_START,
  CURRENCY_RATES_LOADED_SUCCESSFULLY,
  CURRENCY_RATES_LOADING_FAILED,
  CURRENCY_RATES_EDITING_START,
  CURRENCY_RATES_EDITED_SUCCESSFULLY,
  CURRENCY_RATES_EDITING_FAILED,
  SET_USER_RATE,
  USE_CB_RATE,
  USE_USER_RATE,
  SET_DEFAULT_CURRENCY,
} from "../actions/currencyRates";

const INITIAL_STATE = {
  ready: false,
  loading: false,
  error: {
    text: '',
    show: false,
  },
  rates: [],
  useDefaultRates: -1,
  defaultCurrencyId: 1,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //Сохранение нового курса валют
    case CURRENCY_RATES_EDITING_START:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
      };
    //Редактирование курсов валют
    case SET_USER_RATE:
      return update(state, {
        rates: {
          [action.payload.index]: {
            userRate: { $set: action.payload.value }
          }
        }
      });
    //Обходим массив и для каждого userRate проставляем значение, равное rate (курсу по ЦБ) этой валюты
    case USE_USER_RATE:
      return {
        ...state,
        useDefaultRates: action.payload.index,
      };
    case USE_CB_RATE:
      return update(state, {
        useDefaultRates: { $set: -1 },
        rates: {
          [action.payload.index]: {
            userRate: { $set: action.payload.rate }
          }
        }
      });
    //Установить валюту по умолчанию и пересчитать значения других валют
    //Для рубля формула:
    //RUB=1 / значение валюты в рублях, относительно которой сейчас считается курс (newDefaultRate)
    //При этом defaultRate рубля всегда будет 1
    //Для других валют:
    //курс валюты (в руб.) / курс валюты выставленной по умолчанию (в руб.) (rate.defaultRate / newDefaultRate)
    case SET_DEFAULT_CURRENCY:
      const recalculatedRates = state.rates;
      const newDefaultRate = action.payload.rate;
      //Рубль
      //if (action.payload.id === 1) newDefaultRate = 1;
      recalculatedRates.forEach(rate => {
        const newRate = (rate.defaultRate / newDefaultRate).toFixed(8);
        //Получилась строка, приводим ее к целому или дробному числу
        if (parseFloat(newRate) !== parseInt(newRate, 10)) {
          rate.calculatedDefaultRate = parseFloat(newRate);
        } else {
          rate.calculatedDefaultRate = parseInt(newRate, 10);
        }
      });
      return {
        ...state,
        rates: recalculatedRates,
        defaultCurrencyId: action.payload.id,
      };
    //Загрузка курсов валют
    case CURRENCY_RATES_LOADING_START:
      return {
        ...state,
        ready: false,
        loading: true,
        error: {
          text: '',
          show: false,
        },
        rates: [],
        useDefaultRates: -1,
        defaultCurrencyId: 1,
      };
    case CURRENCY_RATES_LOADED_SUCCESSFULLY:
      return {
        ...state,
        ready: true,
        loading: false,
        error: {
          text: '',
          show: false,
        },
        rates: action.payload.data.currencies,
        defaultCurrencyId: action.payload.data.defaultCurrencyId,
      };
    case CURRENCY_RATES_LOADING_FAILED:
    case CURRENCY_RATES_EDITING_FAILED:
      return {
        ...state,
        ready: false,
        loading: false,
        error: {
          text: action.payload.error,
          show: true,
        },
      };
    default:
      return state;
  }
}
