import { referalApi } from '../../api/request';

import { showServerErrors } from "./ui";
import { tokenError } from "./auth";

export const ENTER_PARTNER_START = "Enter partner start";
export const ENTER_PARTNER_SUCCESS = "Enter partner successfull";
export const ENTER_PARTNER_FAILURE = "Enter partner failed";


const enterPartnerStart = () => ({
  type: ENTER_PARTNER_START,
});


const enterPartnerSuccess = (referal) => ({
  type: ENTER_PARTNER_SUCCESS,
  payload: {
    referal
  }
});

const enterPartnerFailure = (error, dispatch) => {
  tokenError(error, dispatch);
  dispatch(showServerErrors(error, 'fail_enter_partner'));
  return {
    type: ENTER_PARTNER_FAILURE,
    payload: {
      error: 'fail_enter_partner'
    }
  };
};

export const enterPartnerAsync = () => async (dispatch) => {
  dispatch(enterPartnerStart());

  referalApi.becomePartner()
    .then((res) => {
      dispatch(enterPartnerSuccess(res.data));
    })
    .catch((error) => {
      dispatch(enterPartnerFailure(error, dispatch));
    });
};