import React from 'react';
import Preloader from '../components/ui/Preloader/Preloader';

type QueryCheckerPropsType = {
  checkers: (() => boolean)[],
  children: any
};

const QueryChecker = (props: QueryCheckerPropsType) => {
  const { checkers, children } = props;

  if (checkers.every((checker) => checker())) {
    return children;
  }

  return (<Preloader />);
};

export default QueryChecker;
