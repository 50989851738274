import update from "immutability-helper";
import {
  MOVE_TO_CALC_START,
  MOVE_TO_CALC_SUCCESS,
  MOVE_TO_CALC_FAILURE,
  UPDATE_FORK_START,
  UPDATE_FORK_SUCCESS,
  UPDATE_FORK_FAILURE,
  FORK_IS_GONE,
  CLEAR_CALC,
  CLEAR_CALC_SUCCESS,
  ONLY_MY_BK_CHANGE,
  SET_CALC_UPDATING,
  SWITCH_CALC_AUTO_UPDATE,
  RESET_CALC,
  UPDATE_CALC_SHOULDER_BET,
} from '../actions/calc';
import { SIGN_OUT } from "../actions/auth";
import { concatKeysForList } from "../../hooks/useBetList";

const INITIAL_STATE = {
  loading: false,
  error: '',
  eventType: "",
  onlyMyBk: false,
  openCalcFlag: "-1",
  errorsCount: 0,
  toCalc: {
    forkInfo: {},
    getAllCoefficients: false,
    clear: false
  },
  bkMasValues: {
    masShoulder1: [],
    masShoulder2: [],
    shoulderBKvalue1: null,
    shoulderBKvalue2: null,
    disabled1: false,
    disabled2: false
  },
  fork: {},
  newShoulder1: {},
  newShoulder2: {},
  expired1: '',
  expired2: '',
  isLoad1: false,
  isLoad2: false,
  isLeftUpdate: false,
  isLeftAutoUpdate: false,
  isRightUpdate: false,
  isRightAutoUpdate: false,
  isBothUpdate: false,
  isAutoUpdateOn: false,
  isForksUpdating: false
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_OUT:
      return INITIAL_STATE;
    case SET_CALC_UPDATING:
      return update(state, {
        isForksUpdating: { $set: action.payload.isLoading },
        isLeftUpdate: { $set: action.payload.isLoading },
        isRightUpdate: { $set: action.payload.isLoading },
      });

    case SWITCH_CALC_AUTO_UPDATE:
      return update(state, {
        isAutoUpdateOn: { $set: action.payload.isOn },
        isLeftAutoUpdate: { $set: action.payload.isLeftAutoUpdate },
        isRightAutoUpdate: { $set: action.payload.isRightAutoUpdate },
        errorsCount: { $set: 0 },
      });

    //  перемещение в калькулятор
    case MOVE_TO_CALC_START:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
        openCalcFlag: { $set: "-1" },
        bkMasValues: { $set: INITIAL_STATE.bkMasValues },
        toCalc: {
          forkInfo: { $set: {} },
          clear: { $set: false }
        },
      });
    case MOVE_TO_CALC_SUCCESS:
      const isVluebet = action.payload.eventType === 'valuebet';
      const firstBet = isVluebet ? action.payload.forkInfo.bet : action.payload.forkInfo.leftShoulder;
      return update(state, {
        loading: { $set: false },
        openCalcFlag: { $set: action.payload.id },
        toCalc: {
          forkInfo: { $set: action.payload.forkInfo },
          clear: { $set: false }
        },
        eventType: { $set: action.payload.eventType },
        expired1: { $set: '' },
        expired2: { $set: '' },
        newShoulder1: { $set: firstBet },
        newShoulder2: { $set: isVluebet ? {} : action.payload.forkInfo.rightShoulder },

        bkMasValues: {
          masShoulder1: { $set: [{
            key: firstBet.bookerId,
            text: firstBet.bookerName + ' ' + firstBet.coefficient,
            value: concatKeysForList(firstBet),
          }] },
          masShoulder2: { $set: isVluebet ? [] : [{
            key: action.payload.forkInfo.rightShoulder.bookerId,
            text: action.payload.forkInfo.rightShoulder.bookerName + ' ' + action.payload.forkInfo.rightShoulder.coefficient,
            value: concatKeysForList(action.payload.forkInfo.rightShoulder),
          }] },
          shoulderBKvalue1: { $set: concatKeysForList(firstBet) },
          shoulderBKvalue2: { $set: concatKeysForList(action.payload.forkInfo.rightShoulder) },
        }
      });
    case MOVE_TO_CALC_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.payload.error },
      });
    //  обновление плеча
    case UPDATE_FORK_START:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
        isLoad1: { $set: false },
        isLoad2: { $set: false },
        isLeftUpdate: { $set: action.payload.isLeftUpdate },
        isRightUpdate: { $set: action.payload.isRightUpdate },
        isBothUpdate: { $set: action.payload.isBothUpdate },
      });
    case UPDATE_FORK_SUCCESS:
      return update(state, {
        fork: { $set: action.payload.fork },
        newShoulder1: { $set: action.payload.newShoulder1 },
        newShoulder2: { $set: action.payload.newShoulder2 },
        loading: { $set: false },
        isLoad1: { $set: true },
        isLoad2: { $set: true },
        isLeftUpdate: { $set: false },
        isRightUpdate: { $set: false },
        isBothUpdate: { $set: false },
        toCalc: {
          getAllCoefficients: { $set: action.payload.getAllCoefficients },
        },
        bkMasValues: {
          masShoulder1: { $set: action.payload.masShoulder1 },
          masShoulder2: { $set: action.payload.masShoulder2 },
          shoulderBKvalue1: { $set: action.payload.valueBkMas1 },
          shoulderBKvalue2: { $set: action.payload.valueBkMas2 },
        },
      });

    case UPDATE_CALC_SHOULDER_BET:
      if (action.payload.newShoulder1) {
        return update(state, {
          expired1: { $set: '' },
          newShoulder1: { $set: action.payload.newShoulder1 },
          openCalcFlag: { $set: action.payload.openCalcFlag },
          bkMasValues: {
            shoulderBKvalue1: { $set: action.payload.shoulderBKvalue1 },
          },
          toCalc: {
            forkInfo: {
              leftShoulder: { $set: { ...state.toCalc.forkInfo.leftShoulder, ...action.payload.newShoulder1 } },
            },
          }
        });
      }
      return update(state, {
        expired1: { $set: '' },
        newShoulder2: { $set: action.payload.newShoulder2 },
        openCalcFlag: { $set: action.payload.openCalcFlag },
        bkMasValues: {
          shoulderBKvalue2: { $set: action.payload.shoulderBKvalue2 },
        },
        toCalc: {
          forkInfo: {
            rightShoulder: { $set: { ...state.toCalc.forkInfo.rightShoulder, ...action.payload.newShoulder2 } },
          },
        }
      });

    case UPDATE_FORK_FAILURE:
      return update(state, {
        errorsCount: { $set: state.errorsCount + 1 },
        loading: { $set: false },
        isLoad1: { $set: false },
        isLoad2: { $set: false },
        isLeftUpdate: { $set: false },
        isRightUpdate: { $set: false },
        isBothUpdate: { $set: false },
        error: { $set: action.payload.error },
      });
    case FORK_IS_GONE:
      return update(state, {
        expired1: { $set: action.payload.expired1 },
        expired2: { $set: action.payload.expired2 },
      });
    case CLEAR_CALC:
      return INITIAL_STATE;
    case CLEAR_CALC_SUCCESS:
      return update(state, {
        toCalc: {
          clear: { $set: false },
        },
        openCalcFlag: { $set: "-1" },
      });
    case ONLY_MY_BK_CHANGE:
      return update(state, {
        onlyMyBk: { $set: !state.onlyMyBk },
      });
    case RESET_CALC:
      return INITIAL_STATE;
    default:
      return state;
  }
}
