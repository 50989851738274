import { ShoulderType, AllBetTypes } from '../redux/types/bets';
/**
 * Создаем id вилки из ключей, для не авторизованного
 */

export const concatKeys = (leftShoulder: ShoulderType, rightShoulder: ShoulderType | null) => {
  return leftShoulder.betKey + (rightShoulder ? rightShoulder.betKey : '');
};

// eslint-disable-next-line import/prefer-default-export
export const useForkId = (fork: AllBetTypes, index: string | number): string => {
  const leftShoulder = 'leftShoulder' in fork ? fork.leftShoulder : fork.bet;
  const rightShoulder = 'rightShoulder' in fork ? fork.rightShoulder : null;

  if (!leftShoulder.betId) {
    return String(index);
  }

  return concatKeys(leftShoulder, rightShoulder) || index.toString();
};